import React, { useState } from 'react'
import { useMutation } from 'react-query'
import 'twin.macro'
import { addToRain } from '../sidebar/lib/rain-api'
import {
  ActionButton,
  Button,
  Container,
  Err,
  H2,
  Input,
  P,
  Success
} from '../_common/components'
import { crashPause, crashResume, creditUser } from './apis/admin'

export const Miscellaneous: React.FC = () => {
  return (
    <Container tw="flex flex-col gap-8">
      <TipRain />
      <CreditUser />
      <CrashControls />
    </Container>
  )
}

const TipRain: React.FC = () => {
  const [amount, setAmount] = useState('0')

  const mutation = useMutation(addToRain)

  return (
    <section>
      <H2>Tip rain</H2>
      <Input
        type="number"
        step={0.01}
        min={0.01}
        placeholder="Amount"
        value={amount}
        onChange={(e) => setAmount(e.target.value)}
        tw="mr-5"
      />
      <ActionButton mutation={mutation} payload={~~(parseFloat(amount) * 100)}>
        Tip rain
      </ActionButton>

      {mutation.error && <Err>{(mutation.error as Error).message}</Err>}
      {mutation.isSuccess && <Success>Rain tipped successfully</Success>}
    </section>
  )
}

const CreditUser: React.FC = () => {
  const [userId, setUserId] = useState('')
  const [amount, setAmount] = useState('0')

  const mutation = useMutation(creditUser)

  return (
    <section>
      <H2>Credit user</H2>
      <Input
        type="number"
        min={1}
        placeholder="User ID"
        value={userId}
        onChange={(e) => setUserId(e.target.value)}
        tw="mr-5"
      />

      <Input
        type="number"
        step={0.01}
        min={0.01}
        placeholder="Amount"
        value={amount}
        onChange={(e) => setAmount(e.target.value)}
        tw="mr-5"
      />
      <ActionButton
        mutation={mutation}
        payload={{ userId, amount: ~~(parseFloat(amount) * 100) }}>
        Credit user
      </ActionButton>

      {mutation.error && <Err>{(mutation.error as Error).message}</Err>}
      {mutation.isSuccess && <Success>User credited successfully</Success>}
    </section>
  )
}

const CrashControls: React.FC = () => {
  return (
    <section>
      <H2>Crash controls</H2>
      <P tw="mb-2">
        You probably don't want to click this. Only use when game is finished.
      </P>
      <Button onClick={crashPause} tw="mr-5">
        Pause
      </Button>
      <Button onClick={crashResume}>Resume</Button>
    </section>
  )
}
