import React from 'react'
import 'twin.macro'
import { Avatar, Table, TableComponent } from '../_common/components'
import { Currency } from '../_common/components/Currency'
import { useLiveGames } from './lib/useLiveGames'

export const LiveGames: React.FC = () => {
  const { games } = useLiveGames()

  return (
    <div tw="flex flex-col bg-[#121a53] py-3 px-2 rounded-lg w-full overflow-hidden">
      <div tw="text-lg text-white font-bold pb-3 px-1">Live games</div>

      <div tw="overflow-y-auto lg:overflow-y-hidden">
        <TableComponent isFull tw="table-auto lg:table-fixed">
          <thead>
            <Table.Tr>
              <Table.Th>Player</Table.Th>
              <Table.Th>Bet</Table.Th>
              <Table.Th>Win</Table.Th>
            </Table.Tr>
          </thead>
          <tbody>
            {games.map((game) => (
              <Table.Tr key={game.id}>
                <Table.Td tw="flex flex-row gap-2">
                  <Avatar size="small" src={game.user.avatar} />
                  <div tw="truncate">{game.user.name}</div>
                </Table.Td>
                <Table.Td>
                  <Currency value={game.betAmount} size="small" />
                </Table.Td>
                <Table.Td>
                  <Currency
                    value={game.winningAmount - game.betAmount}
                    size="small"
                    comparison
                  />
                </Table.Td>
              </Table.Tr>
            ))}
          </tbody>
        </TableComponent>
      </div>
    </div>
  )
}
