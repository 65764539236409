import styled from '@emotion/styled'
import { Link } from '@reach/router'
import React from 'react'
import tw from 'twin.macro'
import { useMediaQuery } from 'react-responsive'
import { ReactComponent as Explosion } from './assets/explosion.svg'
import { ReactComponent as Swords } from '../_common/assets/swords.svg'
import { ReactComponent as Bards } from '../_common/assets/cards.svg'
import { ReactComponent as Ticket } from '../_common/assets/ticket.svg'
// import { ReactComponent as Discord } from '../_common/assets/discord.svg'
// import { ReactComponent as Twitter } from '../_common/assets/twitter.svg'
import { NavLink } from './NavLink'
import { FormattedMessage } from 'react-intl'
import { Mobile } from './Mobile'
import { Desktop } from './Desktop'
import { css } from '@emotion/react'
import { FreeCoins } from './FreeCoins'
import { PromoCode } from './PromoCode'

export const Navigation: React.FC = () => {
  const isDesktop = useMediaQuery({ query: '(min-width: 768px)' })

  return isDesktop ? <Desktop /> : <Mobile />
}

export const PrimaryLinks: React.FC = () => (
  <>
    <NavLink to="/" icon={<Explosion tw="w-5 h-5 fill-current" />}>
      <FormattedMessage id="nav.crash" />
    </NavLink>
    <NavLink to="/duels" icon={<Swords tw="h-4 fill-current" />}>
      <FormattedMessage id="nav.duels" />
    </NavLink>
    <NavLink to="/blackjack" icon={<Bards tw="h-6 w-6 fill-current" />}>
      <FormattedMessage id="nav.blackjack" />
    </NavLink>
    <NavLink
      to="/lottery"
      icon={<Ticket tw="h-4 fill-current transform -rotate-45" />}>
      <FormattedMessage id="nav.lottery" />
    </NavLink>
  </>
)

export const SecondaryLinks: React.FC = () => (
  <>
    <FreeCoins />
    <PromoCode />
    <SecondaryLink to="/faq">
      <FormattedMessage id="nav.faq" />
    </SecondaryLink>
    <SecondaryLink to="/provably-fair">
      <FormattedMessage id="nav.provably-fair" />
    </SecondaryLink>
    <SecondaryLink to="/terms-of-service">
      <FormattedMessage id="nav.tos" />
    </SecondaryLink>
    <SecondaryLink to="/privacy-policy">
      <FormattedMessage id="nav.privacy" />
    </SecondaryLink>
    <SecondaryLink to="/affiliates">
      <FormattedMessage id="nav.affiliates" />
    </SecondaryLink>

    {/* <div tw="flex flex-row gap-4 uppercase">
      <SecondaryLinkExt href="https://discord.gg/crashgg">
        <Discord />
      </SecondaryLinkExt>
      <SecondaryLinkExt href="https://twitter.com/CrashGG_">
        <Twitter />
      </SecondaryLinkExt>
    </div> */}
  </>
)

const linkStyles = tw`text-gainsGold hover:brightness-125 transition duration-150 uppercase text-[11px] font-semibold tracking-wider`

export const SecondaryLink = styled(Link)(linkStyles)

export const SecondaryLinkExt = styled.a([
  linkStyles,
  css`
    color: #f6c358;
    svg {
      ${tw`fill-current h-3 inline`}
    }
  `
])
SecondaryLinkExt.defaultProps = {
  target: '_blank',
  rel: 'noopener noreferrer'
}
