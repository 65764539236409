import { Tab } from '@headlessui/react'
import React from 'react'
import 'twin.macro'
import { Container, StyledTab } from '../_common/components'
import { Giftcards } from './Giftcards'
import { PromoCodes } from './PromoCodes'

export const Codes: React.FC = () => {
  return (
    <Container>
      <Tab.Group>
        <StyledTab.List tw="mx-5">
          <StyledTab>Promo Codes</StyledTab>
          <StyledTab>Giftcards</StyledTab>
        </StyledTab.List>
        <Tab.Panels tw="m-5">
          <StyledTab.Panel>
            <PromoCodes />
          </StyledTab.Panel>
          <StyledTab.Panel>
            <Giftcards />
          </StyledTab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </Container>
  )
}
