import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import 'twin.macro'
import { H3, Range } from '../_common/components'
import { ReactComponent as VolumeOff } from './assets/volume-x.svg'
import { ReactComponent as VolumeFull } from './assets/volume-full.svg'
import { getVolume, persistVolume } from '../_common/sounds'

export const Volume: React.FC = () => {
  const [volume, _setVolume] = useState(getVolume())
  const setVolume = (value: string) => {
    _setVolume(parseFloat(value))
    persistVolume(value)
  }

  return (
    <section>
      <H3>
        <FormattedMessage id="settings.interface" />
      </H3>
      <div tw="grid md:grid-cols-2">
        <div tw="text-gray-el4 text-lg">
          <FormattedMessage id="settings.volume" />
        </div>
        <div tw="flex flex-row gap-3 items-center">
          <VolumeOff tw="h-6 fill-current text-gray" />
          <Range
            min={0}
            max={1}
            step={0.01}
            value={volume}
            onChange={(e) => setVolume(e.target.value)}
          />
          <VolumeFull tw="h-6 fill-current text-gray" />
        </div>
      </div>
    </section>
  )
}
