import React from 'react'
import { FormattedDate } from 'react-intl'
import 'twin.macro'
import { H2, Table } from '../_common/components'
import { Currency } from '../_common/components/Currency'
import { StatsPeriod } from './apis/stats'

interface Props {
  days: StatsPeriod[]
}

export const Breakdown: React.FC<Props> = ({ days }) => {
  return (
    <div>
      <H2>Daily breakdown</H2>
      <Table isFull>
        <thead>
          <Table.Tr>
            <Table.Td>Date</Table.Td>
            <Table.Td>Users</Table.Td>
            <Table.Td>Wager</Table.Td>
            <Table.Td>Profit/Loss</Table.Td>
            <Table.Td>Crypto</Table.Td>
            <Table.Td>Giftcards</Table.Td>
            <Table.Td>Skinsback</Table.Td>
            <Table.Td>Shop fee (est.)</Table.Td>
            <Table.Td>Skins (USD)</Table.Td>
          </Table.Tr>
        </thead>
        <tbody>
          {days.map((day) => (
            <Table.Tr key={day.day}>
              <Table.Td>
                <FormattedDate
                  value={day.day}
                  dateStyle="medium"
                  timeStyle="short"
                />
              </Table.Td>
              <Table.Td>{day.newUsers}</Table.Td>
              <Table.Td>
                <Currency value={day.wager} size="small" />
              </Table.Td>
              <Table.Td>
                <Currency value={day.profitUsd} size="small" comparison />
              </Table.Td>
              <Table.Td>
                <Currency value={day.deposits.coinpayments || 0} size="small" />
              </Table.Td>
              <Table.Td>
                <Currency value={day.deposits.giftcard || 0} size="small" />
              </Table.Td>
              <Table.Td>
                <Currency value={day.deposits.skinsback || 0} size="small" />
              </Table.Td>
              <Table.Td>
                <Currency value={day.shopFee || 0} size="small" />
              </Table.Td>
              <Table.Td>
                <Currency
                  value={day.deposits.skin! - day.withdrawals.skin!}
                  size="small"
                />
              </Table.Td>
            </Table.Tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}
