import React from 'react'
import 'twin.macro'
import { Option, Select } from '../_common/components'
import { ReactComponent as Bitcoin } from './assets/bitcoin.svg'
import { ReactComponent as Litecoin } from './assets/litecoin.svg'
import { ReactComponent as Ethereum } from './assets/ethereum.svg'
import { useNavigate } from '@reach/router'

interface Props {
  selected: string
}

export const CryptoSelector: React.FC<Props> = ({ selected }) => {
  const navigate = useNavigate()

  const selectedOption = OPTIONS.find((o) => o.id === selected) || OPTIONS[0]

  const select = (option: Option) => {
    navigate(`/deposit/crypto/${option.id.toLowerCase()}`)
  }

  return (
    <div tw="w-44 mx-auto mt-5">
      <Select
        options={OPTIONS}
        selected={selectedOption}
        select={select}
        tw="bg-dark-el2 hover:bg-dark-el3"
      />
    </div>
  )
}

interface OptionProps {
  Icon: React.FC<any>
  name: string
}

const OptionRender: React.FC<OptionProps> = ({ Icon, name }) => (
  <div tw="flex flex-row items-center gap-2">
    <Icon tw="h-5 w-5" />
    {name}
  </div>
)

const OPTIONS: Option[] = [
  { id: 'BTC', render: <OptionRender Icon={Bitcoin} name="Bitcoin" /> },
  { id: 'LTC', render: <OptionRender Icon={Litecoin} name="Litecoin" /> },
  { id: 'ETH', render: <OptionRender Icon={Ethereum} name="Ethereum" /> }
]
