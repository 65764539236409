import React from 'react'
import HCaptcha from '@hcaptcha/react-hcaptcha'
import { FormattedMessage } from 'react-intl'
import tw from 'twin.macro'
import { B, H2, Modal, P, Success } from '../_common/components'
import { HCAPTCHA_KEY, SITE_TAG } from '../_common/constants'
import { useUser } from '../_common/hooks/useUser'
import { lvlFromWager, nameHasTag } from '../_common/utils'
import { joinRain } from './lib/rain-api'
import { sounds } from '../_common/sounds'

const RAIN_LEVEL = 5

interface Props {
  isActive: boolean
  hasJoined: boolean
  markJoined: () => void
  isOpen: boolean
  close: () => void
}

export const RainModal: React.FC<Props> = ({
  isActive,
  hasJoined,
  markJoined,
  isOpen,
  close
}) => {
  const { user } = useUser()
  const metRequirements =
    lvlFromWager(user?.totalWagered || 0) >= RAIN_LEVEL &&
    nameHasTag(user?.name)
  isActive = true

  return (
    <Modal isOpen={isOpen} close={close} styles={tw`px-5 py-5 max-w-sm`}>
      <H2>
        <FormattedMessage id="rain.join" />
      </H2>
      <P tw="text-sm">
        <FormattedMessage id="rain.about" />
      </P>
      <div tw="bg-gainsBgNavbar rounded-md mt-5 px-3 py-4 text-center">
        {!metRequirements ? (
          <Requirements />
        ) : !isActive ? (
          <NotActive />
        ) : hasJoined ? (
          <Joined />
        ) : (
          <Join markJoined={markJoined} />
        )}
      </div>
    </Modal>
  )
}

export const Join: React.FC<{ markJoined: () => void }> = ({ markJoined }) => {
  const onVerify = async (token: string) => {
    try {
      await joinRain(token)
      markJoined()
      sounds.placeBet()
    } catch (err) {
      // noop
    }
  }

  return (
    <div tw="mx-auto inline-block">
      <HCaptcha sitekey={HCAPTCHA_KEY} theme="dark" onVerify={onVerify} />
    </div>
  )
}

export const Requirements: React.FC = () => (
  <P tw="text-sm">
    <FormattedMessage
      id="rain.requirements"
      values={{
        level: (
          <B>
            <FormattedMessage
              id="common.level"
              values={{ level: RAIN_LEVEL }}
            />{' '}
            onsite
          </B>
        ),
        tag: <B>{SITE_TAG}</B>
      }}
    />
  </P>
)

export const NotActive: React.FC = () => (
  <P tw="text-sm">
    <FormattedMessage id="rain.not-active" />
  </P>
)

export const Joined: React.FC = () => (
  <Success>
    <FormattedMessage id="rain.joined" />
  </Success>
)
