import React from 'react'
import 'twin.macro'
import { H2, Stat, StatLabel, StatValue } from '../_common/components'
import { Currency } from '../_common/components/Currency'
import { StatsPeriod } from './apis/stats'

interface Props {
  duration: string
  stats: StatsPeriod
}

export const DurationStats: React.FC<Props> = ({ duration, stats }) => {
  return (
    <div>
      <H2>{duration}</H2>
      <div tw="grid grid-cols-1 md:grid-cols-4 xl:grid-cols-7 gap-5">
        <Stat>
          <StatValue>{stats.newUsers}</StatValue>
          <StatLabel>New users</StatLabel>
        </Stat>
        <Stat>
          <StatValue>
            <Currency value={stats.profitUsd} size="big" comparison />
          </StatValue>
          <StatLabel>Profit/Loss (USD)</StatLabel>
        </Stat>
        <Stat>
          <StatValue>
            <Currency value={stats.wager} size="big" />
          </StatValue>
          <StatLabel>Total wagered</StatLabel>
        </Stat>
        <Stat>
          <StatValue>
            <Currency
              value={
                (stats.deposits.coinpayments || 0) -
                (stats.withdrawals.coinpayments || 0)
              }
              size="big"
            />
          </StatValue>
          <StatLabel>Crypto</StatLabel>
        </Stat>
        <Stat>
          <StatValue>
            <Currency value={stats.deposits.giftcard || 0} size="big" />
          </StatValue>
          <StatLabel>Giftcards</StatLabel>
        </Stat>
        <Stat>
          <StatValue>
            <Currency value={stats.deposits.skinsback || 0} size="big" />
          </StatValue>
          <StatLabel>Skinsback</StatLabel>
        </Stat>
        <Stat>
          <StatValue>
            <Currency
              value={stats.deposits.skin! - stats.withdrawals.skin!}
              size="big"
            />
          </StatValue>
          <StatLabel>Skins (USD)</StatLabel>
        </Stat>
      </div>
    </div>
  )
}
