import { Int } from '@crashgg/types/dist'
import React from 'react'
import tw from 'twin.macro'
import { Button, Container, H1 } from '../_common/components'
import { PoolSize } from './PoolSize'
import { ReactComponent as TicketIcon } from '../_common/assets/ticket.svg'
import { ReactComponent as LogoIcon } from '../_common/assets/logo-icon.svg'
import { FormattedMessage } from 'react-intl'
import { useCountdown } from '../_common/hooks/useCountdown'
import { Tickets } from '../_common/components/Currency'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import bg from './assets/bg.png'

interface Props {
  id: Int
  pot: Int
  endsAt: Date
  entryPrice: Int
  open: () => void
}

export const Hero: React.FC<Props> = ({
  pot,
  endsAt,
  entryPrice,
  id,
  open
}) => {
  const [h, m, s] = useCountdown(endsAt)

  return (
    <Bg tw="bg-dark-de1 py-10">
      <Container tw="flex flex-col items-center gap-5">
        <H1 tw="tracking-wide">
          L
          <LogoIcon tw="inline h-9 mb-2 mx-1" />
          TTERY #{id}
        </H1>

        <PoolSize pot={pot} />

        <div tw="text-gray-el4 text-lg font-medium">
          <FormattedMessage id="lottery.ends-in" />
          <span tw="text-white font-bold ml-2">
            {!!h && <span tw="mx-2">{h} H</span>}
            <span tw="inline-block mx-1 w-12">
              {String(m).padStart(2, '0')} M
            </span>
            <span tw="inline-block w-12">{String(s).padStart(2, '0')} S</span>
          </span>

          <span tw="ml-5 text-lg">
            <Tickets
              value={entryPrice}
              styles={tw`gap-1 flex-row-reverse text-lg`}
            />
            <span tw="mx-2">=</span>
            <Tickets
              value={1}
              isLottery
              styles={tw`gap-1 flex-row-reverse text-lg`}
            />
          </span>
        </div>

        <Button
          color="gold"
          tw="font-bold text-lg tracking-normal"
          onClick={open}>
          <TicketIcon tw="h-4 mb-1 mr-2 inline transform -rotate-45" />{' '}
          <FormattedMessage id="lottery.buy-tickets" />
        </Button>
      </Container>
    </Bg>
  )
}

const Bg = styled.div([
  tw`bg-dark-el1 py-10`,
  css`
    background-image: url('${bg}');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  `
])
