import { useMemo } from 'react'
import { useDebounce } from '../_common/hooks/useDebounce'
import { SteamItemAny } from '../_common/types'

export type Order = 'price-asc' | 'price-desc'

export interface Props {
  items: SteamItemAny[]
  search?: string
  order?: Order
}

export const useItemFilter = ({
  items,
  search = '',
  order = 'price-desc'
}: Props) => {
  const debouncedSearch = useDebounce(search, 300)

  const filteredItems = useMemo(() => {
    return nameSearch(items, debouncedSearch).sort(sortMethods[order])
  }, [items, debouncedSearch, order])

  return { filteredItems }
}

const nameSearch = (items: SteamItemAny[], _search: string) => {
  const search = _search.toLowerCase().trim()
  if (!search.length) return items

  return items.filter((item) => item.name.toLowerCase().includes(search))
}

const sortMethods: Record<Order, (a: SteamItemAny, b: SteamItemAny) => number> =
  {
    'price-asc': (a, b) => a.price! - b.price!,
    'price-desc': (a, b) => b.price! - a.price!
  }
