import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // FIXME: in some cases it should be refetched
      refetchOnMount: false,
      refetchOnWindowFocus: true,
      refetchOnReconnect: true
    }
  }
})

;(global as any).queryClient = queryClient

export const QueryProvider: React.FC = ({ children }) => {
  return (
    <QueryClientProvider client={queryClient}>
      {children}
      {process.env.NODE_ENV === 'development' && (
        <ReactQueryDevtools position="bottom-right" />
      )}
    </QueryClientProvider>
  )
}
