import { Card as TCard, HandStatus, Int } from '@crashgg/types/dist'
import { css, keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import React from 'react'
import tw from 'twin.macro'
import cardBack from './assets/cards/back.svg'
import { ReactComponent as C } from './assets/cards/C.svg'
import { ReactComponent as D } from './assets/cards/D.svg'
import { ReactComponent as H } from './assets/cards/H.svg'
import { ReactComponent as S } from './assets/cards/S.svg'

const SUITS = { C, D, H, S }

interface Props extends WrapperProps {
  card: TCard | [-1]
}

export const Card: React.FC<Props> = ({
  card,
  isRevealed,
  idx,
  delay,
  status
}) => {
  const Suit = SUITS[card[1] || 'C']

  return (
    <Wrapper
      isRevealed={isRevealed}
      idx={idx}
      delay={delay}
      isRed={card[1] && ['D', 'H'].includes(card[1])}
      status={status}>
      <div className="inner">
        <div className="front">
          <div tw="text-2xl lg:text-5xl font-bold ml-3">{card[0]}</div>
          <Suit tw="w-3 lg:w-5 ml-3 -mt-7" />
        </div>
        <div className="back"></div>
      </div>
    </Wrapper>
  )
}

interface WrapperProps {
  isRevealed: boolean
  idx?: Int
  delay?: Int
  isRed?: boolean
  status?: HandStatus
}

const Wrapper = styled.div<WrapperProps>((props) => [
  tw`w-16 h-24 lg:(w-24 h-36) mx-1 rounded shadow-lg overflow-hidden`,
  tw`relative`,
  css`
    perspective: 1000px;
    animation: ${show(props.idx)} 1s ease-out;
    animation-fill-mode: forwards;
    border: 6px solid ${borderColor(props.status)};
    transition: border 0.3s;
    border-radius: 6px;

    .inner {
      transform-style: preserve-3d;
      transform: rotateY(180deg);
      ${tw`w-full h-full`}
    }

    .front,
    .back {
      ${tw`absolute w-full h-full`}
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
    }

    .front {
      ${tw`bg-white`}
      ${props.isRed ? tw`text-[red]` : tw`text-black`}
    }

    .back {
      background: url(${cardBack});
      // background-size: cover;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      transform: rotateY(180deg);
    }
  `,
  props.isRevealed &&
    css`
      .inner {
        animation: 0.6s ${reveal} ease-in-out;
        animation-fill-mode: forwards;
      }
    `
])

const reveal = keyframes`
  0% {
    transform: rotateY(180deg);
  }
  100% {
    transform: rotateY(0deg);
  }
`

const show = (idx: Int = 0) => keyframes`
  0% {
    position: absolute;
    left: ${5 + 2 * idx}rem;
    margin-top: 0;
    margin-bottom: ${idx * 1}rem;
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    position: absolute;
    left: ${2 * idx}rem;
    margin-top: ${idx * 1}rem;
    margin-bottom: 0;
  }
`

const borderColor = (status?: HandStatus): string => {
  return {
    blackjack: '#3498db',
    win: '#2ecc71',
    lose: 'red',
    tie: '#f1c40f',
    'in-progress': 'transparent'
  }[status || 'in-progress']
}
