import React from 'react'
import { FormattedMessage } from 'react-intl'
import 'twin.macro'
import { API } from '../API'
import { Button } from './Button'
import { ReactComponent as SteamIcon } from '../assets/steam.svg'

interface Props {
  compact?: boolean
}

export const SignInButton: React.FC<Props> = ({ compact }) => {
  return (
    <Button
      onClick={API.login}
      tw="flex flex-row gap-2 px-5 bg-gainsGold text-gainsGoldText uppercase text-sm font-bold tracking-wider hover:bg-gainsGold hover:text-black"
      className="group">
      <div tw="relative z-50 flex flex-row items-center gap-2">
        <SteamIcon tw="w-6 h-6 fill-current text-gainsGoldText group-hover:text-black" />
        <FormattedMessage
          id={compact ? 'common.sign-in-compact' : 'common.sign-in-steam'}
        />
      </div>
      <div className="animated-button-bg" tw="z-10" />
    </Button>
  )
}
