import { RouteComponentProps } from '@reach/router'
import React, { useState, useEffect } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import Skeleton from 'react-loading-skeleton'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'
import { theme } from 'twin.macro'
import {
  ActionButton,
  Container,
  H1,
  Input,
  P,
  Protected,
  Stat,
  StatLabel,
  StatValue
} from '../_common/components'
import { Currency } from '../_common/components/Currency'
import { useTitle } from '../_common/hooks/useTitle'
import { useUser } from '../_common/hooks/useUser'
import {
  claimAffiliateEarnings,
  getAffiliateStats,
  setAffiliateCode
} from './lib/affiliates'

export const AffiliatesPage: React.FC<RouteComponentProps> = () => {
  useTitle('Affiliates')

  return (
    <Protected>
      <div id="bg" />
      <Container sm page>
        <H1>
          <FormattedMessage id="affiliates.title" />
        </H1>
        <P>
          <FormattedMessage id="affiliates.desc" />
        </P>

        <section tw="mt-10 grid md:(grid-cols-3) gap-5">
          <SetCode />
          <ClaimEarnings />
        </section>

        <Stats />
      </Container>
    </Protected>
  )
}

const SetCode: React.FC = () => {
  const { user } = useUser()
  const queryClient = useQueryClient()

  const [code, setCode] = useState('')
  useEffect(() => {
    setCode(user.affiliateCode)
  }, [user.affiliateCode])

  const mutation = useMutation(setAffiliateCode, {
    onSuccess: () => {
      toast.success(`Code ${code} set!`)
      queryClient.invalidateQueries('user')
    }
  })

  return (
    <div tw="flex flex-row gap-3 md:col-span-2">
      <Input
        type="text"
        tw="flex-1"
        placeholder={'Enter code'}
        value={code}
        onChange={(e) => setCode(e.target.value.toUpperCase())}
      />
      <ActionButton mutation={mutation} payload={code} color="primary">
        <FormattedMessage id="affiliates.set-code" />
      </ActionButton>
    </div>
  )
}

const ClaimEarnings: React.FC = () => {
  const queryClient = useQueryClient()
  const { formatMessage } = useIntl()

  const mutation = useMutation(claimAffiliateEarnings, {
    onSuccess: ({ claimedEarnings }) => {
      toast.success(
        formatMessage(
          { id: 'affiliates.claimed-earnings' },
          { amount: claimedEarnings }
        )
      )
      queryClient.invalidateQueries('affiliate-stats')
    }
  })

  return (
    <ActionButton mutation={mutation} payload={null}>
      <FormattedMessage id="affiliates.claim-earnings" />
    </ActionButton>
  )
}

const Stats: React.FC = () => {
  const { isLoading, data } = useQuery('affiliate-stats', getAffiliateStats)

  const skeleton = <Skeleton width={70} baseColor={theme`colors.dark.el3`} />

  return (
    <div tw="grid md:grid-cols-3 gap-5 mt-10">
      <Stat>
        <StatValue>{isLoading ? skeleton : data._count.referrals}</StatValue>
        <StatLabel>
          <FormattedMessage id="affiliates.users" />
        </StatLabel>
      </Stat>
      <Stat>
        <StatValue>
          {isLoading ? (
            skeleton
          ) : (
            <Currency value={data.totalEarnings} size="big" />
          )}
        </StatValue>
        <StatLabel>
          <FormattedMessage id="affiliates.all-time" />
        </StatLabel>
      </Stat>
      <Stat>
        <StatValue>
          {isLoading ? (
            skeleton
          ) : (
            <Currency value={data.unclaimedEarnings} size="big" />
          )}
        </StatValue>
        <StatLabel>
          <FormattedMessage id="affiliates.claimable" />
        </StatLabel>
      </Stat>
    </div>
  )
}
