import { Int } from '@crashgg/types/dist'
import React from 'react'
import tw from 'twin.macro'
import { lvlFromWager } from '../utils'
import iconMod from '../../sidebar/assets/icon-mod.png'
import iconOwner from '../../sidebar/assets/icon-owner.png'

interface Props {
  wager: Int
  role: string
}

export const Level: React.FC<Props> = ({ wager, role }) => {
  const level = lvlFromWager(wager)

  return role === 'user' ? (
    <div
      tw="py-[2px] px-2 pb-[2px] text-xs rounded inline-flex h-[min-content]"
      css={getColor(level, role)}
      title={`Level ${level}`}>
      {level}
    </div>
  ) : (
    <img src={SHORT_ROLE[role]} alt={role} tw="h-[20px]" />
  )
}

const SHORT_ROLE: Record<string, string> = {
  admin: iconOwner,
  mod: iconMod
}

const getColor = (level: Int, role?: string) => {
  if (role === 'mod') return tw`bg-green bg-opacity-20 text-green`
  if (role === 'admin') return tw`bg-red bg-opacity-20 text-red`

  if (level >= 100) return tw`bg-[#2bcbba] bg-opacity-30 text-[#2bcbba]`
  if (level >= 75) return tw`bg-[#f1c40f] bg-opacity-20 text-[#f1c40f]`
  if (level >= 50) return tw`bg-purple bg-opacity-20 text-[#9963e6]`
  if (level >= 25) return tw`bg-blue bg-opacity-20 text-blue`
  if (level >= 10) return tw`bg-gray bg-opacity-40 text-gray-el4`
  return tw`bg-gray bg-opacity-20 text-gray-el2`
}
