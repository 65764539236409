import { RouteComponentProps } from '@reach/router'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import 'twin.macro'
import { Container, H1, H3, P, Protected } from '../_common/components'
import { useTitle } from '../_common/hooks/useTitle'
import { useGiftcard } from './apis/deposit'
import { UseCode } from './UseCode'
import giftcard5 from './assets/giftcards/giftcard_5.png'
import giftcard10 from './assets/giftcards/giftcard_10.png'
import giftcard25 from './assets/giftcards/giftcard_25.png'
import giftcard50 from './assets/giftcards/giftcard_50.png'
import giftcard100 from './assets/giftcards/giftcard_100.png'
import giftcard250 from './assets/giftcards/giftcard_250.png'
import { DepositTabs } from './DepositTabs'

export const GiftcardPage: React.FC<RouteComponentProps> = () => {
  useTitle(`Giftcards`)

  return (
    <Protected>
      <div id="bg" />
      <DepositTabs />
      <Container page sm>
        <span tw="text-green font-bold tracking-wide">+40% bonus</span>
        <H1>
          <FormattedMessage id="deposit.giftcard.title" />
        </H1>
        <P>
          <FormattedMessage id="deposit.giftcard.desc" />
        </P>

        <div tw="my-10">
          <UseCode method={useGiftcard} />
        </div>

        <H3>
          <FormattedMessage id="deposit.giftcard.buy" />
        </H3>
        <P tw="text-sm">
          <FormattedMessage id="deposit.giftcard.buy-desc" />
        </P>

        <div tw="grid grid-cols-1 xs:grid-cols-2 lg:grid-cols-3 gap-5 mt-5">
          {GIFTCARDS.map((card) => (
            <a
              key={card.value}
              tw="hover:scale-105 transition duration-150"
              href={card.link}
              target="_blank"
              rel="noreferrer noopener">
              <img src={card.image} alt={`Giftcard $${card.value / 100}`} />
            </a>
          ))}
        </div>
      </Container>
    </Protected>
  )
}

const GIFTCARDS = [
  {
    value: 5_00,
    image: giftcard5,
    link: 'https://www.kinguin.net/category/122753/crash-gg-5-giftcard'
  },
  {
    value: 10_00,
    image: giftcard10,
    link: 'https://www.kinguin.net/category/122658/crash-gg-10-giftcard'
  },
  {
    value: 25_00,
    image: giftcard25,
    link: 'https://www.kinguin.net/category/122660/crash-gg-25-giftcard'
  },
  {
    value: 50_00,
    image: giftcard50,
    link: 'https://www.kinguin.net/category/122662/crash-gg-50-giftcard'
  },
  {
    value: 100_00,
    image: giftcard100,
    link: 'https://www.kinguin.net/category/122663/crash-gg-100-giftcard'
  },
  {
    value: 250_00,
    image: giftcard250,
    link: 'https://www.kinguin.net/category/122666/crash-gg-250-giftcard'
  }
]
