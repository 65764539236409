import { css } from '@emotion/react'
import styled from '@emotion/styled'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useQuery } from 'react-query'
import tw from 'twin.macro'
import { Avatar, Container, H2, P } from '../_common/components'
import { Tickets } from '../_common/components/Currency'
import { RelativeTime } from '../_common/components/RelativeTime'
import { useUser } from '../_common/hooks/useUser'
import { Current, getMyTickets } from './lib/lottery-api'

interface Props {
  current: Current
}

export const LiveTickets: React.FC<Props> = ({ current }) => {
  const { isAuthenticated } = useUser()

  const myTickets = useQuery(
    ['lottery-my-tickets', current.lottery.id],
    () => getMyTickets(current.lottery.id),
    { enabled: isAuthenticated }
  )

  return (
    <div tw="py-5 lg:py-10">
      <Container>
        <H2 tw="text-center relative tracking-wide">
          <FormattedMessage id="lottery.live-tickets" />

          <span tw="w-3 absolute top-1 ml-1 inline-flex">
            <span tw="bg-red w-3 h-3 rounded-full animate-ping opacity-50 absolute inline-flex" />
            <span tw="bg-red w-2 h-2 m-auto rounded-full relative inline-flex mt-[2px]" />
          </span>
        </H2>

        <Container sm tw="grid md:grid-cols-3 gap-5 lg:gap-8 my-5">
          <Stat>
            <StatDesc>
              <FormattedMessage id="lottery.total-tickets" />
            </StatDesc>
            <StatValue>
              <Tickets value={current.totalTickets} isLottery size="big" />
            </StatValue>
          </Stat>
          <Stat>
            <StatDesc>
              <FormattedMessage id="lottery.your-tickets" />
            </StatDesc>
            <StatValue>
              {myTickets.isSuccess ? (
                <Tickets value={myTickets.data} isLottery size="big" />
              ) : (
                'N/A'
              )}
            </StatValue>
          </Stat>
          <Stat>
            <StatDesc>
              <FormattedMessage id="lottery.win-chance" />
              <sup>1</sup>
            </StatDesc>
            <StatValue>
              {myTickets.isSuccess ? <>{myTickets.data / 1e4}%</> : 'N/A'}
            </StatValue>
          </Stat>
        </Container>

        <Table>
          <thead>
            <tr>
              <th tw="text-left">
                <FormattedMessage id="common.user" />
              </th>
              <th tw="text-right md:text-center">
                <FormattedMessage id="lottery.tickets-bought" />
              </th>
              <th tw="hidden md:table-cell text-right">
                <FormattedMessage id="common.time" />
              </th>
            </tr>
          </thead>

          <tbody>
            {current.lastBuys.map((buy) => (
              <tr tw="bg-dark-de1 my-1 text-left" key={buy.createdAt}>
                <td tw="flex flex-row items-center gap-2 truncate">
                  <Avatar
                    size="small+"
                    src={buy.user.avatar}
                    tw="rounded-full"
                  />
                  <div tw="text-lg font-medium">{buy.user.name}</div>
                </td>
                <td tw="text-right md:text-center">
                  <Tickets value={buy.count} isLottery />
                </td>
                <td tw="hidden md:table-cell text-right text-gray-el1">
                  <RelativeTime date={new Date(buy.createdAt)} />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>

        <P tw="mt-5 text-gray-el1">
          1. - <FormattedMessage id="lottery.jackpot-notice" />
        </P>
      </Container>
    </div>
  )
}

const Stat = styled.div(tw`bg-dark-de1 font-medium p-3`)
const StatDesc = styled.div(tw`text-gray-el4`)
const StatValue = styled.div(tw`text-white font-bold text-xl`)
const Table = styled.table(
  tw`w-full table-fixed`,
  css`
    border-collapse: separate;
    border-spacing: 0 10px;

    th {
      ${tw`p-2 text-gray-el1 font-normal text-lg`}
    }

    td {
      ${tw`p-3 lg:px-5`}
    }
  `
)
