import styled from '@emotion/styled'
import 'twin.macro'
import { GameState } from '@crashgg/types/dist'
import { css } from '@emotion/react'
import { blink } from './lib/animations'

interface Props {
  state: GameState
}

export const Blink = styled.div<Props>((props) => [
  props.state === GameState.Ended &&
    css`
      // animation: 150ms ${blink} ease;
    `
])
