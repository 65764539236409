import React from 'react'
import styled from '@emotion/styled'
import { FormattedMessage } from 'react-intl'
import tw from 'twin.macro'
import { H3, P, Button } from '../_common/components'
import { ReactComponent as Discord } from '../_common/assets/discord.svg'
import { ReactComponent as Steam } from '../_common/assets/steam.svg'
import { css } from '@emotion/react'
import { useUser } from '../_common/hooks/useUser'
import { API } from '../_common/API'

export const LinkedProfiles: React.FC = () => {
  const { user } = useUser()

  return (
    <section>
      <H3>
        <FormattedMessage id="settings.linked-accounts" />
      </H3>

      <div tw="grid gap-3 md:(grid-cols-2 gap-10) mt-3">
        <ProfileCard tw="bg-[#1b2838]">
          <ProfileIcon>
            <Steam />
          </ProfileIcon>
          <ProfileInfo>
            <H3>Steam</H3>
            <P tw="text-sm">
              {user.name}
              <br />
              <FormattedMessage
                id="settings.account-id"
                values={{ id: user.steamId }}
              />
            </P>
          </ProfileInfo>
        </ProfileCard>

        <ProfileCard tw="bg-[#5865F2]">
          <ProfileIcon>
            <Discord />
          </ProfileIcon>
          <ProfileInfo>
            <H3>Discord</H3>
            <P tw="text-sm">
              {user.discordId ? (
                <>
                  <FormattedMessage
                    id={
                      user.discordJoinedGuild
                        ? 'settings.server-joined'
                        : 'settings.server-not-joined'
                    }
                  />
                  <br />
                  <FormattedMessage
                    id="settings.account-id"
                    values={{ id: user.discordId }}
                  />
                </>
              ) : (
                <FormattedMessage id="settings.not-linked" />
              )}
            </P>
          </ProfileInfo>

          <Button
            color="transparent-ring"
            tw="text-white"
            onClick={API.linkDiscord}>
            <FormattedMessage
              id={user.discordId ? 'common.refresh' : 'settings.link'}
            />
          </Button>
        </ProfileCard>
      </div>
    </section>
  )
}

const ProfileCard = styled.div(
  tw`bg-dark-el3 p-5 rounded-md flex flex-row gap-5 items-center`,
  css`
    ${H3} {
      ${tw`mb-2 leading-none`}
    }
  `
)

const ProfileInfo = styled.div(tw`flex-1`)

const ProfileIcon = styled.div(
  tw`bg-black bg-opacity-10 h-14 w-14 rounded-full flex items-center justify-center`,
  css`
    svg {
      ${tw`fill-current h-5`}
    }
  `
)
