import { API } from '../../_common/API'
import { SteamItemExternal, SteamItemInternal } from '../../_common/types'

export const getInventory = async (): Promise<SteamItemExternal[]> => {
  if (API.isGuest) return []

  return API.get('/steam/inventory')
}

export const getShop = async (): Promise<SteamItemInternal[]> => {
  return API.get('/steam/shop')
}

export interface WagerReq {
  remaining: number
  scale: number
}

export const getWagerReq = async (): Promise<WagerReq> => {
  return API.get('/user/me/wager-requirement')
}

export const deposit = async (items: SteamItemExternal[]) => {
  // TODO: send a condensed payload with proper amounts
  const minimalItems = items.map((item) => ({
    externalId: item.externalId,
    amount: 1,
    price: item.price
  }))

  return API.post('/steam/deposit', { items: minimalItems })
}

export const withdraw = async (items: SteamItemInternal[]) => {
  const minimalItems = items.map((item) => ({
    id: item.id,
    price: item.price
  }))

  return API.post('/steam/withdraw', { items: minimalItems })
}
