import styled from '@emotion/styled'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import tw from 'twin.macro'
import { Container, H2 } from '../_common/components'
import IconBuy from './assets/icon-buy.svg'
import IconWait from './assets/icon-wait.svg'
import IconWin from './assets/icon-win.svg'
import ticketExplanation from './assets/ticket-explanation.png'
import poolAllocation from './assets/pool-allocation.png'

export const HowItWorks: React.FC = () => {
  return (
    <div tw="py-5 lg:py-10">
      <Container>
        <H2 tw="text-center relative tracking-wide">
          <FormattedMessage id="lottery.how-it-works" />
        </H2>

        <div tw="grid lg:grid-cols-3 gap-5 lg:gap-20 my-10">
          {STEPS.map((step) => (
            <div key={step.name} tw="flex flex-col items-center text-center">
              <img src={step.icon} tw="h-20" alt="" />

              <div tw="text-xl font-medium text-white my-3">
                <FormattedMessage id={`lottery.${step.name}.title`} />
              </div>
              <div tw="text-lg text-gray-el1">
                <FormattedMessage id={`lottery.${step.name}.desc`} />
              </div>
            </div>
          ))}
        </div>

        <div tw="lg:(grid grid-cols-3) gap-5 pt-10">
          <div tw="col-span-2">
            <H3>
              <FormattedMessage id="lottery.criteria.title" />
            </H3>
            <P>
              <FormattedMessage id="lottery.criteria.desc" />
            </P>

            <P as="ul" tw="py-5 list-inside list-disc">
              <li>
                Ticket A - every digit but 4th matches, so it only wins "Match
                first 3" rewards
              </li>
              <li>
                Ticket B - every digit but first matches, so it does not win
                anything
              </li>
            </P>

            <P>
              Winnings do not stack - if you match first 3 numbers, you only get
              winnings from the "Match 3" pool.
            </P>
          </div>
          <div tw="w-full">
            <img
              src={ticketExplanation}
              alt="Infographic showing how tickets works"
              tw="block mx-auto max-w-sm lg:max-w-full"
            />
          </div>
        </div>

        <div tw="lg:(grid grid-cols-3) gap-5 pt-10">
          <div tw="col-span-2">
            <H3>Reward distribution</H3>
            <P>
              The lottery pool increases everytime a bet is placed on the site,
              or when someone buys tickets with coins. The pool can also be
              occasionally increased by site staff.
            </P>
            <P tw="mt-3">
              When the round ends, pool is divided into 7 chunks - 6 for every
              winning bracket, and a 30% rollover. In case no one wins in a
              certain bracket, given chunk is also rolled over to the next
              round.
            </P>
          </div>
          <div tw="w-full">
            <img
              src={poolAllocation}
              alt="Infographic showing poll allocation"
              tw="block mx-auto max-w-sm lg:max-w-full"
            />
          </div>
        </div>
      </Container>
    </div>
  )
}

const STEPS = [
  { name: 'get-tickets', icon: IconBuy },
  { name: 'enter-draw', icon: IconWait },
  { name: 'win', icon: IconWin }
]

const H3 = styled.h2(tw`text-3xl text-white font-bold mb-3`)
const P = styled.p(tw`text-xl text-gray-el1 leading-7`)
