import React from 'react'
import { useForm } from 'react-hook-form'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import 'twin.macro'
import { Button, Err, Input, Table } from '../_common/components'
import { Currency } from '../_common/components/Currency'
import { CreatePromoCode, createPromoCode, getPromoCodes } from './apis/admin'

const PROMOCODES = 'admin-promocodes'

export const PromoCodes: React.FC = () => {
  const { register, handleSubmit, reset } = useForm()
  const queryClient = useQueryClient()

  const create = useMutation(createPromoCode, {
    onSuccess: () => {
      queryClient.invalidateQueries(PROMOCODES)
      reset()
    }
  })

  const submit = (payload: CreatePromoCode) => {
    create.mutate({
      code: payload.code,
      amount: ~~(payload.amount * 100),
      minLevel: ~~(payload.minLevel ?? 1),
      maxClaims: ~~(payload.maxClaims ?? 1)
    })
  }

  const codes = useQuery(PROMOCODES, getPromoCodes)

  return (
    <div tw="grid lg:grid-cols-4 gap-5">
      <form onSubmit={handleSubmit(submit)} tw="flex flex-col gap-3">
        <Input placeholder="Code" {...register('code')} />
        <Input
          type="number"
          step="0.01"
          min="0.01"
          placeholder="Amount"
          {...register('amount')}
        />
        <Input
          type="number"
          step="1"
          min="1"
          placeholder="Uses"
          {...register('maxClaims')}
        />
        <Input
          type="number"
          placeholder="Min level"
          {...register('minLevel')}
        />
        <Button type="submit">Create code</Button>
        {create.error && <Err>{(create.error as Error).message}</Err>}
      </form>

      <div tw="col-span-3">
        <Table isFull>
          <thead>
            <Table.Tr>
              <Table.Td>Code</Table.Td>
              <Table.Td>Amount</Table.Td>
              <Table.Td>Min. Level</Table.Td>
              <Table.Td>Claims</Table.Td>
            </Table.Tr>
          </thead>
          <tbody>
            {codes.data?.map((code) => (
              <Table.Tr key={code.code}>
                <Table.Td tw="text-white font-bold tracking-wide">
                  {code.code}
                </Table.Td>
                <Table.Td>
                  <Currency value={code.amount} size="small" />
                </Table.Td>
                <Table.Td>{code.minLevel}</Table.Td>
                <Table.Td>
                  {code.claims} / {code.maxClaims}
                </Table.Td>
              </Table.Tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  )
}
