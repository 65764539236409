import React from 'react'
import { FormattedMessage } from 'react-intl'
import tw from 'twin.macro'
import { Avatar } from '../_common/components'
import { useUser } from '../_common/hooks/useUser'
import headerBg from './assets/header-bg.svg'

export const Header: React.FC = () => {
  const { user } = useUser()

  return (
    <WithBackground>
      <div tw="flex flex-row gap-5 items-center">
        <Avatar src={user.avatar} size="big" />
        <div>
          <h2 tw="text-xl font-bold">{user.name}</h2>
          <div tw="text-gray-el3 text-sm font-light">
            <FormattedMessage
              id="profile.joined"
              values={{ when: new Date(user.createdAt) }}
            />
          </div>
        </div>
      </div>
    </WithBackground>
  )
}

const WithBackground: React.FC = ({ children }) => {
  return (
    <div>
      <div
        css={[
          tw`h-44 bg-cover filter blur-xl z-[-10]`,
          { backgroundImage: `url(${headerBg})`, backfaceVisibility: 'hidden' }
        ]}>
        <div tw="h-44 bg-dark opacity-50"></div>
      </div>

      <div
        css={tw`relative -mt-44 h-44 z-20 flex flex-row items-center justify-center sm:(justify-start p-5)`}>
        {children}
      </div>
    </div>
  )
}
