import React, { forwardRef } from 'react'
import { Float } from '@crashgg/types/dist'
import styled from '@emotion/styled'
import tw from 'twin.macro'
import { Input, inputVariants, Props as InputProps } from './Input'
import { P } from './Text'
import { ReactComponent as CoinIcon } from '../assets/currency-coin.svg'
import { useUser } from '../hooks/useUser'

interface Props extends InputProps {
  setAmount: (v: string) => void
}

export const BetInput = forwardRef<HTMLInputElement, Props>(
  ({ value, setAmount, variant, disabled, ...props }, ref) => {
    const { user } = useUser()

    const multiply = (by: Float) => {
      if (disabled) return
      setAmount((Number(value) * by).toFixed(2))
    }

    const setMax = () => {
      if (disabled) return
      setAmount(String((user?.balance || 0) / 100))
    }

    return (
      <InputWrapper css={inputVariants[variant || 'default']}>
        <CoinIcon tw="w-5 h-5 my-auto ml-1 flex-shrink-0" />
        <Input
          ref={ref}
          type="number"
          min={0}
          step={0.01}
          tw="w-[5.5rem] mx-0 p-2 text-lg"
          value={value}
          onChange={(e) => !disabled && setAmount(e.target.value)}
          variant={variant}
          disabled={disabled}
          {...props}
        />
        <InputButton onClick={() => multiply(0.5)}>1/2</InputButton>
        <InputButton onClick={() => multiply(2)}>x2</InputButton>
        <InputButton onClick={setMax}>max</InputButton>
      </InputWrapper>
    )
  }
)

export const AltLabel = styled(P)(tw`mb-2 text-center md:text-left`)

export const InputButton = styled.button(
  tw`bg-gainsBgChatInput hover:bg-gainsBgSubNavbarHover text-gray-el4 text-xs px-2 mx-1 my-1 transition duration-150 rounded flex-shrink-0`
)

export const InputWrapper = styled.div(
  tw`bg-gainsBgChat flex flex-row px-1 py-1 rounded-md overflow-hidden`
)
