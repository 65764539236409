import React from 'react'
import { FormattedMessage } from 'react-intl'
import tw from 'twin.macro'
import { Avatar, Button } from '../_common/components'
import { callBots, Duel, DuelState, joinDuel } from './lib/duels-api'
import { getPlayerData, getRoll } from './lib/utils'
import { ReactComponent as BotIcon } from './assets/bot-icon.svg'
import { Int } from '@crashgg/types/dist'
import { useUser } from '../_common/hooks/useUser'
import { noop } from '../_common/utils'
import { API } from '../_common/API'

interface Props {
  teamId: Int
  game: Duel
}

export const Player: React.FC<Props> = ({ teamId, game }) => {
  const { user, isAuthenticated } = useUser()
  const isOwner = user?.id === game.createdById

  const player = game.players.find((p) => p.team === teamId)
  const data = player && getPlayerData(player, game.id)
  const chance = (100 / game.playerCount).toFixed(2)
  const status = getStatus(teamId, game)
  const buttonColor = game.mode === 'lo' ? 'purple' : 'primary'

  const join = () => {
    if (!isAuthenticated) return API.login()
    joinDuel(game.id, teamId).catch(noop)
  }

  return (
    <div
      css={[
        tw`h-16 px-2 bg-dark-el1 rounded-md flex flex-row items-center gap-2 transition duration-150`,
        status === 'rolling' && tw`bg-dark-el2`,
        status === 'winner' &&
          tw`bg-gradient-to-r from-[rgb(25,96,62)] to-dark-el2`
      ]}>
      {data ? (
        <>
          <Avatar src={data.avatar} />

          <div tw="flex-1 truncate">
            <div>
              {data.name}
              {data.isBot && (
                <BotIcon
                  tw="w-4 fill-current text-gray-de1 inline ml-2 pb-1"
                  title="Bot"
                />
              )}
            </div>
            <div tw="text-xs text-gray-el3">
              <FormattedMessage id="duels.chance" values={{ chance }} />
            </div>
          </div>

          <div
            css={[
              tw`w-20 h-10 bg-dark-el3 rounded-md text-center flex items-center justify-center transition duration-150`,
              status === 'rolling' && tw`bg-dark-el4`,
              status === 'winner' && tw`bg-[#264d3a]`
            ]}>
            {status === 'waiting' ? (
              <div tw="text-xs">Waiting...</div>
            ) : status === 'rolling' ? (
              <div tw="text-xs">Rolling...</div>
            ) : (
              <div tw="font-bold">{getRoll(game.outcomes[teamId - 1])}</div>
            )}
          </div>
        </>
      ) : (
        <>
          <div tw="flex-1 truncate text-gray-el4 text-sm ml-1">
            <FormattedMessage id="duels.waiting-for-players" />
          </div>
          {isOwner ? (
            <Button
              size="small"
              color={buttonColor}
              onClick={() => callBots(game.id)}>
              Call bots
            </Button>
          ) : (
            <Button size="small" color={buttonColor} onClick={join}>
              Join duel
            </Button>
          )}
        </>
      )}
    </div>
  )
}

const getStatus = (teamId: Int, game: Duel) => {
  if (game.state === DuelState.Finished) {
    return teamId === game.winningTeam ? 'winner' : 'rolled'
  }
  if (teamId === game.outcomes?.length) return 'rolling'
  if (teamId > game.outcomes?.length) return 'waiting'
  return 'rolled'
}
