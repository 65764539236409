import React from 'react'
import { IntlProvider as ReactIntlProvider } from 'react-intl'
import { messages } from './lib/i18n'

export const IntlProvider: React.FC = ({ children }) => {
  const locale = 'en-US'

  return (
    <ReactIntlProvider
      messages={messages[locale]}
      locale={locale}
      defaultLocale="en-US">
      {children}
    </ReactIntlProvider>
  )
}
