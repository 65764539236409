import React, { useState } from 'react'
import tw from 'twin.macro'
import { Chat } from './Chat'
import { ReactComponent as ChevronLeftIcon } from './assets/chevron-left.svg'
import { LogoBox } from './LogoBox'

/**
 * Responsive sidebar component, inspired by this demo:
 * @see https://codesandbox.io/s/react-minimal-side-navigation-example-y299d
 */

export const Sidebar: React.FC = () => {
  const deviceLg = window.innerWidth >= 1024
  const [isOpen, setIsOpen] = useState(deviceLg)

  const toggle = () => setIsOpen(!isOpen)

  return (
    <>
      <div
        onClick={toggle}
        css={[
          tw`fixed inset-0 z-20 block bg-black opacity-50 xl:hidden`,
          isOpen ? tw`block` : tw`hidden`
        ]}
      />

      <div
        css={[
          tw`fixed h-full w-screen max-w-[20rem] inset-y-0 z-30 bg-gainsBg flex flex-col xl:static`,
          tw`transition duration-200 ease-out transform translate-x-0 xl:(ease-out translate-x-0)`,
          isOpen ? tw`ease-out translate-x-0` : tw`ease-in -translate-x-full`
        ]}>
        <LogoBox />
        <Chat />
      </div>

      <div
        css={[
          tw`absolute right-5 bg-dark-el2 hover:bg-dark-el3 text-gray-de1 flex z-[35] w-10 h-10 rounded-md drop-shadow-md cursor-pointer duration-200`,
          tw`xs:(top-auto bottom-5 right-auto) xl:hidden`,
          isOpen ? tw`xs:left-[21rem] top-3` : tw`left-5 top-20`
        ]}
        onClick={toggle}>
        <ChevronLeftIcon
          css={[
            tw`fill-current m-auto h-6 duration-200`,
            !isOpen && tw`rotate-180`
          ]}
        />
      </div>
    </>
  )
}
