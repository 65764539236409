import React from 'react'
import { Int } from '@crashgg/types/dist'
import { FormattedNumber } from 'react-intl'
import tw, { TwStyle } from 'twin.macro'
// import { ReactComponent as CoinIcon } from '../assets/currency-coin.svg'
import chipsIcon from '../assets/chips-med.png'
import { ReactComponent as TicketIcon } from '../assets/ticket.svg'

export interface Props {
  value?: Int
  size?: Sizes
  comparison?: boolean
  styles?: TwStyle | any
  iconOnly?: boolean
}

type Sizes = 'xs' | 'small' | 'default' | 'big' | 'huge'

export const Currency: React.FC<Props> = ({
  value = 0,
  styles,
  size = 'default',
  comparison = false,
  iconOnly
}) => {
  return (
    <div
      css={[
        WRAPPER_STYLES,
        getColor(value, comparison),
        wrapperStyles[size],
        styles
      ]}>
      <img src={chipsIcon} alt="chips" tw="h-4 select-none" />
      {/* <CoinIcon css={iconStyles[size]} /> */}
      <div>
        {comparison && value > 0 && '+'}
        {!iconOnly && (
          <span tw="text-gainsGold">
            <FormattedNumber
              value={value / 100}
              minimumFractionDigits={2}
              maximumFractionDigits={2}
            />
          </span>
        )}
      </div>
    </div>
  )
}

export const Tickets: React.FC<Props & { isLottery?: boolean }> = ({
  value = 0,
  styles,
  isLottery,
  size = 'default',
  comparison = false,
  iconOnly = false
}) => {
  return (
    <div
      css={[
        WRAPPER_STYLES,
        getColor(value, comparison),
        wrapperStyles[size],
        styles
      ]}>
      <TicketIcon
        css={[
          iconStylesTickets[size],
          isLottery ? tw`text-[#F8E555]` : tw`text-red`,
          tw`fill-current transform -rotate-45`
        ]}
      />
      {comparison && value > 0 && '+'}
      {!iconOnly && <FormattedNumber value={value} />}
    </div>
  )
}

const WRAPPER_STYLES = tw`inline-flex flex-row items-center leading-[0]`

const getColor = (value: Int, colored: boolean) => {
  if (!colored || value === 0) return tw`text-white`

  return value > 0 ? tw`text-green` : tw`text-red`
}

const wrapperStyles: Record<Sizes, TwStyle> = {
  xs: tw`text-sm gap-[4px] tracking-wide`,
  small: tw`text-sm gap-[6px] tracking-wide`,
  default: tw`text-base gap-2 font-semibold`,
  big: tw`text-xl gap-2 font-bold`,
  huge: tw`text-6xl gap-5 font-bold`
}

// const iconStyles: Record<Sizes, TwStyle> = {
//   xs: tw`w-3 h-3`,
//   small: tw`w-4 h-4`,
//   default: tw`w-4 h-4`,
//   big: tw`w-7 h-7`,
//   huge: tw`w-14 h-14 mt-3`
// }

const iconStylesTickets: Record<Sizes, TwStyle> = {
  xs: tw`w-3 h-3`,
  small: tw`w-3 h-3`,
  default: tw`w-4 h-4`,
  big: tw`w-5 h-5 pt-1`,
  huge: tw`w-16 h-16`
}

export const CurrencyUsd = React.forwardRef<{ value: Int } & any, any>(
  ({ value, ...props }, ref) => (
    <FormattedNumber
      value={value / 100}
      // eslint-disable-next-line react/style-prop-object
      style="currency"
      currency="USD"
      {...props}
      ref={ref}
    />
  )
)
