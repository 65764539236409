import { RouteComponentProps } from '@reach/router'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import 'twin.macro'
import { Container, H1, P, Protected } from '../_common/components'
import { useTitle } from '../_common/hooks/useTitle'
import { usePromoCode } from './apis/deposit'
import { DepositTabs } from './DepositTabs'
import { UseCode } from './UseCode'

export const PromoCodePage: React.FC<RouteComponentProps> = () => {
  useTitle(`Promo Codes`)

  return (
    <Protected>
      <div id="bg" />
      <DepositTabs />
      <Container page sm>
        <H1>
          <FormattedMessage id="deposit.promo-code.title" />
        </H1>
        <P>
          <FormattedMessage id="deposit.promo-code.desc" />
        </P>

        <div tw="mt-10">
          <UseCode method={usePromoCode} withCaptcha />
        </div>
      </Container>
    </Protected>
  )
}
