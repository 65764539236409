import { Int } from '@crashgg/types/dist'
import 'twin.macro'
import React, { useEffect, useState } from 'react'
import { FormattedDate, FormattedMessage } from 'react-intl'
import { useQuery } from 'react-query'
import { AOut, Container, H2, H3, P, Pagination } from '../_common/components'
import { Claim } from './Claim'
import { getLottery, PrevLottery } from './lib/lottery-api'
import { PreviousValue } from './PreviousValue'
import { Numbers } from './Numbers'
import { Winner } from './Winner'
import { getRandomOrgUrl } from '../_common/utils'

interface Props {
  currentId: Int
}

export const Previous: React.FC<Props> = ({ currentId }) => {
  const [id, setId] = useState(currentId - 1)

  useEffect(() => {
    setId(currentId - 1)
  }, [currentId, setId])

  const { isSuccess, data: d } = useQuery(
    ['lottery', id],
    () => getLottery(id),
    { enabled: id > 0, keepPreviousData: true }
  )

  // For some reason, this is necessary
  const data: PrevLottery = d as any

  if (currentId === 1 || !isSuccess) return null

  const winners = data.winners.sort((a, b) => b.coinsWon - a.coinsWon)
  const verifyLink = getRandomOrgUrl(data.fairness)

  return (
    <div tw="bg-dark-de1 py-5 lg:py-10">
      <Container>
        <H2 tw="text-center relative tracking-wide">
          <FormattedMessage id="lottery.previous" />
        </H2>

        <Claim />

        {isSuccess && (
          <div tw="bg-dark rounded-md px-5 py-3">
            <div tw="flex flex-col lg:flex-row lg:px-5 justify-between items-center">
              <PreviousValue data={data} />

              <div>
                <div tw="text-lg font-medium mb-3">
                  <FormattedMessage id="lottery.winning-numbers" />
                </div>
                <Numbers numbers={data.winningNumbers} />
              </div>
            </div>

            <div tw="py-5 mt-2 border-t border-dark-el2">
              <H3 tw="text-lg font-bold tracking-wide text-center pb-2">
                <FormattedMessage id="lottery.winners" />
              </H3>

              <div tw="grid md:grid-cols-2 lg:grid-cols-3 gap-5 lg:gap-10">
                {winners.map((winner) => (
                  <Winner
                    key={winner.userId}
                    winner={winner}
                    winningNumbers={data.winningNumbers}
                  />
                ))}
              </div>
            </div>

            <div tw="pt-5 mt-2 border-t border-dark-el2 flex flex-wrap flex-col items-center gap-3 md:(flex-row justify-between gap-0)">
              <Pagination pages={currentId - 1} page={id} setPage={setId} />
              <div tw="text-center md:text-right">
                <P>
                  Drawn on{' '}
                  <FormattedDate
                    value={data.endsAt}
                    dateStyle="medium"
                    timeStyle="short"
                  />
                </P>
                <AOut href={verifyLink!}>Verify on random.org</AOut>
              </div>
            </div>
          </div>
        )}
      </Container>
    </div>
  )
}
