import React, { useState } from 'react'
import { Millis } from '@crashgg/types/dist'
import { useAnimationFrame } from '../_common/hooks/useAnimationFrame'

export interface Props {
  startsAt?: Millis
}

export const StartCountdown: React.FC<Props> = ({ startsAt }) => {
  const [left, setLeft] = useState(0)

  useAnimationFrame(() => {
    if (startsAt) {
      const msLeft = startsAt - Date.now()
      setLeft(Math.max(msLeft, 0) / 1000)
    }
  }, [startsAt])

  if (!startsAt) return <>Starting the game...</>

  return <>Starting in {left.toFixed(2)}s...</>
}
