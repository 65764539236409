import React from 'react'
import { Global, css } from '@emotion/react'
import tw, { GlobalStyles as TwinStyles } from 'twin.macro'
import bgUrl from '../_common/assets/bg.png'
import bgLoginButton from '../_common/assets/bg-login-button.svg'

export const GlobalStyles: React.FC = () => (
  <>
    <TwinStyles />
    <Global styles={customStyles} />
  </>
)

const customStyles = css`
  body {
    ${tw`text-white font-light font-montserrat`}
    background: linear-gradient(180deg, #050b35 0%, #050a32 100%);
  }

  #bg {
    background-image: url(${bgUrl});
    background-size: cover;
    z-index: -999;
    opacity: 0.75;
    ${tw`absolute inset-0 w-screen h-screen`}
  }

  @keyframes anim-button-bg {
    from {
      transform: translateX(-50%);
    }
    to {
      transform: translateX(0%);
    }
  }

  .animated-button-bg {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 480px;
    background-image: url(${bgLoginButton});
    animation: anim-button-bg 10s linear infinite;
  }

  ::-webkit-scrollbar {
    ${tw`w-1 h-1`}
  }

  .hideScroll::-webkit-scrollbar {
    ${tw`w-0`}
  }

  .hideScroll:hover::-webkit-scrollbar {
    ${tw`w-1`}
  }

  ::-webkit-scrollbar-track {
    ${tw`bg-transparent`}
  }

  ::-webkit-scrollbar-thumb {
    ${tw`rounded-full bg-dark-el2`}
  }

  ::-webkit-scrollbar-thumb:hover {
    ${tw`bg-dark-el3`}
  }

  div[tabindex] {
    ${tw`flex-1 h-full`}
  }

  .skeleton-container {
    ${tw`flex w-full`}
  }

  .Toastify__toast-body {
    ${tw`text-sm`}
  }
`
