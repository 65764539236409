import { RouteComponentProps } from '@reach/router'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import 'twin.macro'
import { Button, Center, H1, P, Protected } from '../_common/components'
import { createSkinsbackDeposit } from './apis/deposit'
import { DepositTabs } from './DepositTabs'

export const SkinsbackPage: React.FC<RouteComponentProps> = () => {
  const deposit = () => {
    createSkinsbackDeposit().then((url) => {
      window.location.href = url
    })
  }

  return (
    <Protected>
      <div id="bg" />
      <DepositTabs />
      <Center>
        <H1>
          <FormattedMessage id="deposit.skinsback.header" />
        </H1>
        <P tw="text-lg pb-5 max-w-lg mx-auto">
          <FormattedMessage id="deposit.skinsback.info" />
        </P>
        <Button color="primary" size="big" onClick={deposit}>
          <FormattedMessage id="deposit.skinsback.cta" />
        </Button>
      </Center>
    </Protected>
  )
}
