import React from 'react'
import 'twin.macro'
import { Duel } from './lib/duels-api'
import { ReactComponent as ShieldIcon } from './assets/shield-check.svg'
import { Link } from '@reach/router'
import { FormattedMessage } from 'react-intl'
import { AOut } from '../_common/components'
import { getRandomOrgUrl } from '../_common/utils'

interface Props {
  game: Duel
}

export const Fairness: React.FC<Props> = ({ game }) => {
  const verifyUrl = getRandomOrgUrl(game.fairness)

  return (
    <div tw="py-2 mx-[4%] border-t border-dark-el4">
      <div tw="flex flex-row justify-between items-center pt-3 pb-1">
        <div tw="text-green">
          <ShieldIcon tw="h-4 fill-current inline mr-1 mb-1" />
          <span tw="font-bold">
            <FormattedMessage id="common.provably-fair" />{' '}
          </span>
          <span tw="text-gray">
            <FormattedMessage id="common.by-random.org" />
          </span>
        </div>
        <div tw="space-x-5 text-sm">
          <Link
            tw="text-gray hover:text-gray-el2 transition duration-150 outline-none hover:underline"
            to="/provably-fair#duels">
            <FormattedMessage id="common.how-to-use" />
          </Link>
          {verifyUrl && (
            <AOut
              tw="text-gray hover:text-gray-el2 transition duration-150 outline-none"
              href={verifyUrl}>
              <FormattedMessage id="common.verify" />
            </AOut>
          )}
        </div>
      </div>

      <Data
        label="Random"
        value={game.fairness?.random && JSON.stringify(game.fairness?.random)}
      />
      <Data label="Random.org Signature" value={game.fairness?.signature} />
    </div>
  )
}

interface DataProps {
  label: string
  value: string
}

const Data: React.FC<DataProps> = ({ label, value }) => {
  return (
    <div tw="my-2">
      <div tw="text-sm text-gray">{label}</div>
      <div tw="flex flex-row gap-2 overflow-hidden">
        <div tw="text-sm truncate select-all">
          {value || 'Not yet available'}
        </div>
      </div>
    </div>
  )
}
