import { Card, Int } from '@crashgg/types/dist'

export const humanHandValue = (
  cards: (Card | [-1])[],
  drawn: Int,
  revealSecond = true
) => {
  const revealNum = revealSecond ? drawn : 1

  let value = cards
    .slice(0, revealNum)
    .reduce((sum, card) => sum + cardValue(card), 0)

  let acesCount = cards.reduce(
    (count, [rank]) => count + (rank === 'A' ? 1 : 0),
    0
  )

  while (value > 21 && acesCount > 0) {
    value -= 10
    acesCount -= 1
  }

  if (acesCount > 0 && value >= 11 && value < 21) {
    return `${value - 10}, ${value}`
  }

  return value === 0 ? '—' : String(value)
}

export const cardValue = ([rank]: Card | [-1]) => {
  if (rank === -1) return 0

  if (typeof rank === 'number') return rank
  if (rank === 'A') return 11
  return 10
}
