import styled from '@emotion/styled'
import tw from 'twin.macro'
import avatarEmpty from '../assets/avatar-empty.svg'
import avatarAnon from '../assets/avatar-anon.png'

export interface AvatarProps {
  size?: keyof typeof sizes
}

const sizes = {
  small: tw`w-6 h-6 rounded-md`,
  'small+': tw`w-[2.25rem] h-[2.25rem] rounded-md`,
  medium: tw`w-10 h-10 rounded-xl`,
  big: tw`w-20 h-20 rounded-3xl shadow-md`
}

export const Avatar = styled.img((props: AvatarProps) => [
  sizes[props.size || 'medium'],
  tw`border border-dark-el3`
])

Avatar.defaultProps = {
  alt: '',
  src: avatarEmpty,
  onError: ({ currentTarget }) => {
    if (currentTarget.src === avatarAnon) return

    currentTarget.src = avatarAnon
  }
}
