import React from 'react'
import styled from '@emotion/styled'
import tw from 'twin.macro'
import { Sidebar } from '../../sidebar/Sidebar'
import { Navigation } from '../../navigation/Navigation'
import { ReactComponent as Spinner } from '../assets/spinner.svg'
import { RouteComponentProps } from '@reach/router'

export const Layout: React.FC<RouteComponentProps> = ({ children }) => {
  return (
    <div tw="flex flex-row w-screen h-screen overflow-hidden">
      <Sidebar />

      <div tw="h-full flex-1 flex flex-col max-w-full">
        <Navigation />
        <div tw="flex-1 overflow-x-hidden overflow-y-auto max-w-full">
          {children}
        </div>
      </div>
    </div>
  )
}

export const Center: React.FC = ({ children }) => {
  return (
    <div tw="h-full flex text-center pb-20">
      <div tw="m-auto">{children}</div>
    </div>
  )
}

export const LoadingScreen: React.FC = () => {
  return (
    <Center>
      <Spinner tw="h-12 fill-current text-gray-el1" />
    </Center>
  )
}

interface ContainerProps {
  lg?: boolean
  md?: boolean
  sm?: boolean
  page?: boolean
}

export const Container = styled.div((props: ContainerProps) => [
  tw`w-full max-w-[72rem] mx-auto px-2 md:px-6 xl:px-10`,
  props.lg && tw`max-w-[90rem]`,
  props.md && tw`max-w-[80rem]`,
  props.sm && tw`max-w-[46rem]`,
  props.page && tw`pt-5 md:pt-10 pb-20`
])

export const ContainerLg = styled(Container)(tw`max-w-[90rem]`)
