import { Millis } from '@crashgg/types/dist'
import { useEffect, useState } from 'react'

export const useDebounce = <T>(value: T, delay: Millis) => {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)

    return () => clearTimeout(handler)
  }, [value, delay])

  return debouncedValue
}
