import React from 'react'
import 'twin.macro'
import { Fairness as IFairness } from './lib/useFairness'

interface Props {
  fairness: IFairness
}

export const Fairness: React.FC<Props> = ({ fairness }) => {
  return (
    <div tw="text-sm text-gray-el2 px-3">
      Client Seed: {fairness.seed}
      <br />
      Server Hash: {fairness.serverHash}
      {fairness.prevGame && (
        <div tw="mt-5">
          Previous Game Client Seed: {fairness.prevGame.seed}
          <br />
          Previous Game Server Seed: {fairness.prevGame.serverSeed}
          <br />
          Previous Game Server Hash: {fairness.prevGame.serverHash}
          <br />
        </div>
      )}
    </div>
  )
}
