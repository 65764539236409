import ReactGA from 'react-ga'
import { Metric } from 'web-vitals'
import reportWebVitals from './reportWebVitals'

const trackers: ReactGA.Tracker[] = []

if (process.env.REACT_APP_GA_TRACKING_ID) {
  trackers.push({
    trackingId: process.env.REACT_APP_GA_TRACKING_ID,
    gaOptions: { name: 'analytics' }
  })
}

if (process.env.REACT_APP_GA_ADS_ID) {
  trackers.push({
    trackingId: process.env.REACT_APP_GA_ADS_ID,
    gaOptions: { name: 'ads' }
  })
}

if (trackers.length) {
  ReactGA.initialize(trackers)
  ReactGA.pageview(window.location.pathname + window.location.search)

  reportWebVitals((metric: Metric) => {
    ReactGA.event({
      category: 'Web Vitals',
      action: metric.name,
      value: ~~(
        // values must be integers
        (metric.name === 'CLS' ? metric.value * 1000 : metric.value)
      ),
      label: metric.id,
      nonInteraction: true
    })
  })
}
