import styled from '@emotion/styled'
import tw from 'twin.macro'

interface TableProps {
  isFull?: boolean
}

export const TableComponent = styled.table((props: TableProps) => [
  tw`table-auto font-thin`,
  props.isFull && tw`w-full`
])

const TableWrapper: React.FC<TableProps> = ({ ...props }) => {
  return (
    <div tw="overflow-x-auto max-w-full">
      <TableComponent {...props} />
    </div>
  )
}

const Empty: React.FC = ({ children }) => {
  return (
    <Tr>
      <Td colSpan={10} tw="text-center align-middle py-5">
        {children}
      </Td>
    </Tr>
  )
}

const Tr = styled.tr(tw`even:bg-gainsBgChat`)

const Td = styled.td(tw`py-3 px-2 align-middle text-gray-el4 text-sm`)

const Th = styled(Td)(tw`text-gray text-sm font-normal bg-transparent`)

export const Table = Object.assign(TableWrapper, { Tr, Th, Td, Empty })
