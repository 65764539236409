import { Int, Millis } from '@crashgg/types/dist'
import { toast } from 'react-toastify'
import { API } from '../../_common/API'
import { sounds } from '../../_common/sounds'
import { PublicUser } from '../../_common/types'
import { updateUser } from '../../_common/user'
import { noop, notifyError } from '../../_common/utils'

export enum DuelState {
  Open = 'open',
  InProgress = 'in-progress',
  Finished = 'finished',
  Expired = 'expired'
}

export interface DuelPlayer {
  team: Int
  botId: Int | null
  user: PublicUser | null
  userId: Int | null
}

export interface DuelMin {
  id: Int
  mode: string
  createdAt: string
  updatedAt: string
  state: DuelState
  teamSize: Int
  playerCount: Int
  betAmount: Int
  winnings: Int
  createdById: Int
  startsAt?: Millis
  outcomes: Int[]
  players: DuelPlayer[]
}

export interface Duel extends DuelMin {
  winningTeam?: Int
  fairness?: any
}

export interface ActiveDuels {
  active: DuelMin[]
  history: DuelMin[]
}

export const getDuels = async (): Promise<ActiveDuels> => {
  return API.get('/duels')
}

export interface Join {
  duelId: Int
  newPlayers: DuelPlayer[]
  winnings?: Int
}

export interface Start {
  duelId: Int
  startsIn: Int
}

export interface Roll {
  duelId: Int
  outcomes: Int[]
  round: Int
  roll: Int
}

export interface Finish {
  duelId: Int
  winningTeam: Int
  winningPlayer: DuelPlayer
  outcomes: Int[]
  winnings: Int
  fairness: any
}

export const getDuel = async (id: Int): Promise<Duel> => {
  return API.get(`/duels/${id}`)
}

export const callBots = async (id: Int) => {
  return API.patch(`/duels/${id}/join-bots`).catch(noop)
}

export const createDuel = async (amount: Int, players: Int, mode: string) => {
  if (amount < 10) return notifyError('Minimum bet amount is 0.10 coins')

  const { newBalance, newTickets } = await API.post('/duels', {
    type: {
      2: '1v1',
      3: '1v1v1',
      4: '1v1v1v1'
    }[players],
    mode,
    amount
  })

  updateUser({ balance: newBalance, tickets: newTickets })
  toast.success('Dice Duel created!')
  sounds.placeBet()
}

export const joinDuel = async (duelId: Int, team: Int) => {
  const { newBalance, newTickets } = await API.patch('/duels/join', {
    duelId,
    team,
    vesion: 2
  })

  updateUser({ balance: newBalance, tickets: newTickets })
}
