import styled from '@emotion/styled'
import { Link } from '@reach/router'
import tw from 'twin.macro'
import { prettyError } from '../utils'

export const H1 = styled.h1(tw`text-4xl font-bold mb-5`)
export const H2 = styled.h2(tw`text-3xl font-bold mb-3`)
export const H3 = styled.h3(tw`text-xl font-bold mb-2`)

export const P = styled.p(tw`text-gray-el4 text-base`)
export const A = styled(Link)(tw`text-white hover:underline`)
export const AOut = styled.a(tw`text-white hover:underline`)
AOut.defaultProps = {
  target: '_blank',
  rel: 'noopener noreferrer'
}
export const B = styled.b(tw`text-white`)
export const Err = styled(P)(tw`text-red`)
export const Success = styled(P)(tw`text-green`)

export const ErrPretty: React.FC<{ error: any }> = ({ error }) => {
  if (!error) return null

  return <Err>{prettyError((error as Error).message)}</Err>
}
