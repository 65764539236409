import React from 'react'
import { GlobalStyles } from './GlobalStyles'
import * as Sentry from '@sentry/react'
import { FormattedMessage } from 'react-intl'
import { Center } from '../_common/components'
import 'twin.macro'

export const ErrorBoundary: React.FC = ({ children }) => {
  if (!process.env.REACT_APP_SENTRY_DSN) return <>{children}</>

  return (
    <Sentry.ErrorBoundary fallback={FallbackComponent} showDialog>
      {children}
    </Sentry.ErrorBoundary>
  )
}

const FallbackComponent = () => {
  return (
    <>
      <GlobalStyles />
      <div tw="h-screen w-screen">
        <Center>
          <h1 tw="text-3xl font-bold">
            <FormattedMessage id="error.unexpected" />
          </h1>
          <p tw="text-xl">
            <FormattedMessage id="error.reload" />
          </p>
        </Center>
      </div>
    </>
  )
}
