import { Int } from '@crashgg/types/dist'
import { API } from '../../_common/API'
import { WSCrash } from '../../_common/WS'

export interface CreatePromoCode {
  code: string
  maxClaims: Int
  amount: Int
  minLevel?: Int
}

export interface PromoCode extends CreatePromoCode {
  claims: Int
}

export const getPromoCodes = async (): Promise<PromoCode[]> => {
  const { promocodes } = await API.get('/payments/promocodes/active')
  return promocodes
}

export const createPromoCode = async (payload: CreatePromoCode) => {
  await API.post('/payments/promocodes', payload)
}

export interface CreateGiftcards {
  count: Int
  amount: Int
}

export const createGiftcards = async (
  payload: CreateGiftcards
): Promise<string[]> => {
  const { codes } = await API.post('/payments/giftcards/create', payload)

  return codes
}

export interface CreditUser {
  amount: Int
  userId: string
}

export const creditUser = async ({ userId, amount }: CreditUser) => {
  return API.patch(`/user/${userId}/credit`, { amount, currency: 'SKIN' })
}

export const crashPause = () => {
  WSCrash.send(['admin:pause', {}])
}

export const crashResume = () => {
  WSCrash.send(['admin:resume', {}])
}
