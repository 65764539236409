import { useEffect, useState } from 'react'

const SECOND = 1000

export const useRainTimer = () => {
  const [timeLeft, setTimeLeft] = useState(1800)
  const [hasJoined, setHasJoined] = useState(false)

  useEffect(() => {
    const getTimeLeft = () => {
      const left = ~~((target - new Date().getTime()) / 1000)
      return Math.max(left, 0)
    }

    let timer: NodeJS.Timeout
    let target = getTarget()
    setTimeLeft(getTimeLeft())

    const update = () => {
      const left = getTimeLeft()
      setTimeLeft(left)
      if (left === 0) {
        setHasJoined(false)
        target = getTarget()
      }
    }

    timer = setTimeout(() => {
      update()
      timer = setInterval(update, SECOND)
    }, SECOND - (Date.now() % SECOND))

    return () => clearInterval(timer)
  }, [])

  const markJoined = () => setHasJoined(true)

  return { timeLeft, hasJoined, markJoined }
}

// RAIN_1 < RAIN_2
const RAIN_1 = 15
const RAIN_2 = 45

const getTarget = () => {
  const target = new Date()
  target.setMilliseconds(0)
  target.setSeconds(0)

  const minute = new Date().getMinutes()

  if (minute < RAIN_1) target.setMinutes(RAIN_1)
  else if (minute < RAIN_2) target.setMinutes(RAIN_2)
  else {
    target.setHours(target.getHours() + 1)
    target.setMinutes(RAIN_1)
  }

  return target.getTime()
}
