import {
  GameState,
  GameStatus,
  Tick,
  BetState,
  CrashBet,
  Float,
  CrashPlaceBet,
  CrashHistory,
  Int,
  Millis
} from '@crashgg/types'
import { useReducer, useEffect } from 'react'
import { API } from '../../_common/API'
import { useUser } from '../../_common/hooks/useUser'
import { sounds } from '../../_common/sounds'
import { credit } from '../../_common/user'
import { notifyError } from '../../_common/utils'
import { WSCrash } from '../../_common/WS'
import { Item, findItemsOfValue } from './items'

export interface UserBet extends CrashBet {
  items: Item[]
}

export interface Init {
  clientSeed: string
  maxTotalWin: Int
  maxBet: Int
}

export interface State extends GameStatus, Init {
  isConnected: boolean
  at: Float
  userBets: UserBet[]
  userId: Int
  gameHistory: CrashHistory[]
  ping: Int
  clockDiff: Int
}

type Action =
  | { type: 'init'; payload: Init }
  | { type: 'ping'; payload: Int }
  | { type: 'disconnect'; payload: undefined }
  | { type: 'updateStatus'; payload: GameStatus }
  | { type: 'newBet'; payload: CrashBet }
  | { type: 'onTick'; payload: Tick }
  | { type: 'historyEntry'; payload: CrashHistory }
  | { type: 'setUserId'; payload: Int }
  | { type: string; payload: any }

const initialState: State = {
  isConnected: false,
  gameId: -1,
  state: GameState.Betting,
  startedAt: Date.now(),
  bets: [],
  userBets: [],
  at: -1,
  userId: -1,
  gameHistory: [],
  clientSeed: '',
  maxTotalWin: 0,
  maxBet: 0,
  ping: 100,
  clockDiff: 0
}

const reducer = (state: State, { type, payload }: Action): State => {
  switch (type) {
    case 'init':
      return { ...state, ...payload, isConnected: true }
    case 'ping':
      return { ...state, ping: payload }
    case 'disconnect':
      return { ...state, isConnected: false }
    case 'updateStatus': {
      const status: GameStatus = payload

      return {
        ...state,
        ...status,
        ...(status.now && { clockDiff: status.now - Date.now() }),
        ...(status.state === GameState.Betting && { userBets: [], at: 1 }),
        ...(status.state === GameState.Ended && {
          userBets: state.userBets.map((b) => ({
            ...b,
            state: b.state === BetState.Active ? BetState.Lost : b.state
          }))
        })
      }
    }
    case 'newBet': {
      const newState = { ...state, bets: [...state.bets, payload] }

      if (payload.user.id === state.userId) {
        newState.userBets = [...state.userBets, withItems(payload, 1)]
      }

      return newState
    }
    case 'myBets':
      return {
        ...state,
        userBets: payload.map((bet: CrashBet) => withItems(bet, 1))
      }
    case 'onTick': {
      const { cashouts, at }: Tick = payload
      const newState = { ...state, at }

      if (cashouts) {
        newState.bets = state.bets.map((bet) => {
          const cashout = cashouts.find((c) => c.betId === bet.betId)

          if (!cashout) return bet

          if (bet.user.id === state.userId) {
            const idx = newState.userBets.findIndex(
              (b) => b.betId === bet.betId
            )
            newState.userBets[idx] = cashedOut(newState.userBets[idx], at)
            sounds.win()
          }

          return cashedOut(bet, cashout.at)
        })
      }

      newState.userBets = state.userBets.map((bet) =>
        withItems(bet, Math.min(at, bet.cashedOutAt ?? Infinity))
      )

      return newState
    }
    case 'historyEntry':
      return {
        ...state,
        gameHistory: [...state.gameHistory, payload].slice(-20)
      }
    case 'setUserId':
      return {
        ...state,
        userId: payload
      }
    default:
      throw new Error()
  }
}

const withItems = (bet: CrashBet | UserBet, at: Float): UserBet => ({
  ...bet,
  items: findItemsOfValue(bet.amount * at)
})

const cashedOut = <T = CrashBet | UserBet>(bet: T, at: Float): T => ({
  ...bet,
  state: BetState.Cashout,
  cashedOutAt: at
})

export const useCrash = () => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const { user, isAuthenticated } = useUser()

  useEffect(() => {
    if (isAuthenticated) dispatch({ type: 'setUserId', payload: user?.id })
  }, [user, isAuthenticated])

  useEffect(() => {
    const onInit = ({ detail }: CustomEvent<Init>) => {
      dispatch({ type: 'init', payload: detail })
    }

    const onGameStatus = ({ detail }: CustomEvent<GameStatus>) => {
      if (detail.state === GameState.Ended) sounds.crash()

      dispatch({ type: 'updateStatus', payload: detail })
    }

    const onMyBets = ({ detail }: CustomEvent<CrashBet[]>) => {
      dispatch({ type: 'myBets', payload: detail })
    }

    const onBet = ({ detail }: CustomEvent<CrashBet>) => {
      dispatch({ type: 'newBet', payload: detail })
    }

    const onTick = ({ detail }: CustomEvent<Tick>) => {
      dispatch({ type: 'onTick', payload: detail })
    }

    const onHistoryEntry = ({ detail }: CustomEvent<CrashHistory>) => {
      dispatch({ type: 'historyEntry', payload: detail })
    }

    const onPlaceBet = ({ detail }: CustomEvent<{ amount: Int }>) => {
      if (typeof detail === 'string') return notifyError(detail)

      credit({ balance: -detail.amount })
    }

    const onPingServerTime = ({ detail }: CustomEvent<Millis>) => {
      WSCrash.send(['ping:pong', detail])
    }

    const onPingPong = ({ detail }: CustomEvent<Int>) => {
      dispatch({ type: 'ping', payload: detail })
    }

    const onDisconnect = () => {
      dispatch({ type: 'disconnect', payload: undefined })
    }

    WSCrash.on('init', onInit)
    WSCrash.on('status', onGameStatus)
    WSCrash.on('myBets', onMyBets)
    WSCrash.on('bet', onBet)
    WSCrash.on('tick', onTick)
    WSCrash.on('historyEntry', onHistoryEntry)
    WSCrash.on('placeBet', onPlaceBet)
    WSCrash.on('ping:serverTime', onPingServerTime)
    WSCrash.on('ping:pong', onPingPong)
    WSCrash.on('disconnect', onDisconnect)

    WSCrash.send(['init', null])
    WSCrash.send(['status', null])
    WSCrash.send(['myBets', null])
    WSCrash.send(['ping:serverTime', null])

    const pingInterval = setInterval(
      () => WSCrash.send(['ping:serverTime', null]),
      10_000
    )

    return () => {
      WSCrash.off('init', onInit)
      WSCrash.off('status', onGameStatus)
      WSCrash.off('myBets', onMyBets)
      WSCrash.off('bet', onBet)
      WSCrash.off('tick', onTick)
      WSCrash.off('historyEntry', onHistoryEntry)
      WSCrash.off('placeBet', onPlaceBet)
      WSCrash.off('ping:serverTime', onPingServerTime)
      WSCrash.off('ping:pong', onPingPong)
      WSCrash.off('disconnect', onDisconnect)
      clearInterval(pingInterval)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id])

  const placeBet = (payload: CrashPlaceBet) => {
    if (!isAuthenticated) return API.login()

    sounds.placeBet()
    WSCrash.send(['placeBet', payload])
  }

  const cashout = (betId?: Int) => {
    if (!betId) return
    if (state.state !== GameState.InProgress) return

    WSCrash.send(['cashout', { betId }])
  }

  const cashoutAll = () => {
    if (!isAuthenticated) return API.login()

    for (const bet of state.userBets) {
      if (bet.state === BetState.Active) cashout(bet.betId)
    }
  }

  return { state, placeBet, cashout, cashoutAll }
}
