import { HandAction } from '@crashgg/types/dist'
import { useEffect } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useUser } from '../../_common/hooks/useUser'
import { credit } from '../../_common/user'
import { createBlackjack, Current, getCurrent, playHand } from './blackjack-api'
import { useFairness } from './useFairness'

const BLACKJACK = 'blackjack'

export const useBlackjack = () => {
  const { isAuthenticated } = useUser()
  const queryClient = useQueryClient()
  const fairness = useFairness()

  const current = useQuery(BLACKJACK, getCurrent, {
    enabled: isAuthenticated,
    refetchOnWindowFocus: false
  })

  const gameId = current.data?.gameId

  const onSuccess = (data: Current) => {
    queryClient.setQueryData(BLACKJACK, data)

    credit({ balance: -data.balanceDiff.bet })

    if (data.balanceDiff.win) {
      setTimeout(() => {
        credit({ balance: data.balanceDiff.win })
      }, 1000 * (data.cardsDrawn + 1))
    }

    if (data.game?.allHandsDone) {
      setTimeout(() => {
        fairness.setPrevGame({
          seed: data.clientSeed!,
          serverHash: data.serverHash!,
          serverSeed: data.serverSeed!
        })
        fairness.randomizeSeed()
        fairness.refreshServerHash()
      }, 1000 * (data.cardsDrawn + 1))
    }
  }

  const playMutation = useMutation(
    async (action: HandAction) =>
      playHand(
        gameId!,
        current.data?.game?.handId ?? 0,
        action,
        fairness.serverHash
      ),
    { onSuccess }
  )

  const createMutation = useMutation(createBlackjack, { onSuccess })

  useEffect(() => {
    console.log('gam ph ue', current.data?.game)
  }, [current.data])

  return {
    isLoading: current.isLoading,
    game: current.data?.game,
    handId: current.data?.game?.handId ?? -1,
    gameId,
    play: playMutation.mutate,
    createMutation,
    isPlayProcessing: playMutation.isLoading,
    fairness
  }
}
