import { css, keyframes } from '@emotion/react'
import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import tw from 'twin.macro'
import { CurrencyUsd } from '../_common/components/Currency'
import { ReactComponent as Cloud } from './assets/rain.svg'
import { useRain } from './lib/useRain'
import { useRainTimer } from './lib/useRainTimer'
import { RainModal } from './RainModal'

export const Rain: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false)
  const { timeLeft, hasJoined, markJoined } = useRainTimer()
  const { pot } = useRain()

  const isActive = timeLeft <= 120
  const mm = ~~(timeLeft / 60)
  const ss = String(timeLeft % 60).padStart(2, '0')

  return (
    <div
      css={[
        tw`hover:scale-[1.02] mx-2 my-3 py-3 px-4 rounded sticky top-0 z-10 flex flex-row items-center transition duration-150 cursor-pointer`,
        isActive ? tw`bg-blue-de3` : tw`bg-gray-de2`,
        css`
          background-size: 200% 200%;
          animation: ${rolling} 18s linear infinite;
          background-image: repeating-linear-gradient(
            -45deg,
            transparent,
            transparent 1rem,
            rgba(21, 23, 25, 0.08) 0,
            rgba(21, 23, 25, 0.08) 2rem
          );
        `
      ]}
      onClick={() => setIsOpen(true)}>
      <Cloud tw="fill-current h-7 ml-1 mr-5 opacity-50" />
      <div tw="flex-1">
        <div tw="font-semibold tracking-wide">
          <FormattedMessage id={isActive ? 'rain.active' : 'rain.incoming'} />
        </div>
        <div tw="text-sm opacity-90">
          <FormattedMessage id="rain.ends-in" values={{ mm, ss }} />
        </div>
      </div>
      <div tw="font-bold w-14 h-14 flex rounded-full bg-white bg-opacity-10">
        <div tw="m-auto">
          <CurrencyUsd value={pot} />
        </div>
      </div>

      <RainModal
        isOpen={isOpen}
        close={() => setIsOpen(false)}
        isActive={isActive}
        hasJoined={hasJoined}
        markJoined={markJoined}
      />
    </div>
  )
}

const rolling = keyframes`
  to { background-position:100% 100% } }
`
