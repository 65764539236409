import React from 'react'
import 'twin.macro'
import { RouteComponentProps } from '@reach/router'
import { Container, Protected } from '../_common/components'
import { Header } from './Header'
import { GoBack } from '../_common/components/GoBack'
import { Tab } from '@headlessui/react'
import { StyledTab } from '../_common/components/Tabs'
import { FormattedMessage } from 'react-intl'
import { BetHistory } from './BetHistory'
import { Dashboard } from './Dashboard'
import { useTitle } from '../_common/hooks/useTitle'

export const ProfilePage: React.FC<RouteComponentProps> = () => {
  useTitle('Profile')

  return (
    <Protected>
      <Container tw="flex flex-col gap-5">
        <GoBack />
        <Header />

        <Tab.Group>
          <StyledTab.List>
            <StyledTab>
              <FormattedMessage id="profile.account" />
            </StyledTab>
            <StyledTab>
              <FormattedMessage id="profile.history" />
            </StyledTab>
          </StyledTab.List>
          <Tab.Panels>
            <StyledTab.Panel>
              <Dashboard />
            </StyledTab.Panel>
            <StyledTab.Panel>
              <BetHistory />
            </StyledTab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </Container>
    </Protected>
  )
}
