import React from 'react'
import { FormattedMessage } from 'react-intl'
import 'twin.macro'
import { Center, LoadingScreen } from '.'
import { useUser } from '../hooks/useUser'
import { H1 } from './Text'

interface Props {
  role?: string
}

export const Protected: React.FC<Props> = ({ role, children }) => {
  const { isAuthenticated, isLoading, user } = useUser()
  const isAuthorized = isAuthenticated && (!role || user.role === role)

  if (isLoading) return <LoadingScreen />
  if (!isAuthorized) return <NotAuthorized />
  return <>{children}</>
}

const NotAuthorized: React.FC = () => {
  return (
    <Center>
      <div id="bg" />
      <H1>
        <FormattedMessage id="protected.header" />
      </H1>
      <FormattedMessage id="protected.description" />
    </Center>
  )
}
