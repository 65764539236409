import { Millis, Int } from '@crashgg/types/dist'
import { useLayoutEffect, useRef } from 'react'

export const useAnimationFrame = (
  cb: (delta: Millis, frame: Int) => void,
  deps: any
) => {
  const frame = useRef<number>(0)
  const last = useRef<number>(performance.now())

  const animate = () => {
    const now = performance.now()
    const delta = now - last.current
    cb(delta, frame.current)
    last.current = now

    frame.current = requestAnimationFrame(animate)
  }

  useLayoutEffect(() => {
    frame.current = requestAnimationFrame(animate)
    return () => {
      cancelAnimationFrame(frame.current)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)
}
