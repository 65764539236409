import { useEffect, useState } from 'react'

const SECOND = 1000

export const useCountdown = (target: Date) => {
  const [[h, m, s], setState] = useState<number[]>([0, 0, 0])

  useEffect(() => {
    const getTimeLeft = () => {
      const s = ~~((target.getTime() - Date.now()) / 1000)
      const m = ~~(s / 60)
      const h = ~~(m / 60)

      return [h, m % 60, s % 60]
    }

    let timer: NodeJS.Timeout

    const update = () => setState(getTimeLeft())
    setState(getTimeLeft())

    timer = setTimeout(() => {
      update()
      timer = setInterval(update, SECOND)
    }, SECOND - (Date.now() % SECOND))

    return () => clearInterval(timer)
  }, [target])

  return [h, m, s]
}
