import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import 'twin.macro'
// import { ReactComponent as Gift } from './assets/gift-solid.svg'
// import { ClaimAffiliateModal } from '../affiliates/ClaimAffiliateModal'
import { useUser } from '../_common/hooks/useUser'
import { PromoCodeModal } from '../affiliates/PromoCodeModal'

export const PromoCode: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false)
  const { user, isAuthenticated } = useUser()

  if (!isAuthenticated || user.referredById) return null

  return (
    <>
      <div
        tw="cursor-pointer text-gainsGreen hover:brightness-125 transition duration-150 text-[11px] font-semibold tracking-wider"
        onClick={() => setIsOpen(true)}>
        {/* <Gift tw="text-gainsGreen fill-current h-3 inline mr-1 -mt-1" /> */}
        <FormattedMessage id="nav.promo-code" />
      </div>

      <PromoCodeModal isOpen={isOpen} close={() => setIsOpen(false)} />
    </>
  )
}
