import { Int } from '@crashgg/types/dist'
import { API } from '../../_common/API'
import { sum } from '../../_common/utils'

export interface Stats {
  day: StatsPeriod
  week: StatsPeriod
  all: StatsPeriod
  days: StatsPeriod[]
}

export type Method = 'skin' | 'coinpayments' | 'skinsback' | 'giftcard'

export interface StatsPeriodRaw {
  day: string
  newUsers: Int
  wager: Int
  deposits: Record<Method, Int | undefined>
  withdrawals: Record<Method, Int | undefined>
}

export interface StatsPeriod extends StatsPeriodRaw {
  profitUsd: Int
  shopFee: Int
}

const SHOP_FEE = 0.1

export const getStats = async (): Promise<Stats> => {
  const daysRaw = await API.get('/admin/stats')
  if (!daysRaw.length) throw new Error('invalid_data')

  const days = daysRaw.map((day: StatsPeriodRaw): StatsPeriod => {
    const shopFee = (day.withdrawals?.skin || 0) * SHOP_FEE

    const profitUsd =
      sum(Object.values(day.deposits)) -
      sum(Object.values(day.withdrawals)) +
      shopFee

    return { ...day, profitUsd, shopFee }
  })

  return {
    day: days[0],
    week: accumulate(days.slice(0, 7)),
    all: accumulate(days),
    days
  }
}

const accumulate = (days: StatsPeriod[]): StatsPeriod => {
  return days.reduce(
    (acc, day) => {
      for (const category of ['deposits', 'withdrawals'] as const) {
        for (const key in day[category]) {
          const k = key as Method
          acc[category][k] = (acc[category][k] || 0) + (day[category][k] || 0)
        }
      }

      acc.newUsers += day.newUsers
      acc.wager += day.wager
      acc.profitUsd += day.profitUsd

      return acc
    },
    {
      newUsers: 0,
      deposits: {},
      withdrawals: {},
      wager: 0,
      profitUsd: 0
    } as StatsPeriod
  )
}

export interface Affiliate {
  steamId: string
  name: string
  avatar: string
  code: string
  wager: Int
  referrals: 0
  clicks: Int
}

export const getAffiliates = async (): Promise<Affiliate[]> => {
  return API.get('/affiliates/summary')
}
