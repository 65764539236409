import { Int } from '@crashgg/types/dist'
import React, { useEffect, useState } from 'react'
import { AnimatedDigit } from './AnimatedDigit'
import tw from 'twin.macro'
import { StartCountdown } from './StartCountdown'
import { Duel, DuelState } from './lib/duels-api'
import { FormattedMessage } from 'react-intl'
import { Currency } from '../_common/components/Currency'

interface Props {
  game: Duel
}

export const AnimatedDice: React.FC<Props> = ({ game }) => {
  const { outcomes, state, startsAt, winnings } = game

  const [forceIdle, setForceIdle] = useState(true)
  useEffect(() => setForceIdle(false), [outcomes])

  const value = outcomes?.length ? outcomes[outcomes.length - 1] : 0
  const isIdle = forceIdle || !outcomes?.length || state === DuelState.Finished
  const [a, b, c, d] = getDigits(value)

  return (
    <div tw="relative">
      <div tw="flex flex-row gap-2 justify-center relative" key={value}>
        <AnimatedDigit digit={a} isIdle={isIdle} delay={300} duration={6000} />
        <AnimatedDigit digit={b} isIdle={isIdle} delay={200} duration={5000} />
        <div tw="font-bold text-4xl mt-auto">.</div>
        <AnimatedDigit digit={c} isIdle={isIdle} delay={100} duration={4500} />
        <AnimatedDigit digit={d} isIdle={isIdle} />
      </div>
      <div
        tw="bg-dark-el1/80 h-36 absolute top-0 w-full z-[60] flex transition duration-300"
        css={isIdle ? tw`opacity-100` : tw`opacity-0`}>
        <div tw="m-auto tracking-wide text-white">
          {state === DuelState.Open ? (
            <FormattedMessage id="duels.waiting-for-players" />
          ) : state === DuelState.Finished ? (
            <div tw="flex flex-row items-center gap-3 text-gray-el4">
              <FormattedMessage
                id="duels.winner"
                values={{ winnings: <Currency value={winnings} size="big" /> }}
              />
            </div>
          ) : (
            <StartCountdown startsAt={startsAt} />
          )}
        </div>
      </div>
    </div>
  )
}

const getDigits = (value: Int): Int[] => {
  return String(value).padStart(4, '0').split('').map(Number)
}
