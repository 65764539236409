import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useQuery } from 'react-query'
import 'twin.macro'
import { Pagination, Table } from '../_common/components'
import { getBets } from './apis/profile'
import { BetHistoryRow } from './BetHistoryRow'

export const BetHistory: React.FC = () => {
  const [page, setPage] = useState(1)
  const history = useQuery(['profile/bets', page], () => getBets(page), {
    keepPreviousData: true
  })

  return (
    <div>
      <Table isFull>
        <thead>
          <Table.Tr>
            {[
              'common.date',
              'common.amount',
              'common.game',
              'common.profitloss'
              // 'common.action'
            ].map((key) => (
              <Table.Th key={key}>
                <FormattedMessage id={key} />
              </Table.Th>
            ))}
          </Table.Tr>
        </thead>
        <tbody>
          {history.data?.data.map((bet) => (
            <BetHistoryRow key={bet.id} bet={bet} />
          ))}
          {!history.data?.data.length && (
            <Table.Empty>
              <FormattedMessage id="profile.no-bets" />
            </Table.Empty>
          )}
        </tbody>
      </Table>

      <div tw="mt-5 float-right">
        <Pagination
          pages={history.data?.pages || 1}
          page={page}
          setPage={setPage}
        />
      </div>
    </div>
  )
}
