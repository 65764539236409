import { Int } from '@crashgg/types/dist'
import { useEffect } from 'react'
import { useQueryClient } from 'react-query'
import { sounds } from '../../_common/sounds'
import { WS } from '../../_common/WS'
import {
  Duel,
  DuelMin,
  DuelState,
  Finish,
  Join,
  Roll,
  Start
} from './duels-api'

export const DUELS = 'duels'

export const useDuelEvents = () => {
  const queryClient = useQueryClient()

  useEffect(() => {
    const _setDuel = (duel: DuelMin) => {
      queryClient.setQueryDefaults(['duel', duel.id], {
        staleTime: Infinity,
        refetchOnWindowFocus: true,
        refetchOnMount: false
      })
      queryClient.setQueryData(['duel', duel.id], duel)
    }

    const _modifyActiveDuel = (
      duelId: Int,
      modify: (old: DuelMin) => DuelMin
    ) => {
      queryClient.setQueryData(DUELS, (old: any) => {
        if (!old) return undefined

        return {
          ...old,
          active: old.active.map((duel: DuelMin) => {
            if (duel.id !== duelId) return duel

            return modify(duel)
          })
        }
      })
    }

    const _modifyDuel = (
      duelId: Int,
      modify: (old: DuelMin | Duel) => DuelMin | Duel
    ) => {
      queryClient.setQueryData(['duel', duelId], (old: any) => {
        if (!old) return undefined

        return modify(old)
      })
    }

    const onNew = ({ detail: duel }: CustomEvent<DuelMin>) => {
      queryClient.setQueryData(DUELS, (old: any) => {
        if (!old) return undefined

        return {
          ...old,
          active: [...old?.active, duel]
        }
      })
      _setDuel(duel)
    }

    const onJoin = ({
      detail: { duelId, newPlayers, winnings }
    }: CustomEvent<Join>) => {
      const modifier = (duel: DuelMin): DuelMin => ({
        ...duel,
        players: [...duel.players, ...newPlayers],
        winnings: winnings ?? duel.winnings
      })

      _modifyActiveDuel(duelId, modifier)
      _modifyDuel(duelId, modifier)
    }

    const onStart = ({ detail: { duelId, startsIn } }: CustomEvent<Start>) => {
      const modifier = (duel: DuelMin): DuelMin => ({
        ...duel,
        state: DuelState.InProgress,
        startsAt: Date.now() + startsIn
      })

      _modifyActiveDuel(duelId, modifier)
      _modifyDuel(duelId, modifier)

      if (window.location.hash === `#${duelId}`) sounds.placeBet()
    }

    const onRoll = ({ detail: { duelId, outcomes } }: CustomEvent<Roll>) => {
      const modifier = (duel: DuelMin) => ({
        ...duel,
        outcomes
      })

      _modifyActiveDuel(duelId, modifier)
      _modifyDuel(duelId, modifier)
    }

    const onFinish = ({
      detail: { duelId, winningTeam, fairness, outcomes }
    }: CustomEvent<Finish>) => {
      queryClient.setQueryData(DUELS, (old: any) => {
        if (!old) return undefined

        const duel = old.active.find((d: DuelMin) => d.id === duelId)
        if (!duel) return undefined

        return {
          ...old,
          active: old.active.filter((d: DuelMin) => d.id !== duelId),
          history: [
            {
              ...duel,
              state: DuelState.Finished,
              winningTeam,
              fairness,
              outcomes,
              updatedAt: new Date().toISOString()
            },
            ...old.history
          ].slice(0, 15)
        }
      })

      _modifyDuel(duelId, (duel: DuelMin) => ({
        ...duel,
        state: DuelState.Finished,
        winningTeam,
        fairness
      }))

      if (window.location.hash === `#${duelId}`) sounds.win()
    }

    WS.on('duels:new', onNew)
    WS.on('duels:join', onJoin)
    WS.on('duels:start', onStart)
    WS.on('duels:roll', onRoll)
    WS.on('duels:finish', onFinish)

    return () => {
      WS.off('duels:new', onNew)
      WS.off('duels:join', onJoin)
      WS.off('duels:start', onStart)
      WS.off('duels:roll', onRoll)
      WS.off('duels:finish', onFinish)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryClient])
}
