import styled from '@emotion/styled'
import { Tab } from '@headlessui/react'
import React from 'react'
import tw from 'twin.macro'

type Tabs = React.FC & { List: React.FC; Panel: React.FC }

export const StyledTab: Tabs = ({ children }) => {
  return (
    <Tab as={React.Fragment}>
      {({ selected }) => (
        <button
          css={[
            tw`px-5 py-2 bg-transparent text-gray cursor-pointer block flex-1 outline-none`,
            tw`rounded transition duration-150 hover:bg-dark-el1`,
            selected && tw`bg-dark-el1 text-white`
          ]}>
          {children}
        </button>
      )}
    </Tab>
  )
}

StyledTab.List = styled(Tab.List)(tw`flex flex-row flex-wrap items-center`)
StyledTab.Panel = styled(Tab.Panel)(tw`outline-none`)
