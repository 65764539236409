import { BjPublic, Int } from '@crashgg/types/dist'
import styled from '@emotion/styled'
import React from 'react'
import 'twin.macro'
import { Hand } from './Hand'
import tableBg from './assets/table.svg'
import { CardsDrawn } from './lib/useCardsDrawn'
import { Fairness } from './Fairness'
import { Fairness as IFairness } from './lib/useFairness'

interface Props {
  game?: BjPublic
  gameId?: Int
  drawn: CardsDrawn
  handId: Int
  fairness: IFairness
}

export const Table: React.FC<Props> = ({
  game,
  gameId,
  drawn,
  handId,
  fairness
}) => {
  return (
    <div tw="bg-[#121a53] rounded-lg h-full overflow-hidden">
      <TableBg>
        {game && (
          <div tw="flex flex-col justify-between items-center h-full">
            <Hand
              cards={game.dealerHand}
              drawn={drawn.dealer}
              reveal2Card={drawn.isDealerRevealed}
              gameId={gameId!}
              isCurrent
            />

            <div tw="flex flex-row w-full justify-center pb-[7.5%]">
              {game.playerHands.map((hand, i) => (
                <Hand
                  {...hand}
                  status={drawn.isDrawing ? undefined : hand.status}
                  key={i}
                  gameId={gameId!}
                  drawn={drawn.player[i]}
                  reveal2Card
                  isCurrent={i === handId || game.allHandsDone}
                />
              ))}
            </div>
          </div>
        )}
      </TableBg>
      <Fairness fairness={fairness} />
    </div>
  )
}

const TableBg = styled.div`
  background-image: url(${tableBg});
  aspect-ratio: 1.4 / 1;
  background-size: cover;
  --margin: 4%;
  margin: calc(var(--margin) * 0.7) var(--margin);
  width: calc(100% - 2 * var(--margin));
  padding: 5%;
  box-sizing: border-box;
`
