import React from 'react'
import 'twin.macro'
import { FormattedMessage } from 'react-intl'
import { H2 } from '../_common/components'
import { Tradelink } from '../_common/components/Tradelink'
import { LinkedProfiles } from './LinkedProfiles'
import { Volume } from './Volume'

export const Settings: React.FC = () => {
  return (
    <>
      <H2>
        <FormattedMessage id="profile.settings" />
      </H2>
      <div tw="flex flex-col gap-5">
        <Tradelink />
        <Volume />
        <LinkedProfiles />
      </div>
    </>
  )
}
