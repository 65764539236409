import React from 'react'
import { theme } from 'twin.macro'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { css, Global } from '@emotion/react'

export const Toasts: React.FC = () => {
  return (
    <>
      <Global styles={customStyles} />
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </>
  )
}

const customStyles = css`
  :root {
    --toastify-color-dark: ${theme<string>`colors.dark.el1`};
    --toastify-color-progress-dark: ${theme<string>`colors.blue.DEFAULT`};
    --toastify-color-success: ${theme<string>`colors.green.DEFAULT`};
    --toastify-color-error: ${theme<string>`colors.red.DEFAULT`};
  }
`
