import { Dialog } from '@headlessui/react'
import tw, { TwStyle } from 'twin.macro'
import React from 'react'
import { ReactComponent as Close } from '../assets/close.svg'

export interface ModalProps {
  isOpen: boolean
  close: () => void
  styles?: TwStyle
}

export const Modal: React.FC<ModalProps> = ({
  isOpen,
  close,
  styles = tw`max-w-md`,
  children
}) => {
  return (
    // @ts-expect-error: A workaround for Headless & Emotion bug
    <Dialog
      open={isOpen}
      onClose={close}
      tw="fixed z-40 inset-0 flex items-center justify-center backdrop-blur-sm">
      <Dialog.Overlay tw="fixed inset-0 bg-dark-de1 opacity-60" />

      <Dialog.Panel
        tw="bg-gainsBgSubNavbar z-50 max-w-2xl rounded-md px-4 py-3 m-2 md:m-5 relative w-full overflow-x-hidden overflow-y-auto max-h-[80vh]"
        css={styles}>
        {children}

        <button
          tw="absolute top-2 right-2 p-1 text-gray-de3 hover:text-gray-de1 transition duration-150 z-[60] outline-none"
          onClick={close}>
          <Close tw="fill-current w-5 h-5" />
        </button>
      </Dialog.Panel>
    </Dialog>
  )
}
