import { Link } from '@reach/router'
import React from 'react'
import 'twin.macro'
import logo from './assets/logo-reup.png'

export const LogoBox: React.FC = () => {
  return (
    <div tw="bg-gainsBgNavbar h-16 md:h-24 flex items-center justify-center">
      <Link to="/">
        <img src={logo} alt="REUP.GG" tw="h-8 md:h-14" />
      </Link>
    </div>
  )
}
