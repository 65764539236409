import { Int } from '@crashgg/types/dist'
import ITEMS from '../assets/items.json'
import scrap from '../assets/scrap.webp'

export type Item = [Int, string, string] // price, name, image

const scraps: Item[] = new Array(9)
  .fill(0)
  .map((_, i) => [9 - i, 'Scrap', scrap])
const items: Item[] = [...(ITEMS as Item[]), ...scraps]

export const findItemsOfValue = (total: Int, maxItems = 2) => {
  let remaining = total
  const selected = []

  for (const item of items) {
    if (item[0] <= remaining) {
      selected.push(item)
      remaining -= item[0]

      if (selected.length === maxItems) break
    }
  }

  return selected
}
