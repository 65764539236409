import React from 'react'
import tw from 'twin.macro'
import { Float, GameState, Millis } from '@crashgg/types'
import { css } from '@emotion/react'
import { shakeFull } from './lib/animations'
import styled from '@emotion/styled'

export interface Props {
  state: GameState
  at: Float
  dynamicAt: Float
  timer: Millis
}

const descriptions = {
  [GameState.InProgress]: 'Upgrading...',
  [GameState.Betting]: 'Place your bets',
  [GameState.Blocked]: 'Processing bets',
  [GameState.Ended]: 'Crashed at',
  [GameState.Paused]: 'Maintenance'
}

export const Status: React.FC<Props> = ({ state, at, dynamicAt, timer }) => {
  const highlight = {
    [GameState.InProgress]: <>x{dynamicAt.toFixed(2)}</>,
    [GameState.Betting]: <>{(timer / 1000).toFixed(1)}s</>,
    [GameState.Blocked]: <>BLOCKED</>,
    [GameState.Ended]: <>x{at.toFixed(2)}</>,
    [GameState.Paused]: <>PAUSED</>
  }[state]

  return (
    <Wrapper state={state}>
      <div
        tw="text-base uppercase tracking-wide"
        css={
          state === GameState.Ended ? tw`text-red-crash` : tw`text-gray-el2`
        }>
        {descriptions[state]}
      </div>
      {highlight}
    </Wrapper>
  )
}

interface WrapperProps {
  state: GameState
}

const Wrapper = styled.section<WrapperProps>(({ state }) => [
  tw`text-center text-4xl font-bold max-w-xs mx-auto py-5`,
  state === GameState.Ended &&
    css`
      ${tw`text-red-crash`}
      perspective: 1000px;
      animation: 150ms ${shakeFull} ease;
    `
])
