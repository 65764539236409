import { Int } from '@crashgg/types/dist'
import { API } from '../../_common/API'

export const joinRain = (token: string) => {
  return API.post('/rain/join', { token })
}

export const addToRain = async (amount: Int) => {
  return API.patch('/rain/add-to-pot', { amount })
}
