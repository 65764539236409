import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useMutation } from 'react-query'
import tw from 'twin.macro'
import { API } from '../_common/API'
import {
  ActionButton,
  AltLabel,
  BetInput,
  Option,
  Select
} from '../_common/components'
import { useUser } from '../_common/hooks/useUser'
import { ReactComponent as PlayersIcon } from './assets/user-group.svg'
import { ReactComponent as SwordsIcon } from './assets/swords.svg'
import { createDuel } from './lib/duels-api'

export const Create: React.FC = () => {
  const { isAuthenticated } = useUser()
  const [amount, setAmount] = useState('1.00')
  const [players, setPlayers] = useState(PLAYERS[0])
  const [mode, setMode] = useState(MODES[0])
  const mutation = useMutation(async () => {
    if (!isAuthenticated) return API.login()

    const amountInt = ~~(parseFloat(amount) * 100)
    return createDuel(amountInt, players.id, mode.id)
  })

  return (
    <div tw="flex flex-row flex-wrap pb-3 gap-4 items-end justify-center">
      <div>
        <AltLabel tw="text-xs">
          <FormattedMessage id="common.bet-amount" />
        </AltLabel>
        <BetInput value={amount} setAmount={setAmount} />
      </div>
      <div>
        <AltLabel tw="text-xs">
          <FormattedMessage id="common.players" />
        </AltLabel>
        <Select options={PLAYERS} selected={players} select={setPlayers} />
      </div>
      <div>
        <AltLabel tw="text-xs">
          <FormattedMessage id="common.mode" />
        </AltLabel>
        <Select options={MODES} selected={mode} select={setMode} />
      </div>
      <ActionButton
        mutation={mutation}
        payload={null}
        color={mode.id === 'lo' ? 'purple' : 'primary'}>
        <span tw="font-bold">
          <FormattedMessage id="common.place-bet" />
        </span>
      </ActionButton>
    </div>
  )
}

const PlayerSelect: React.FC = ({ children }) => (
  <div tw="flex flex-row items-center mt-[-2px]">
    <PlayersIcon tw="fill-current text-gray-el4 h-3" />
    <div tw="text-lg ml-2">{children}</div>
  </div>
)

const PLAYERS: Option[] = [
  { id: 2, render: <PlayerSelect>2</PlayerSelect> },
  { id: 3, render: <PlayerSelect>3</PlayerSelect> },
  { id: 4, render: <PlayerSelect>4</PlayerSelect> }
]

const ModeSelect: React.FC<{ color: any }> = ({ children, color }) => (
  <div tw="flex flex-row items-center">
    <SwordsIcon tw="fill-current w-4 h-4" css={color} />
    <div tw="ml-2">{children}</div>
  </div>
)

const MODES: Option[] = [
  {
    id: 'hi',
    render: <ModeSelect color={tw`text-blue`}>Normal</ModeSelect>
  },
  {
    id: 'lo',
    render: <ModeSelect color={tw`text-purple`}>Cursed</ModeSelect>
  }
]
