import React from 'react'
import tw from 'twin.macro'
import { RouteComponentProps } from '@reach/router'
import { useIntl } from 'react-intl'
import { useTitle } from '../_common/hooks/useTitle'
import { Container, H1, H3, Protected } from '../_common/components'
import styled from '@emotion/styled'
import { MethodCard } from './MethodCard'

import { ReactComponent as Rust } from './assets/rust.svg'
import { ReactComponent as CSGO } from './assets/csgo.svg'
import { ReactComponent as Bitcoin } from './assets/bitcoin.svg'
import { ReactComponent as Ethereum } from './assets/ethereum.svg'
import { ReactComponent as Litecoin } from './assets/litecoin.svg'
import { ReactComponent as Gift } from './assets/gift.svg'
import kinguinImage from './assets/kinguin.png'
import cardImage from './assets/card.png'
import dota2 from './assets/dota2.png'

export const DepositPage: React.FC<RouteComponentProps> = () => {
  useTitle('Deposit')
  const { formatMessage } = useIntl()

  return (
    <Protected>
      <div id="bg" />
      <Container sm tw="pt-5 md:pt-10 pb-20">
        <H1>{formatMessage({ id: 'deposit.title' })}</H1>
        <H3>{formatMessage({ id: 'deposit.items' })}</H3>
        <Cards>
          <MethodCard
            to="/deposit/rust"
            name="Rust Skins"
            image={<Rust />}
            lead={formatMessage({ id: 'deposit.instant' })}
          />
          <MethodCard
            to="/deposit/skinsback"
            name="CS:GO Skins"
            image={<CSGO tw="w-18 h-18" />}
            lead={formatMessage({ id: 'deposit.bonus' }, { bonus: 0.4 })}
          />
          <MethodCard
            to="/deposit/skinsback"
            name="Dota 2 Skins"
            image={<img src={dota2} alt="Dota 2" tw="h-16" />}
            lead={formatMessage({ id: 'deposit.bonus' }, { bonus: 0.4 })}
          />
        </Cards>

        <H3>{formatMessage({ id: 'deposit.fiat' })}</H3>
        <Cards>
          <MethodCard
            to="/deposit/giftcards"
            name="Kinguin"
            image={<img src={kinguinImage} alt="Kinguin" tw="h-16" />}
            lead={formatMessage({ id: 'deposit.bonus' }, { bonus: 0.4 })}
          />
          <MethodCard
            to="/deposit/giftcards"
            name="Credit Card"
            image={<img src={cardImage} alt="Card" tw="h-18" />}
            lead={formatMessage({ id: 'deposit.bonus' }, { bonus: 0.4 })}
          />
        </Cards>

        <H3>{formatMessage({ id: 'deposit.crypto' })}</H3>
        <Cards>
          <MethodCard
            to="/deposit/crypto/btc"
            name="Bitcoin"
            image={<Bitcoin tw="h-16" />}
            lead={formatMessage({ id: 'deposit.bonus' }, { bonus: 0.4 })}
          />
          <MethodCard
            to="/deposit/crypto/eth"
            name="Ethereum"
            image={<Ethereum tw="h-16" />}
            lead={formatMessage({ id: 'deposit.bonus' }, { bonus: 0.4 })}
          />
          <MethodCard
            to="/deposit/crypto/ltc"
            name="Litecoin"
            image={<Litecoin tw="h-16" />}
            lead={formatMessage({ id: 'deposit.bonus' }, { bonus: 0.4 })}
          />
        </Cards>

        <H3>{formatMessage({ id: 'deposit.bonuses' })}</H3>
        <Cards>
          <MethodCard
            to="/deposit/promocodes"
            name="Promo Code"
            image={<Gift tw="h-14 fill-current text-blue" />}
            lead={formatMessage({ id: 'deposit.free' })}
          />
        </Cards>
      </Container>
    </Protected>
  )
}

const Cards = styled.div(tw`flex flex-row flex-wrap gap-5 mt-3 mb-5`)
