import { Float } from '@crashgg/types/dist'
import { useEffect, useState } from 'react'
import { API } from '../../_common/API'
import { WS } from '../../_common/WS'

export const useRain = () => {
  const [pot, setPot] = useState(0)

  useEffect(() => {
    API.get('/rain').then(({ pot }) => setPot(Math.round(pot)))

    const update = ({ detail }: CustomEvent<{ pot: Float }>) => {
      setPot(Math.round(detail.pot))
    }

    WS.on('rain', update)
    return () => WS.off('rain', update)
  }, [])

  return { pot }
}
