import React from 'react'
import 'twin.macro'
import { AOut, H2, P } from '../../_common/components'

export const Blackjack: React.FC = () => {
  return (
    <section>
      <H2 id="blackjack">Blackjack</H2>

      <P tw="mt-3">
        Our blackjack game generates random cards from an infinite deck by
        combining two inputs - Server Seed and Client Seed. The Server Seed is
        generated before game starts, and its' hash is shown to the user. The
        Client Seed is generated in your browser and can be changed to anything
        you wish.
      </P>

      <P tw="mt-3">
        To verify a game visit our
        <AOut
          href="https://playcode.io/986120"
          target="_blank"
          rel="noopener noreferrer">
          Verifier Script
        </AOut>
        , then fill the first 3 lines with your game details. The program will
        output the shuffled deck that was used in the game.
      </P>
    </section>
  )
}
