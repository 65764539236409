import React, { ComponentProps } from 'react'
import styled from '@emotion/styled'
import { FormattedMessage } from 'react-intl'
import tw from 'twin.macro'
import { navigate } from '@reach/router'
import { ReactComponent as ArrowLeft } from '../assets/arrow-left.svg'

export interface GoBackProps extends ComponentProps<'div'> {
  action?: () => void
}

export const GoBack: React.FC<GoBackProps> = ({ action, ...props }) => {
  const onClick = action || (() => navigate(-1))

  return (
    <Wrapper onClick={onClick} {...props}>
      <ArrowLeft tw="fill-current h-5" />
      <FormattedMessage id="common.go-back" />
    </Wrapper>
  )
}

const Wrapper = styled.div(
  tw`cursor-pointer flex flex-row gap-2 items-center font-medium text-gray-de2 hover:text-gray transition duration-150`
)
