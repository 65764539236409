import React from 'react'
import { Int } from '@crashgg/types/dist'
import { FormattedMessage, FormattedNumberParts } from 'react-intl'
import tw from 'twin.macro'
import { ReactComponent as Coin } from '../_common/assets/currency-coin.svg'
import { Currency } from '../_common/components/Currency'

export const PreviousValue = ({ data }: any) => {
  return (
    <div>
      <div tw="text-lg font-medium">
        <FormattedMessage id="lottery.prize-pool" />
      </div>
      <PreviousPool value={data.totalPot} />
      <div tw="text-lg font-medium flex flex-row items-center">
        <Currency
          value={data.totalWinnings}
          size="default"
          styles={tw`text-lg font-medium`}
        />
        <div tw="ml-2 text-gray-el4">paid out</div>
      </div>
    </div>
  )
}

export const PreviousPool: React.FC<{ value: Int }> = ({ value }) => {
  return (
    <div tw="flex flex-row">
      <Coin tw="h-12 mr-2" />
      <FormattedNumberParts
        value={value / 100}
        // eslint-disable-next-line react/style-prop-object
        style="currency"
        currency="USD">
        {(parts) => (
          <>
            {parts.slice(1).map((part, idx) => (
              <span css={getPartStyles(part.type)} key={idx}>
                {part.value}
              </span>
            ))}
          </>
        )}
      </FormattedNumberParts>
    </div>
  )
}

const getPartStyles = (type: string) => {
  if (['integer', 'group'].includes(type))
    return tw`text-5xl font-bold text-[#F8E555]`

  return tw`text-white text-3xl mt-auto`
}
