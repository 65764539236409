import React from 'react'
import 'twin.macro'
import { toast } from 'react-toastify'
import { RouteComponentProps } from '@reach/router'
import { FormattedMessage } from 'react-intl'
import { ItemPage } from './ItemPage'
import { useMutation, useQuery } from 'react-query'
import { getInventory, deposit } from './apis/steam'
import { useUser } from '../_common/hooks/useUser'
import { useTitle } from '../_common/hooks/useTitle'
import { Protected } from '../_common/components'
import { sounds } from '../_common/sounds'
import { DepositTabs } from './DepositTabs'

export const DepositItemsPage: React.FC<RouteComponentProps> = () => {
  useTitle('Deposit items')
  const { isAuthenticated } = useUser()
  const inventory = useQuery('user-inventory', getInventory, {
    enabled: isAuthenticated,
    retry: false,
    refetchOnWindowFocus: false
  })

  const mutation = useMutation(deposit, {
    onSuccess: () => {
      sounds.notification()
      toast.success('Creating tradeoffer...')
    }
  })

  return (
    <Protected>
      <ItemPage
        title={<FormattedMessage id="deposit.items.title" />}
        actionMutation={mutation}
        idKey="externalId"
        action={<FormattedMessage id="deposit.items.action" />}
        actionDesc={<FormattedMessage id="deposit.items.action-desc" />}
        itemsQuery={inventory}
        emptyMessage={<FormattedMessage id="deposit.items.empty" />}
        topData={<DepositTabs />}
        maxItems={100}
      />
    </Protected>
  )
}
