import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { UseQueryResult } from 'react-query'
import 'twin.macro'
import { ActionButton, Container, H1 } from '../_common/components'
import { Currency } from '../_common/components/Currency'
import { RefreshQuery } from '../_common/components/RefreshQuery'
import { Tradelink } from '../_common/components/Tradelink'
import { useUser } from '../_common/hooks/useUser'
import { SteamItemAny } from '../_common/types'
import { sumKey } from '../_common/utils'
import { ItemCompact } from './ItemCompact'
import { ItemList } from './ItemList'
import { Filter } from './Filter'
import { Order, useItemFilter } from './useItemFilter'
import { useSelect } from './useSelect'
import { WagerReq } from './apis/steam'

export interface Props {
  title: React.ReactNode
  itemsQuery: UseQueryResult<SteamItemAny[], any>
  idKey?: any
  emptyMessage?: React.ReactNode
  action: React.ReactNode
  actionDesc: React.ReactNode
  actionMutation: any
  topData?: React.ReactNode
  maxItems: number
  wagerReq?: WagerReq
}

export const ItemPage: React.FC<Props> = ({
  title,
  itemsQuery,
  idKey = 'id',
  emptyMessage,
  action,
  actionDesc,
  actionMutation,
  topData,
  maxItems,
  wagerReq
}) => {
  const { isAuthenticated, user } = useUser()
  const requireTradelink = isAuthenticated && !user.tradeToken

  const items = itemsQuery.data || []
  const { isSelected, selectedItems, toggleItem } = useSelect({
    items,
    idKey,
    maxItems
  })

  const [search, setSearch] = useState('')
  const [order, setOrder] = useState<Order>('price-desc')
  const { filteredItems } = useItemFilter({ items, search, order })

  const selectedValue = sumKey(selectedItems, 'price')
  const actionDisabled = !selectedItems.length

  return (
    <div tw="h-full flex flex-col lg:flex-row">
      <div tw="flex-1 overflow-hidden lg:(h-full pb-5)">
        {topData}
        <Container tw="mt-2 flex flex-row flex-wrap justify-between items-center">
          <H1>{title}</H1>
          <div tw="flex flex-row flex-wrap gap-2">
            <Filter
              search={search}
              setSearch={setSearch}
              order={order}
              setOrder={setOrder}
            />
            <RefreshQuery query={itemsQuery} tw="pl-2 pr-0" />
          </div>
        </Container>
        {requireTradelink ? (
          <SetTradelink />
        ) : (
          <ItemList
            items={filteredItems}
            idKey={idKey}
            isSelected={isSelected}
            toggleItem={toggleItem}
            isLoading={itemsQuery.isLoading}
            emptyMessage={emptyMessage}
          />
        )}
      </div>

      <div tw="bg-gainsBgChat p-3 flex flex-col w-screen lg:(w-72 p-5) overflow-hidden">
        <div tw="text-gray-el2 font-medium pb-3 lg:pb-5 overflow-auto">
          {selectedItems.length}/{maxItems}{' '}
          <FormattedMessage id="item-page.selected-count" />
        </div>

        <div tw="flex-1 flex flex-row lg:flex-col gap-3 min-w-0 overflow-x-auto">
          {selectedItems.length ? (
            selectedItems.map((item) => {
              const id = (item as any)[idKey]

              return (
                <ItemCompact
                  key={id}
                  {...item}
                  isSelected
                  onSelect={() => toggleItem(id)}
                />
              )
            })
          ) : (
            <div tw="mx-auto my-4 lg:my-10 text-gray">
              <FormattedMessage id="item-page.not-selected" />
            </div>
          )}
        </div>

        <div>
          <div tw="text-gray-el2 my-3 lg:my-5">
            {actionDesc}
            <div tw="float-right">
              <Currency value={selectedValue} />
            </div>
          </div>

          {wagerReq?.remaining ? (
            <>
              <div tw="text-red text-sm text-center">
                In order to withdraw, you have to wager 100% of your deposit and
                rewards.
              </div>
              <div tw="text-gray-el2 text-center mt-3">
                <Currency size="xs" value={wagerReq.remaining} /> remaining
              </div>
            </>
          ) : (
            <ActionButton
              color="primary"
              full
              disabled={actionDisabled}
              mutation={actionMutation}
              payload={selectedItems}>
              {action}
            </ActionButton>
          )}
        </div>
      </div>
    </div>
  )
}

const SetTradelink: React.FC = () => {
  return (
    <div tw="mx-auto mt-5 lg:mt-10 max-w-sm">
      <Tradelink isRequired />
    </div>
  )
}
