import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { forwardRef } from 'react'
import tw from 'twin.macro'

export interface Props
  extends StyledProps,
    React.InputHTMLAttributes<HTMLInputElement> {
  label?: React.ReactFragment
}

export const Input = forwardRef<HTMLInputElement, Props>(
  ({ label, ...props }, ref) => {
    if (!label) return <StyledInput ref={ref} {...props} />

    return (
      <div>
        {label && <label tw="block font-semibold mb-1">{label}</label>}
        <StyledInput ref={ref} {...props} />
      </div>
    )
  }
)

export const CopyInput = forwardRef<HTMLInputElement, Props>((props, ref) => (
  <Input ref={ref} readOnly onFocus={(e) => e.target.select()} {...props} />
))

export const inputVariants = {
  darker: tw`bg-gainsBgChat`,
  default: tw`bg-gainsBgChat`
}

export interface StyledProps {
  full?: boolean
  variant?: keyof typeof inputVariants
}

const StyledInput = styled.input((props: StyledProps) => [
  tw`px-5 py-3 rounded-md outline-none placeholder-gray-de2`,
  props.full && tw`w-full`,
  inputVariants[props.variant || 'default']
])

export const Range = styled.input(css`
  -webkit-appearance: none;
  appearance: none;
  ${tw`w-full h-1 bg-dark-el4`}

  ::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    ${tw`w-4 h-4 rounded-full bg-white cursor-pointer outline-none`}
  }

  ::-moz-range-thumb {
    ${tw`w-4 h-4 rounded-full bg-white cursor-pointer outline-none`}
  }
`)

Range.defaultProps = { type: 'range' }
