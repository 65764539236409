import { RouteComponentProps } from '@reach/router'
import React from 'react'
import 'twin.macro'
import { Container, H3, P } from '../_common/components'
import { useTitle } from '../_common/hooks/useTitle'

export const TOSPage: React.FC<RouteComponentProps> = () => {
  useTitle('Terms of Service')

  return (
    <Container page tw="space-y-3">
      <div id="bg" />

      <H3>Terms of Service</H3>
      <P tw="pb-5">
        Hi there, thanks for actually reading this. Nobody likes long boring
        legal jargon so let's make this as short and as simple as it could
        possibly be.
      </P>
      <H3 tw="text-gainsBgSubNavbarText">1. Who can play</H3>
      <P>
        No individual under the age of eighteen (18) may use REUP.GG, regardless
        of any consent from a parent or guardian. You are responsible for
        ensuring that online gambling is legal in your area of jurisdiction.
      </P>
      <H3 tw="text-gainsBgSubNavbarText">2. Account Bans</H3>
      <P>
        REUP.GG holds the authority to ban or freeze accounts. Users funds are
        forfeited in the event of major exploitation of bugs or "freebie"
        services that we offer. If Crash believes that you do not meet the
        requirements of play (Clause 1). The user is notified 24 hours before an
        account freeze to withdraw any remaining funds on the subsequent
        account. Any individual caught breaking previous and subsequent Terms
        would result in relevant action on their accounts.
      </P>
      <H3 tw="text-gainsBgSubNavbarText">3. Account Security</H3>
      <P>
        You are responsible for ensuring that your account is fully secure.
        REUP.GG is not liable for any losses due to compromised accounts.
        REUP.GG accounts are not intended to be shared with other users, any
        damages or loss are your responsibility.
      </P>
      <H3 tw="text-gainsBgSubNavbarText">4. Returns and Refunds</H3>
      <P>
        REUP.GG and other services operated by GambaVerse LLC do not offer
        refunds or returns on digital products once the digital product has been
        used. If you have made a purchase and have not used the digital item
        such as an activation key you may be elegible for a refund.
      </P>
      <H3 tw="text-gainsBgSubNavbarText">5. Multiple Accounts</H3>
      <P>
        The use of multiple accounts on REUP.GG and other services operated by
        GambaVerse LLC is strictly prohibited. If multiple accounts linked to
        one individual are found, all previous and subsequent accounts will be
        permananently banned (Section 2).
      </P>
      <H3 tw="text-gainsBgSubNavbarText">6. System Abuse</H3>
      <P>
        If caught abusing Rewards, Rain, Lottery, Faucets, Freebies, or any
        cashback system that REUP.GG provides all previous and subsequent
        accounts will be permananently banned (Section 2). Claiming such
        freebies without the intention of playing will result in a permanent ban
        from all REUP.GG products.
      </P>
      <P>
        Abuse of REUP.GG systems, including but not limited to the use of
        exploits, bugs, or actions that give you unfair advantage, is strictly
        prohibited. Abuse may result in an immediate account ban. Any coins
        earned by abuse will be forfeit. If you find an exploit, you must
        contact REUP.GG immediately.
      </P>
      <H3 tw="text-gainsBgSubNavbarText">7. Betting</H3>
      <P>
        All bets are final. No refunds will be issued in the event of a "false
        bet". You as the customer understand the risks of gambling and therefore
        should play responsibly.
      </P>
      <H3 tw="text-gainsBgSubNavbarText">8. REUP.GG Coins Pricing</H3>
      <P>
        REUP.GG Coins do not represent any real life currency or crypto currency
        and have no liquid value. REUP.GG is for entertainment purposes only and
        does not present the opportunity to win real life value items.
      </P>
      <H3 tw="text-gainsBgSubNavbarText">9. Affiliation</H3>
      <P>
        REUP.GG and other services operated by GambaVerse LLC are not affiliated
        with or endorsed by the FacePunch, Rust, Steam or any other trademarks
        of the Valve corporation.
      </P>
      <H3 tw="text-gainsBgSubNavbarText">10. Governing Law</H3>
      <P>
        All inquiries relating to the construction, validity, enforcement and
        interpretation of these Terms shall be governed by and construed and
        enforced in accordance with the laws of the State of Delaware.
      </P>
      <P>
        In the event of any dispute arising between GambaVerse LLC and you, the
        parties will make best efforts to solve such dispute in good faith
        within a reasonable time. In case that you and GambaVerse LLC will not
        reach an amicable solution within a reasonable period (of no less than
        30 days as of the commencement of the amicable dispute resolution
        process), you or GambaVerse LLC may seek legal resolution with the
        competent courts situated in Delaware, and such courts shall have sole
        and exclusive jurisdiction to resolve such dispute.
      </P>
    </Container>
  )
}
