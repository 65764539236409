import React from 'react'
import tw from 'twin.macro'
import { Currency } from '../_common/components/Currency'
import { SteamItem } from '../_common/types'
import { getItemImage } from '../_common/utils'

export interface Props extends SteamItem {
  isSelected?: boolean
  onSelect: () => void
}

export const Item: React.FC<Props> = ({
  name,
  price,
  imageUrl,
  isAccepted = true,
  isSelected,
  onSelect
}) => {
  const onClick = () => isAccepted && onSelect && onSelect()

  return (
    <div
      css={[
        tw`h-40 w-full px-2 py-5 bg-gainsBgChat flex flex-col items-center rounded-md transition duration-150`,
        isSelected && tw`bg-gainsBgSubNavbarHover`,
        isAccepted ? tw`cursor-pointer` : tw`cursor-not-allowed opacity-50`
      ]}
      onClick={onClick}>
      <img
        src={getItemImage(imageUrl)}
        alt=""
        tw="w-18"
        css={`
          filter: drop-shadow(#70abff22 0px 0px 20px);
        `}
      />

      <div tw="text-gainsBgSubNavbarText py-2 text-center text-sm truncate max-w-full">
        {name}
      </div>

      <span tw="font-semibold">
        <Currency value={price || 0} size="small" />
      </span>
    </div>
  )
}
