import { Int } from '@crashgg/types/dist'
import { API } from '../../_common/API'
import { PublicUser } from '../../_common/types'
import { notifyError } from '../../_common/utils'

export interface Lottery {
  id: number
  status: string
  entryPrice: Int
  winningNumbers?: string
  fairness?: Int
  totalPot?: Int
  totalWinnings?: Int
  endsAt: Date
  createdAt: Date
  updatedAt: Date
}

export interface LastBuy {
  user: PublicUser
  count: number
  createdAt: string
}

export interface Current {
  lottery: Lottery
  lastBuys: LastBuy[]
  pot: number
  totalTickets: number
  ticketsPerCoin: number
}

export interface Winner {
  user: PublicUser
  userId: Int
  coinsWon: Int
  maxNumbers: Int
  ticketsWon: Int
}

export interface PrevLottery extends Required<Lottery> {
  winners: Winner[]
}

export const getCurrent = async (): Promise<Current> => {
  const data = await API.get('/lottery')
  data.lottery.endsAt = new Date(data.lottery.endsAt)

  return data
}

export const getMyTickets = async (lotteryId: Int): Promise<Int> => {
  const data = await API.get(`/lottery/${lotteryId}/my-tickets`)

  return data.tickets
}

const generateNumbers = (count: Int) => {
  const numbers: Record<string, true> = {}
  let len = 0

  while (len < count) {
    const n = String(~~(Math.random() * 1e6)).padStart(6, '0')
    if (!numbers[n]) {
      numbers[n] = true
      len += 1
    }
  }

  return Object.keys(numbers)
}

export const buyLotteryTickets = async (count: Int) => {
  const numbers = generateNumbers(count)

  await API.post('/lottery/enter', { numbers })
}

export const buyTickets = async (amountCoins: Int) => {
  if (amountCoins > 2500_00) {
    notifyError('You can exchange up to 2500.00 coins at a time')
    throw new Error()
  }

  return API.post('/lottery/buy-tickets', { amountCoins })
}

export const claimLotteryWinnings = async () => {
  return API.post('/lottery/claim-winnings')
}

export const getLottery = async (id: Int): Promise<PrevLottery> => {
  return API.get(`/lottery/${id}`)
}
