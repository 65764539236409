import styled from '@emotion/styled'
import tw from 'twin.macro'

export interface ButtonProps {
  size?: keyof typeof sizes
  color?: keyof typeof colors
  full?: boolean
  disabled?: boolean
}

const sizes = {
  small: tw`px-3 py-2 font-light text-xs tracking-wider`,
  medium: tw`px-6 py-3 font-normal tracking-wider`,
  big: tw`px-8 py-4 font-medium text-lg tracking-wider`
}

const transparentColor = tw`text-gray bg-transparent hover:(bg-transparent text-gray-el2)`

const colors = {
  primary: tw`bg-blue hover:bg-blue-de1 text-white`,
  purple: tw`bg-purple hover:bg-purple-de1 text-white`,
  default: tw`bg-gray-de2 hover:bg-gray-de3 text-white`,
  gold: [
    'background: linear-gradient(30deg, #F0C838 16.73%, #FAED5D 103.58%)',
    tw`text-dark-de1 hover:opacity-90`
  ],
  transparent: transparentColor,
  'transparent-ring': [
    transparentColor,
    tw`ring-1 ring-white ring-opacity-30 hover:ring-opacity-50`
  ],
  'gains-green': [
    `background: #0B2744; color: #3CF3B1;`,
    tw`hover:brightness-125`
  ],
  'gains-gold': [
    'background: #242339; color: #F6C358;',
    tw`hover:brightness-125`
  ]
}

export const Button = styled.button((props: ButtonProps) => [
  `text-shadow: 0 4px 8px rgba(0, 0, 0, 0.12);
  white-space: nowrap;
  font-weight: 600;
  
  &::before {
    content: ' ';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 50%;
    background: #fff;
    opacity: 0.08;
  }`,
  tw`border-none font-bold duration-150 relative overflow-hidden rounded-tr-lg rounded-bl-lg select-none uppercase tracking-wider`,
  sizes[props.size || 'medium'],
  colors[props.color || 'default'],
  props.full && tw`w-full`,
  props.disabled ? tw`cursor-not-allowed` : tw`cursor-pointer`
])
