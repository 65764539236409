import { Tab } from '@headlessui/react'
import { RouteComponentProps } from '@reach/router'
import React from 'react'
import 'twin.macro'
import { StyledTab } from '../_common/components/Tabs'
import { Protected } from '../_common/components'
import { Dashboard } from './Dashboard'
import { useTitle } from '../_common/hooks/useTitle'
import { Affiliates } from './Affiliates'
import { Lookup } from './Lookup'
import { Codes } from './Codes'
import { Miscellaneous } from './Miscellaneous'

export const AdminPage: React.FC<RouteComponentProps> = () => {
  useTitle('Admin')

  return (
    <Protected role="admin">
      <Tab.Group>
        <StyledTab.List tw="mx-5">
          <StyledTab>Dashboard</StyledTab>
          <StyledTab>Lookup</StyledTab>
          <StyledTab>Affiliates</StyledTab>
          <StyledTab>Codes</StyledTab>
          <StyledTab>Miscellaneous</StyledTab>
        </StyledTab.List>
        <Tab.Panels tw="m-5">
          <StyledTab.Panel>
            <Dashboard />
          </StyledTab.Panel>
          <StyledTab.Panel>
            <Lookup />
          </StyledTab.Panel>
          <StyledTab.Panel>
            <Affiliates />
          </StyledTab.Panel>
          <StyledTab.Panel>
            <Codes />
          </StyledTab.Panel>
          <StyledTab.Panel>
            <Miscellaneous />
          </StyledTab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </Protected>
  )
}
