import React from 'react'
import { CrashHistory } from '@crashgg/types/dist'
import tw from 'twin.macro'
import { H2, Modal, CopyInput, P } from '../_common/components'
import { FormattedMessage } from 'react-intl'

export type ModalState =
  | { isOpen: false; game: null }
  | { isOpen: boolean; game: CrashHistory }

type Props = ModalState & { close: () => void; clientSeed: string }

export const HistoryModal: React.FC<Props> = ({
  isOpen,
  close,
  game,
  clientSeed
}) => {
  return (
    <Modal isOpen={isOpen} close={close} styles={tw`px-5 py-5 max-w-sm`}>
      <H2>
        <FormattedMessage id="crash.history-title" values={{ id: game?.id }} />
      </H2>
      <P tw="mb-10">
        <FormattedMessage
          id="crash.history-desc"
          values={{ at: <b tw="text-white">x{game?.crashedAt.toFixed(2)}</b> }}
        />
      </P>
      <CopyInput
        label={<FormattedMessage id="crash.game-seed" />}
        value={game?.seed}
        full
        variant="darker"
        tw="mb-2"
      />
      <CopyInput
        label={<FormattedMessage id="crash.client-seed" />}
        value={clientSeed}
        full
        variant="darker"
      />
    </Modal>
  )
}
