import React, { useMemo } from 'react'
import tw from 'twin.macro' // , { theme }
import { Int, UserSummary } from '@crashgg/common'
import styled from '@emotion/styled'
import { Avatar } from '../_common/components'
import { Level } from '../_common/components/Level'
import { RoleName } from './Role'
import { ReactComponent as BanIcon } from './assets/ban.svg'
import { removeMessage } from './lib/chat-api'
import { parseEmotes } from './lib/emotes'

export interface IMessage {
  id: Int
  room: string
  message: string
  createdAt: string
  user: UserSummary
  removedById?: string
}

export const Message: React.FC<IMessage & { isMod: boolean }> = ({
  id,
  message,
  user,
  removedById,
  isMod
}) => {
  const withEmotes = useMemo(() => parseEmotes(message), [message])

  if (removedById) return null

  return (
    <div tw="py-3 flex flex-row">
      <Avatar src={user.avatar} />

      <div tw="pl-4 flex-1 overflow-hidden">
        <div tw="text-gray text-sm font-normal flex flex-row items-center gap-2">
          <Level role={user.role} wager={user.totalWagered} />
          <RoleName
            role={user.role}
            name={user.name}
            steamId={user.steamId}
            isVerified={user.isVerified}
          />
          {isMod && (
            <BanIcon
              tw="fill-current text-gray-de2 hover:text-gray cursor-pointer h-3 ml-auto mt-1"
              onClick={() => removeMessage(id)}
            />
          )}
        </div>

        <Bubble dangerouslySetInnerHTML={{ __html: withEmotes }} />
      </div>
    </div>
  )
}

// const Bubble = styled.div`
//   ${tw`text-gainsBgSubNavbarText bg-gainsBgChat p-2 mt-2 relative text-sm break-words`}

//   &:before {
//     ${tw`absolute top-1 -left-4`}
//     content: '';
//     border: solid 0.5rem transparent;
//     border-right-color: ${theme<string>`colors.dark.el1`};
//     z-index: 1;
//   }
// `

const Bubble = styled.div`
  ${tw`text-gainsBgSubNavbarText bg-gainsBgChat p-4 py-3 mt-2 relative text-sm break-words font-semibold rounded-tr-lg rounded-bl-lg`}
`
