import React, { forwardRef } from 'react'
import 'twin.macro'
import { InputButton, InputWrapper } from './BetInput'
import { Input, inputVariants, Props as InputProps } from './Input'
import { ReactComponent as Refresh } from '../assets/refresh.svg'

interface Props extends InputProps {
  setSeed: (v: string) => void
}

export const SeedInput = forwardRef<HTMLInputElement, Props>(
  ({ value, setSeed, variant, disabled, ...props }, ref) => {
    return (
      <InputWrapper css={inputVariants[variant || 'default']}>
        <Input
          ref={ref}
          tw="w-[5.5rem] mx-0 p-2 text-lg text-gray-el4"
          value={value}
          onChange={(e) => !disabled && setSeed(e.target.value)}
          variant={variant}
          {...props}
        />
        <InputButton
          onClick={() => !disabled && setSeed(randomSeed())}
          className="group">
          <Refresh tw="block h-3 fill-current m-auto transition duration-150 group-hover:rotate-45" />
        </InputButton>
      </InputWrapper>
    )
  }
)

export const randomSeed = () => Math.random().toString(36).substring(2, 10)
