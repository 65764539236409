import { Int } from '@crashgg/types/dist'
import React, { useEffect, useRef } from 'react'
import { theme } from 'twin.macro'
import { fade, Fade } from '../_common/components'

interface Props {
  digit: Int
  delay?: Int
  duration?: Int
  isIdle?: boolean
}

export const AnimatedDigit: React.FC<Props> = ({
  digit,
  delay,
  duration = 4000,
  isIdle
}) => {
  const ref = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const spins = 3

    // must be different for the callback to fire
    if (ref.current) {
      ref.current.style.transitionDuration = '0ms'
      ref.current.style.transitionDelay = '0ms'
      ref.current.style.marginTop = `-${DIGIT_SIZE / 2}px`
    }

    setTimeout(() => {
      if (!ref.current) return

      if (!isIdle) {
        ref.current.style.transitionDuration = `${duration}ms`
        ref.current.style.transitionDelay = `${delay || 0}ms`
      }
      ref.current.style.marginTop = `${
        -DIGIT_SIZE * (0.5 + digit + spins * 10)
      }px`
    }, 200)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [digit])

  return (
    <div tw="w-[22%] bg-dark-el3 rounded h-36 overflow-hidden text-center text-5xl select-none">
      <Fade
        tw="h-full overflow-hidden"
        fades={[
          fade('medium', 'bottom', theme`colors.dark.el3`),
          fade('medium', 'top', theme`colors.dark.el3`)
        ]}>
        <div ref={ref} />
        {DIGITS.map((digit, i) => (
          <div tw="py-2" key={i}>
            {digit}
          </div>
        ))}
      </Fade>
    </div>
  )
}

const DIGIT_SIZE = 64
const TEN = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
const DIGITS = [9, ...TEN, ...TEN, ...TEN, ...TEN, 0]
