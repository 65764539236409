import { RouteComponentProps } from '@reach/router'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import Skeleton from 'react-loading-skeleton'
import { useQuery } from 'react-query'
import 'twin.macro'
import {
  AOut,
  Container,
  CopyInput,
  H1,
  P,
  Protected
} from '../_common/components'
import { CurrencyUsd } from '../_common/components/Currency'
import { useTitle } from '../_common/hooks/useTitle'
import { useUser } from '../_common/hooks/useUser'
import { getCryptoAddress, getCryptoRates } from './apis/deposit'
import { CryptoSelector } from './CryptoSelector'
import { DepositTabs } from './DepositTabs'

interface Props extends RouteComponentProps {
  code: string
  name: string
}

export const CryptoPage: React.FC<Props> = ({ code, name }) => {
  useTitle(`Deposit ${code}`)
  const { isAuthenticated } = useUser()
  const { data: address } = useQuery(
    ['crypto-address', code],
    async () => getCryptoAddress(code),
    { enabled: isAuthenticated, retry: false }
  )
  const rates = useQuery('crypto-rates', getCryptoRates, {
    enabled: isAuthenticated,
    retry: false
  })

  const confirms = rates.data?.[code]?.confirms || 1
  const price = rates.data?.[code]?.priceUsd || 0

  const explorer = (
    <AOut
      href={`https://blockchair.com/${name.toLowerCase()}/address/${address}`}
      target="_blank"
      rel="noopener noreferrer">
      <FormattedMessage id="deposit.crypto.explorer" />
    </AOut>
  )

  return (
    <Protected>
      <div id="bg" />
      <DepositTabs />
      <CryptoSelector selected={code} />
      <Container page sm>
        <span tw="text-green font-bold tracking-wide">+40% bonus</span>
        <H1>
          <FormattedMessage id="deposit.crypto.title" values={{ name }} />
        </H1>
        <P>
          <FormattedMessage
            id="deposit.crypto.desc"
            values={{ name, confirms, explorer }}
          />
        </P>

        <div tw="flex flex-col md:(grid grid-cols-3) gap-5 mt-10">
          <div tw="w-full max-w-xs mx-auto rounded overflow-hidden col-span-1">
            {address ? (
              <img
                src={`https://chart.googleapis.com/chart?chs=175x175&cht=qr&chl=${address}`}
                alt=""
                tw="w-full block"
              />
            ) : (
              <Skeleton width="100%" height="205px" />
            )}
          </div>
          <div tw="col-span-2 flex flex-col gap-5 my-auto">
            <CopyInput value={address || 'Loading...'} tw="bg-dark-el3" />
            <P tw="text-center md:text-left">
              1 {code} ≈ <CurrencyUsd value={price * 100} />
            </P>
          </div>
        </div>
      </Container>
    </Protected>
  )
}
