import { Int } from '@crashgg/types/dist'
import { API } from '../../_common/API'
import { Paginated } from '../../_common/types'

export interface Bet {
  id: Int
  betAmount: Int
  winningAmount: Int
  game: string
  data: Record<any, any>
  createdAt: Date
  crashGameId?: Int
}

export const getBets = async (page: Int = 1): Promise<Paginated<Bet[]>> => {
  return API.get(`/user/me/bets?page=${page}`)
}

export const updateTradelink = async (tradelink: string) => {
  const tradeToken = new URL(tradelink).searchParams.get('token')
  if (!tradeToken || tradeToken.length !== 8) throw new Error('invalid_token')

  return API.patch(`/user/me`, { tradeToken })
}
