import React from 'react'
import { FormattedMessage } from 'react-intl'
import tw from 'twin.macro'
import { Avatar } from '../_common/components'
import { Currency } from '../_common/components/Currency'
import { Stat, StatLabel } from './Common'
import { Duel } from './lib/duels-api'
import botAvatar from '../_common/assets/avatar-bot.png'
import { getColor } from './lib/utils'

export const Game: React.FC<Duel> = (game) => {
  const progress = game.players.length / game.playerCount

  return (
    <div
      onClick={() => (window.location.hash = String(game.id))}
      css={[
        tw`bg-dark-el2/40 rounded rounded-b-sm overflow-hidden transition duration-200 cursor-pointer hover:scale-[1.03]`,
        game.state === 'finished' && tw`opacity-60`
      ]}>
      <div tw="px-3 py-3 h-28 flex flex-row">
        <Avatars {...game} />
        <div tw="flex flex-col">
          <div tw="font-bold">
            <FormattedMessage id={`duels.game-title.${game.mode}`} />
          </div>
          <div tw="text-xs text-gray-el4 tracking-wide">
            {game.playerCount} players &middot;{' '}
            <FormattedMessage id={`duels.state.${game.state}`} />
          </div>
          <div tw="mt-3 flex flex-row">
            <Stat>
              <StatLabel>Bet</StatLabel>
              <Currency
                value={game.betAmount}
                size="small"
                styles={tw`font-bold`}
              />
            </Stat>
            <Stat>
              <StatLabel>Win</StatLabel>
              <Currency
                value={game.winnings}
                size="small"
                styles={tw`font-bold`}
              />
            </Stat>
          </div>
        </div>
      </div>

      <div
        tw="h-[3px] bg-blue/40"
        css={[
          game.state === 'finished'
            ? tw`bg-gray/40`
            : game.mode === 'lo'
            ? tw`bg-purple/40`
            : tw`bg-blue/40`
        ]}>
        <div
          tw="h-full transition-all duration-200"
          css={[getColor(game, 'bg')]}
          style={{ width: `${progress * 100}%` }}
        />
      </div>
    </div>
  )
}

export const Avatars: React.FC<Duel> = (game) => {
  const { players, playerCount, winningTeam } = game

  return (
    <div tw="w-24 flex">
      <div tw="my-auto w-[4.75rem] flex flex-row flex-wrap justify-center gap-1">
        {[...new Array(playerCount)].map((_, i) => {
          const player = players.find((p) => p.team === i + 1)

          return (
            <Avatar
              size="small+"
              key={i}
              src={player?.botId ? botAvatar : player?.user?.avatar}
              css={[
                tw`border-2`,
                winningTeam && player?.team === winningTeam
                  ? getColor(game, 'border')
                  : tw`border-dark-el4`
              ]}
            />
          )
        })}
      </div>
    </div>
  )
}
