import { css } from '@emotion/react'
import styled from '@emotion/styled'
import tw from 'twin.macro'

export interface FadeProps {
  fades?: string[]
}

const sizes = {
  medium: '3rem',
  big: '8rem',
  huge: '20rem'
}

const directions = {
  top: 'to bottom',
  bottom: 'to top',
  left: 'to right',
  right: 'to left'
}

export const fade = (
  size: keyof typeof sizes,
  direction: keyof typeof directions,
  color: string
) => {
  return `linear-gradient(
    ${directions[direction]},
    ${color},
    transparent ${sizes[size]}
  )`
}

export const Fade = styled.div(({ fades }: FadeProps) => [
  tw`relative pointer-events-none`,
  css`
    &:after {
      content: '';
      ${tw`absolute left-0 right-0 top-0 bottom-0`}
      background-image: ${fades?.join()};
    }

    & > * {
      ${tw`pointer-events-auto`}
    }
  `
])
