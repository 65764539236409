import { Int } from '@crashgg/types/dist'
import { toast as toastify } from 'react-toastify'
import { enUS } from '../translations/en-US'
import { ACCEPTED_TAGS_UPPER } from './constants'
import { sounds } from './sounds'

export const debounce = (func: any, timeout = 300) => {
  let timer: NodeJS.Timeout

  return (...args: any) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      func.apply(this, args)
    }, timeout)
  }
}

export const noop = () => {
  //
}

export const sleep = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export const getItemImage = (hash: string) => {
  return `https://community.akamai.steamstatic.com/economy/image/${hash}/330x192`
}

export const sumKey = <T>(arr: T[], sumKey: keyof T) => {
  return arr.reduce(
    (acc, item) => acc + ((item[sumKey] as unknown as number) || 0),
    0
  )
}

export const sum = (arr: any[]): number => {
  return arr.reduce((acc, val) => {
    if (typeof val !== 'number') return acc

    return acc + val
  }, 0)
}

export const lvlFromWager = (totalWager: Int) => ~~Math.sqrt(totalWager / 400)
export const wagerForLvl = (level: Int) => level ** 2 * 400

export const nameHasTag = (name?: string) => {
  if (!name) return false
  return ACCEPTED_TAGS_UPPER.some((tag) => name.toUpperCase().includes(tag))
}

export const isTabFocused = () => document.visibilityState === 'visible'

export const openPopup = (url: string) => {
  // if (!isTabFocused()) return

  const popupWindow = window.open(url, '_blank', 'width=1024, height=768')
  if (popupWindow?.focus) popupWindow.focus()
}

export const prettyError = (errorCode: string) => {
  if (Array.isArray(errorCode)) errorCode = errorCode[0]

  if (!errorCode || !errorCode.length) return 'Unexpected error'

  const translationKey = `error.${errorCode}`

  if (enUS[translationKey]) return enUS[translationKey]

  const error = errorCode.replaceAll('_', ' ')
  return error[0].toUpperCase() + error.slice(1)
}

export const notifyError = (errorCode: string) => {
  const message = prettyError(errorCode)

  toastify.error(message)
  sounds.error()
}

export const getRandomOrgUrl = (fairness?: any) => {
  if (!fairness?.random) return null

  const random = btoa(JSON.stringify(fairness.random))
  const signature = encodeURIComponent(fairness.signature)

  return `https://api.random.org/signatures/form?format=json&random=${random}&signature=${signature}`
}

export const escapeHtml = (str: string) => {
  return str
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#039;')
}

export const depositConversion = () => {
  console.log('depositConversion')
  // eslint-disable-next-line @typescript-eslint/no-extra-semi
  // ;(window as any).gtag('event', 'conversion', {
  //   send_to: 'AW-11054659065/2_mbCOiIwoYYEPnropcp',
  //   value: 1.0,
  //   currency: 'USD',
  //   transaction_id: ''
  // })
}
