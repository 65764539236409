import React from 'react'
import 'twin.macro'
import { AOut, H2, P } from '../../_common/components'

export const Duels: React.FC = () => {
  return (
    <section>
      <H2 id="duels">Dice Duels</H2>
      <P>
        Dice rolls are truly random and generated by random.org, a service that
        generates random data from atmospheric noise. You can learn more about
        their service{' '}
        <AOut
          href="https://www.random.org/"
          target="_blank"
          rel="noopener noreferrer">
          here
        </AOut>
        .
      </P>

      <P tw="mt-3">
        We request random data right when the game is about to start. Random.org
        provides us 2, 3, or 4, non-repeating integers between 0 and 9999. These
        numbers are divided by 100 and used as dice roll results. When the game
        finishes, we show two values - "Random" and "Random.org Signature".
        These can be used to verify game fairness on the random.org page.
      </P>

      <P tw="mt-3">
        To verify a game simply click the "Verify now" button. If you prefer to
        input the data yourself, copy both "Random" and "Random.org Signature"
        and paste them in the{' '}
        <AOut
          href="https://api.random.org/signatures/form"
          target="_blank"
          rel="noopener noreferrer">
          Signature Verification Form
        </AOut>
        , then click "Verify signature". Check if the rolls match and are in the
        correct order. To make sure you're viewing the correct result, verify
        game ID (under "User Data") and the time when results were generated.
      </P>
    </section>
  )
}
