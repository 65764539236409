import React from 'react'
import { FormattedRelativeTime } from 'react-intl'
import 'twin.macro'

interface Props {
  date: Date
}

export const RelativeTime: React.FC<Props> = ({ date }) => {
  return (
    <FormattedRelativeTime
      value={(date.getTime() - Date.now()) / 1000}
      numeric="auto"
      unit="second"
      // eslint-disable-next-line react/style-prop-object
      style="narrow"
      updateIntervalInSeconds={5}
    />
  )
}
