import { Int } from '@crashgg/types/dist'
import { useEffect, useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { DuelMin, getDuels } from './duels-api'

export const DUELS = 'duels'

interface Selected {
  isOpen: boolean
  gameId?: Int
}

export const useDuels = () => {
  const queryClient = useQueryClient()

  const duels = useQuery(DUELS, getDuels, {
    staleTime: Infinity,
    cacheTime: Infinity,
    refetchOnWindowFocus: true,
    refetchOnMount: true,
    refetchOnReconnect: true,
    onSuccess: ({ active }) => {
      for (const duel of active) _setDuel(duel)
      for (const duel of history) _setDuel(duel)
    }
  })

  useEffect(() => {
    if (!duels.data && !duels.isFetching) {
      queryClient.refetchQueries(DUELS)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [duels.data, duels.isFetching])

  const _setDuel = (duel: DuelMin) => {
    queryClient.setQueryDefaults(['duel', duel.id], {
      staleTime: Infinity,
      refetchOnWindowFocus: true,
      refetchOnMount: false
    })
    queryClient.setQueryData(['duel', duel.id], duel)
  }

  const active = (duels.data?.active || []).sort(
    (a, b) => b.winnings - a.winnings
  )
  const history = (duels.data?.history || []).sort(
    (a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
  )

  const [selected, setSelected] = useState<Selected>({ isOpen: false })

  useEffect(() => {
    const onChange = () => {
      const hash = window.location.hash.slice(1)
      const gameId = parseInt(hash, 10)

      if (hash.length && gameId) {
        setSelected({ isOpen: true, gameId })
      } else {
        setSelected({ isOpen: false })
      }
    }

    onChange()
    window.addEventListener('hashchange', onChange)
    return () => window.removeEventListener('hashchange', onChange)
  }, [])

  const closeSelected = () => {
    window.history.replaceState(null, document.title, ' ')
    setSelected({ isOpen: false })
  }

  return {
    isLoading: duels.isLoading,
    active,
    history,
    selected,
    closeSelected
  }
}
