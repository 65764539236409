import 'twin.macro'
import { escapeHtml } from '../../_common/utils'
import cat from '../assets/emotes/cat.gif'
import cry from '../assets/emotes/cry.png'
import cutepepe from '../assets/emotes/cutepepe.png'
import ez from '../assets/emotes/ez.png'
import hmm from '../assets/emotes/hmm.gif'
import kekw from '../assets/emotes/kekw.png'
import rain from '../assets/emotes/rain.gif'
import yey from '../assets/emotes/yey.png'
import catstare from '../assets/emotes/catstare.png'
import car from '../assets/emotes/car.gif'
import troll from '../assets/emotes/troll.webp'
import awesome from '../assets/emotes/awesome.png'
import dead from '../assets/emotes/dead.gif'
import keyboard from '../assets/emotes/keyboard.gif'
import peace from '../assets/emotes/peace.gif'
import mike from '../assets/emotes/mike.png'

export const EMOTES: Record<string, string> = {
  cat,
  cry,
  cutepepe,
  ez,
  hmm,
  kekw,
  rain,
  yey,
  catstare,
  car,
  troll,
  awesome,
  dead,
  keyboard,
  peace,
  mike
}

export const parseEmotes = (message: string) => {
  message = escapeHtml(message)

  for (const key in EMOTES) {
    const html = `<img src="${EMOTES[key]}" alt="${key}" title=":${key}:" width="20" height="20" style="display: inline; margin: 0 2px;" />`
    message = message.replaceAll(`:${key}:`, html)
  }

  return message
}
