import React from 'react'
import 'twin.macro'
import { RouteComponentProps } from '@reach/router'
import { FormattedMessage } from 'react-intl'
import { ItemPage } from './ItemPage'
import { useMutation, useQuery } from 'react-query'
import { getShop, getWagerReq, withdraw } from './apis/steam'
import { useTitle } from '../_common/hooks/useTitle'
import { Protected } from '../_common/components'
import { toast } from 'react-toastify'
import { credit } from '../_common/user'

export const WithdrawPage: React.FC<RouteComponentProps> = () => {
  useTitle('Withdraw')

  const mutation = useMutation(withdraw, {
    onSuccess: (offer) => {
      toast.success('Creating tradeoffer...')
      credit({ balance: offer.balanceChange })
    }
  })
  const shop = useQuery('shop', getShop)
  const wagerReq = useQuery('user/wagerReq', getWagerReq)

  return (
    <Protected>
      <ItemPage
        title={<FormattedMessage id="withdraw.title" />}
        actionMutation={mutation}
        action={<FormattedMessage id="withdraw.action" />}
        actionDesc={<FormattedMessage id="withdraw.action-desc" />}
        itemsQuery={shop}
        idKey="id"
        emptyMessage={<FormattedMessage id="withdraw.items.empty" />}
        topData={<div tw="mt-5"></div>}
        maxItems={20}
        wagerReq={wagerReq?.data}
      />
    </Protected>
  )
}
