import { Int } from '@crashgg/types/dist'
import { useEffect } from 'react'
import { useQueryClient } from 'react-query'
import { API } from '../_common/API'
import { credit } from '../_common/user'
import { WS, WSCrash } from '../_common/WS'
import { toast } from 'react-toastify'
import { useIntl } from 'react-intl'
import { notifyError, openPopup } from '../_common/utils'
import { Finish } from '../duels/lib/duels-api'
import { useUser } from '../_common/hooks/useUser'
import { useDuelEvents } from '../duels/lib/useDuelEvents'
import { sounds } from '../_common/sounds'

const useEvents = () => {
  const queryClient = useQueryClient()
  const { formatMessage } = useIntl()
  const { user } = useUser()

  useEffect(() => {
    const onPaymentDeposit = ({ detail }: CustomEvent<{ amount: Int }>) => {
      _onDeposit(detail.amount)
    }

    const onRainReward = ({ detail }: CustomEvent<{ reward: Int }>) => {
      toast.success(
        formatMessage({ id: 'rain.reward' }, { reward: detail.reward })
      )
      sounds.notification()
      credit({ balance: detail.reward })
    }

    const onOfferUpdate = ({
      detail: { status, externalId, balanceChange, type, error, securityToken }
    }: CustomEvent<{
      status: string
      externalId: string
      balanceChange: Int
      type: string
      error?: string
      securityToken?: string
    }>) => {
      switch (status) {
        case 'active':
          toast.success(`Trade sent! Token: ${securityToken}`)
          openPopup(`https://steamcommunity.com/tradeoffer/${externalId}`)
          sounds.notification()
          break

        case 'accepted':
          if (type === 'deposit') _onDeposit(balanceChange)
          break

        case 'failed':
        case 'canceled':
        case 'anomaly':
          if (type === 'withdraw') {
            credit({
              balance: -balanceChange,
              totalWithdrawals: balanceChange
            })
          }

          notifyError(error || 'trade_failed')
          break
      }
    }

    const onForceRefresh = async () => {
      await API._fetchAccessToken(true)
      queryClient.invalidateQueries('user')
    }

    const onDuelFinish = ({ detail }: CustomEvent<Finish>) => {
      if (detail.winningPlayer.userId === user?.id) {
        queryClient.invalidateQueries('user')
      }
    }

    const onCrashOutcome = ({ detail }: CustomEvent<any>) => {
      credit({
        balance: detail.balanceIncrement,
        tickets: detail.ticketIncrement,
        totalWagered: detail.wagerIncrement
      })

      setTimeout(() => queryClient.invalidateQueries('user'), 1000)
    }

    const _onDeposit = (amount: Int) => {
      toast.success(formatMessage({ id: 'deposit.success' }, { amount }))
      credit({ balance: amount, totalDeposits: amount })
      sounds.notification()
    }

    WS.on('payment:deposit', onPaymentDeposit)
    WS.on('rain:reward', onRainReward)
    WS.on('offer:update', onOfferUpdate)
    WS.on('auth:force-refresh', onForceRefresh)
    WS.on('duels:finish', onDuelFinish)
    WSCrash.on('betOutcome', onCrashOutcome)

    return () => {
      WS.off('payment:deposit', onPaymentDeposit)
      WS.off('rain:reward', onRainReward)
      WS.off('offer:update', onOfferUpdate)
      WS.off('auth:force-refresh', onForceRefresh)
      WS.off('duels:finish', onDuelFinish)
      WSCrash.off('betOutcome', onCrashOutcome)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id])
}

export const Events: React.FC = () => {
  useEvents()
  useDuelEvents()

  return null
}
