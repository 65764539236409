import { Int } from '@crashgg/types/dist'
import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import tw from 'twin.macro'
import {
  ActionButton,
  H2,
  H3,
  Input,
  InputWrapper,
  Modal,
  ModalProps,
  P
} from '../_common/components'
import { Tickets, Currency } from '../_common/components/Currency'
import { ReactComponent as TicketIcon } from '../_common/assets/ticket.svg'
import { ReactComponent as CoinIcon } from '../_common/assets/currency-coin.svg'
import { useMutation, useQueryClient } from 'react-query'
import { buyLotteryTickets, buyTickets } from './lib/lottery-api'
import { toast } from 'react-toastify'
import { refreshUser } from '../_common/user'
import { CURRENT } from './lib/useLottery'
import { useUser } from '../_common/hooks/useUser'

interface BuyTicketsProps {
  entryPrice: Int
}

interface BuyRegularProps {
  ticketsPerCoin: Int
}

export interface Props extends ModalProps, BuyTicketsProps, BuyRegularProps {}

export const BuyTicketsModal: React.FC<Props> = ({
  isOpen,
  close,
  entryPrice,
  ticketsPerCoin
}) => {
  const { user } = useUser()

  return (
    <Modal
      isOpen={isOpen}
      close={close}
      styles={tw`px-5 py-5 max-w-lg lg:ml-80`}>
      <H2 tw="text-xl">
        <FormattedMessage id="lottery.buy-tickets.title" />
      </H2>
      <P tw="text-sm">
        You can exchange{' '}
        <Tickets value={entryPrice} size="small" styles={tw`mx-1`} /> for{' '}
        <Tickets value={1} isLottery size="small" styles={tw`ml-1`} />. Numbers
        will be randomly chosen by your browser. You can buy up to 10,000
        lottery tickets at once.
      </P>
      <BuyLottery entryPrice={entryPrice} />
      <P tw="text-sm mt-2">
        Your tickets:
        <Tickets value={user.tickets} size="small" styles={tw`ml-2`} />
      </P>

      <H3 tw="text-base mt-5">Need regular tickets?</H3>
      <P tw="text-sm">
        50% of your purchase value will be added to the current pool.
      </P>
      <BuyRegular ticketsPerCoin={ticketsPerCoin} />
      <P tw="text-sm mt-2">
        Your balance:
        <Currency value={user.balance} size="small" styles={tw`ml-2`} />
      </P>
    </Modal>
  )
}

const BuyLottery: React.FC<BuyTicketsProps> = ({ entryPrice }) => {
  const [amount, setAmount] = useState<string>('100')
  const toBuy = Math.min(~~(~~amount / entryPrice), 10000)
  const queryClient = useQueryClient()

  const mutation = useMutation(buyLotteryTickets, {
    onSuccess: (_, amount) => {
      toast.success(`Joined the lottery with ${amount} tickets!`)
      queryClient.refetchQueries(['lottery-my-tickets'])
      queryClient.refetchQueries([CURRENT])

      refreshUser()
    }
  })

  return (
    <section tw="mt-5 flex flex-row items-center">
      <InputWrapper tw="bg-dark">
        <TicketIcon tw="w-5 h-5 my-auto ml-2 -rotate-45 fill-current text-red" />

        <Input
          type="number"
          min={0}
          step={entryPrice}
          tw="w-28 mx-0 p-2 text-lg bg-dark"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
        />
      </InputWrapper>
      <div tw="pb-2 flex-1">
        <span tw="text-2xl text-gray-el4 mx-3">=</span>
        <Tickets value={toBuy} size="big" isLottery styles={tw`gap-1`} />
      </div>
      <ActionButton mutation={mutation} payload={toBuy} tw="w-32">
        Exchange
      </ActionButton>
    </section>
  )
}

const BuyRegular: React.FC<BuyRegularProps> = ({ ticketsPerCoin }) => {
  const [amount, setAmount] = useState<string>('1.00')
  const ticket = Math.round(parseFloat(amount) * ticketsPerCoin)
  const balance = ~~(parseFloat(amount) * 100)

  const mutation = useMutation(buyTickets, {
    onSuccess: () => {
      toast.success(`Successfully bought tickets!`)
      refreshUser()
    }
  })

  return (
    <section tw="mt-5 flex flex-row items-center">
      <InputWrapper tw="bg-dark">
        <CoinIcon tw="w-5 h-5 my-auto ml-2" />

        <Input
          type="number"
          min={0}
          step={0.01}
          tw="w-28 mx-0 p-2 text-lg bg-dark"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
        />
      </InputWrapper>
      <div tw="pb-2 flex-1">
        <span tw="text-2xl text-gray-el4 mx-3">=</span>
        <Tickets value={ticket} size="big" styles={tw`gap-1`} />
      </div>
      <ActionButton mutation={mutation} payload={balance} tw="w-32">
        Exchange
      </ActionButton>
    </section>
  )
}
