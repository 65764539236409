import React from 'react'
import { FormattedMessage } from 'react-intl'
import 'twin.macro'
import { Stat, StatLabel, StatValue } from '../_common/components'
import { Currency } from '../_common/components/Currency'
import { useUser } from '../_common/hooks/useUser'
import { LevelProgress } from './LevelProgress'
import { Settings } from './Settings'

export const Dashboard: React.FC = () => {
  const { user } = useUser()

  return (
    <div tw="flex flex-col gap-5 mb-32">
      <div tw="grid sm:grid-cols-2 md:grid-cols-4 gap-5">
        <Stat>
          <StatValue>
            <Currency value={user.totalDeposits} size="big" />
          </StatValue>
          <StatLabel>
            <FormattedMessage id="profile.total-deposits" />
          </StatLabel>
        </Stat>
        <Stat>
          <StatValue>
            <Currency value={user.totalWithdrawals} size="big" />
          </StatValue>
          <StatLabel>
            <FormattedMessage id="profile.total-withdrawals" />
          </StatLabel>
        </Stat>
        <Stat>
          <StatValue>
            <Currency value={user.totalWagered} size="big" />
          </StatValue>
          <StatLabel>
            <FormattedMessage id="profile.total-wagered" />
          </StatLabel>
        </Stat>
        <Stat>
          <StatValue>
            <Currency
              value={user.balance + user.totalWithdrawals - user.totalDeposits}
              size="big"
              comparison
            />
          </StatValue>
          <StatLabel>
            <FormattedMessage id="common.profitloss" />
          </StatLabel>
        </Stat>
      </div>
      <LevelProgress />
      <Settings />
    </div>
  )
}
