import { Int } from '@crashgg/types/dist'
import React from 'react'
import tw from 'twin.macro'

interface Props {
  numbers: string
}

export const Numbers: React.FC<Props> = ({ numbers }) => {
  return (
    <div tw="flex flex-row gap-2">
      {numbers.split('').map((number, idx) => (
        <Number number={number} key={idx} />
      ))}
    </div>
  )
}

export const Number: React.FC<{ number: Int | string }> = ({ number }) => {
  return (
    <div
      tw="w-10 h-10 rounded-full border-2 text-xl font-bold flex"
      css={COLORS[number]}>
      <div tw="m-auto">{number}</div>
    </div>
  )
}

const COLORS: Record<string, any> = {
  0: tw`border-[#888]`,
  1: tw`border-[#474787]`,
  2: tw`border-[#F0C838]`,
  3: tw`border-[#28E373]`,
  4: tw`border-[#65A1FB]`,
  5: tw`border-[#A24AF5]`,
  6: tw`border-[#fd79a8]`,
  7: tw`border-[#1abc9c]`,
  8: tw`border-[#E28A05]`,
  9: tw`border-[#EE5F32]`
}
