import { useEffect } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { WS } from '../../_common/WS'
import { getHistory } from './blackjack-api'

export const LIVE_GAMES = 'blackjack-live-games'

export const useLiveGames = () => {
  const games = useQuery(LIVE_GAMES, async () => getHistory(), {
    staleTime: Infinity
  })

  const queryClient = useQueryClient()

  useEffect(() => {
    const onNewGame = (ev: CustomEvent) => {
      queryClient.setQueryData(LIVE_GAMES, (old: any) => {
        return [ev.detail, ...old]
          .filter(
            (game, i, arr) => arr.findIndex((g) => g.id === game.id) === i
          )
          .slice(0, 10)
      })
    }

    WS.on('blackjack:new', onNewGame)

    return () => {
      WS.off('blackjack:new', onNewGame)
    }
  }, [queryClient])

  return {
    isLoading: games.isLoading,
    games: games.data || []
  }
}
