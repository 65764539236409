import React from 'react'
import 'twin.macro'
import { Link } from '@reach/router'
import { Currency, Tickets } from '../_common/components/Currency'
import { useUser } from '../_common/hooks/useUser'
import { ReactComponent as Plus } from './assets/plus.svg'
import { depositConversion } from '../_common/utils'

interface Props {
  withTopUp?: boolean
  tickets?: boolean
}

export const Balance: React.FC<Props> = ({ withTopUp, tickets }) => {
  const { user, isAuthenticated } = useUser()

  if (!isAuthenticated) return null

  return (
    <div tw="flex flex-row gap-2 items-center rounded-tr-lg rounded-bl-lg overflow-hidden">
      <div
        style={{
          background:
            'linear-gradient(270deg, #4F484F 0%, rgba(79, 72, 79, 0) 100%)'
        }}
        tw="inline-flex">
        <div tw="px-4 rounded-tr-lg rounded-bl-lg border border-gainsGoldBorder h-[46px] flex items-center justify-center">
          {tickets ? (
            <Tickets value={user?.tickets ?? 0} />
          ) : (
            <Currency value={user?.balance ?? 0} />
          )}
        </div>
      </div>
      {withTopUp && (
        <Link
          to="/deposit"
          onClick={depositConversion}
          tw="bg-blue w-9 h-9 rounded-lg flex items-center justify-center">
          <Plus tw="w-5 h-5 text-white fill-current" />
        </Link>
      )}
    </div>
  )
}
