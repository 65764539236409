import { CrashHistory, Float } from '@crashgg/types/dist'
import React, { useState, memo } from 'react'
import tw from 'twin.macro' // { theme }
import { Container, fade, Fade } from '../_common/components'
import { HistoryModal, ModalState } from './HistoryModal'

interface Props {
  history: CrashHistory[]
  clientSeed: string
}

export const History: React.FC<Props> = memo(({ history, clientSeed }) => {
  const orderedHistory = history.slice().reverse()
  const [modal, setModal] = useState<ModalState>({
    isOpen: false,
    game: null
  })

  return (
    <Container tw="max-w-[100vw] xl:max-w-5xl overflow-hidden h-10">
      <Fade
        fades={[fade('huge', 'right', `#060C33`)]}
        tw="overflow-hidden flex flex-row gap-3">
        {orderedHistory.map((game) => (
          <div
            tw="px-3 py-2 cursor-pointer hover:bg-dark-el2 rounded-md transition duration-150"
            css={getColor(game.crashedAt)}
            key={game.id}
            onClick={() => setModal({ isOpen: true, game })}>
            x{game.crashedAt.toFixed(2)}
          </div>
        ))}
      </Fade>

      <HistoryModal
        {...modal}
        clientSeed={clientSeed}
        close={() => setModal({ isOpen: false, game: null })}
      />
    </Container>
  )
})

const getColor = (crashedAt: Float) => {
  if (crashedAt >= 20) return tw`text-gold`
  if (crashedAt >= 5) return tw`text-blue-el2`
  if (crashedAt >= 2) return tw`text-green`
  if (crashedAt >= 1.3) return tw`text-[#fa6b6b]`
  return tw`text-red`
}
