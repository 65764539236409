import { RouteComponentProps } from '@reach/router'
import React from 'react'
import 'twin.macro'
import { Container, LoadingScreen } from '../_common/components'
import { useTitle } from '../_common/hooks/useTitle'
import { Controls } from './Controls'
import { useBlackjack } from './lib/useBlackjack'
import { useCardsDrawn } from './lib/useCardsDrawn'
import { LiveGames } from './LiveGames'
import { Table } from './Table'

export const BlackjackPage: React.FC<RouteComponentProps> = () => {
  useTitle('Blackjack')

  const {
    isLoading,
    game,
    play,
    createMutation,
    gameId,
    handId,
    fairness,
    isPlayProcessing
  } = useBlackjack()
  const drawn = useCardsDrawn(game, gameId, isLoading)

  if (isLoading) return <LoadingScreen />

  return (
    <Container
      page
      tw="xl:pt-6 max-w-[100rem] flex flex-col pb-10 lg:(grid grid-cols-12 pb-5) gap-5">
      <div tw="order-2 flex flex-col gap-5 lg:(order-none lg:col-span-3) h-full overflow-hidden">
        <Controls
          game={game}
          play={play}
          createMutation={createMutation}
          isPlayProcessing={isPlayProcessing}
          isDrawing={!!drawn.isDrawing}
          fairness={fairness}
        />

        <LiveGames />
      </div>
      <div tw="lg:col-span-9">
        <Table
          game={game}
          gameId={gameId}
          drawn={drawn}
          handId={handId}
          fairness={fairness}
        />
      </div>
    </Container>
  )
}
