import { Int } from '@crashgg/types/dist'
import React from 'react'
import { FormattedNumber } from 'react-intl'
import { useQuery } from 'react-query'
import 'twin.macro'
import { H1, LoadingScreen } from '../_common/components'
import { getStats } from './apis/stats'
import { Breakdown } from './Breakdown'
import { DurationStats } from './DurationStats'

export const Dashboard: React.FC = () => {
  const { data } = useQuery('admin-stats', getStats)

  if (!data) {
    return <LoadingScreen />
  }

  return (
    <div tw="flex flex-col gap-5">
      <AreWeProfitableYet profit={data.all.profitUsd} />
      <DurationStats stats={data.day} duration="Today" />
      <DurationStats stats={data.week} duration="This week" />
      <Breakdown days={data.days} />
    </div>
  )
}

const GOAL = 50_000_00

const AreWeProfitableYet: React.FC<{ profit: Int }> = ({ profit }) => {
  const profitSummary = (
    <>
      <FormattedNumber value={Math.floor(profit / 100)} />/
      <FormattedNumber value={GOAL / 100} />
    </>
  )

  return (
    <H1 tw="mb-0">
      Are we profitable yet?{' '}
      {profit >= GOAL ? (
        <span tw="text-green">Hell yeah! {profitSummary}</span>
      ) : (
        <span tw="text-red">No {profitSummary}</span>
      )}
    </H1>
  )
}
