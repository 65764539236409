import { RouteComponentProps } from '@reach/router'
import React from 'react'
import 'twin.macro'
import { Container, H1, LoadingScreen } from '../_common/components'
import { ReactComponent as Swords } from '../_common/assets/swords.svg'
import { useTitle } from '../_common/hooks/useTitle'
import { FormattedMessage } from 'react-intl'
import { GamesList } from './GamesList'
import { useDuels } from './lib/useDuels'
import { DuelModal } from './DuelModal'
import { Create } from './Create'

export const DuelsPage: React.FC<RouteComponentProps> = () => {
  useTitle('Dice Duels')

  const { isLoading, active, history, selected, closeSelected } = useDuels()

  if (isLoading) return <LoadingScreen />

  return (
    <Container md page tw="md:pt-5">
      <div id="bg" />

      <DuelModal {...selected} close={closeSelected} />

      <div tw="flex flex-row flex-wrap xs:justify-between border-b-2 border-dark-el2 mb-2 gap-3">
        <H1 tw="flex flex-row gap-2 my-auto">
          <Swords tw="fill-current text-blue h-10" />
          <FormattedMessage id="duels.header" />
        </H1>
        <Create />
      </div>

      <div tw="flex flex-col gap-10">
        <GamesList
          title={<FormattedMessage id="duels.active-games" />}
          games={active}
        />
        <GamesList
          title={<FormattedMessage id="duels.history-games" />}
          games={history}
        />
      </div>
    </Container>
  )
}
