import { Int } from '@crashgg/types/dist'
import { API } from '../../_common/API'

export const createSkinsbackDeposit = async (): Promise<string> => {
  const { paymentUrl } = await API.post('/payments/skinsback')

  return paymentUrl
}

export const getCryptoAddress = async (code: string): Promise<string> => {
  const { address } = await API.get(`/payments/crypto/${code}/address`)

  return address
}

export interface Rate {
  code: string
  name: string
  txFee: number
  status: string
  explorerUrl: string
  confirms: number
  priceUsd: number
}

export type Rates = Record<string, Rate>

export const getCryptoRates = async (): Promise<Rates> => {
  return API.get('/payments/crypto/rates')
}

export interface CodeClaimed {
  amount: Int
  newBalance: Int
}

export const usePromoCode = async ({
  code,
  captchaToken
}: any): Promise<CodeClaimed> => {
  if (!code.length) throw new Error('Invalid code')

  return API.put(
    `/payments/promocodes/${code.toUpperCase()}/claim`,
    { captchaToken },
    { skipError: true }
  )
}

export const useGiftcard = async ({
  code
}: {
  code: string
}): Promise<CodeClaimed> => {
  if (!code.length) throw new Error('Invalid code')

  return API.put(`/payments/giftcards/${code}/claim`, {}, { skipError: true })
}
