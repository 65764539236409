import React, { useLayoutEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import tw from 'twin.macro'
import { Avatar, H1 } from '../_common/components'
import { Duel } from './lib/duels-api'
import { ReactComponent as Swords } from './assets/swords-shadow.svg'
import { getColor, getPlayerData, getRoll } from './lib/utils'
import confetti from 'canvas-confetti'
import { Float } from '@crashgg/types/dist'

interface Props {
  game: Duel
}

export const Hero: React.FC<Props> = ({ game }) => {
  useLayoutEffect(() => {
    if (game.state !== 'finished') return

    const nameDiv = document.getElementById('duel-winner-name')
    if (!nameDiv) return
    const offset = nameDiv.getBoundingClientRect()

    const centerX = offset.left + nameDiv.offsetWidth / 2
    const centerY = offset.top + nameDiv.offsetHeight / 2
    const x = centerX / window.innerWidth
    const y = centerY / window.innerHeight + 0.3

    fireConfetti(x, y)
  }, [game.state])

  if (game.state === 'finished' && game.winningTeam) {
    const winner = getPlayerData(
      game.players.find((p) => p.team === game.winningTeam)!,
      game.id
    )
    const roll = getRoll(game.outcomes[game.winningTeam - 1])

    return (
      <div tw="py-12 flex flex-col items-center">
        <Avatar
          size="big"
          src={winner.avatar}
          css={game.mode === 'lo' && tw`border-[3px] border-purple`}
        />
        <H1 tw="mt-3 mb-2" id="duel-winner-name">
          {winner.name}
        </H1>
        <div tw="text-sm font-bold text-gray-el4">
          <FormattedMessage id="duels.hero-won" values={{ roll }} />
        </div>
      </div>
    )
  }

  return (
    <div tw="py-10 flex flex-col items-center">
      <Swords tw="w-20 fill-current" css={getColor(game)} />
      <div tw="text-sm font-bold text-gray-el4">
        <FormattedMessage id={`duels.rules.${game.mode}`} />
      </div>
      <H1>
        <FormattedMessage id={`duels.game-title.${game.mode}`} />
      </H1>
    </div>
  )
}

const fireConfetti = (x: Float, y: Float, count = 200) => {
  const defaults = {
    origin: { x, y },
    disableForReducedMotion: true
  }

  const fire = (particleRatio: Float, opts: any) => {
    confetti(
      Object.assign({}, defaults, opts, {
        particleCount: Math.floor(count * particleRatio)
      })
    )
  }

  fire(0.25, {
    spread: 26,
    startVelocity: 55
  })
  fire(0.2, {
    spread: 60
  })
  fire(0.35, {
    spread: 100,
    decay: 0.91,
    scalar: 0.8
  })
  fire(0.1, {
    spread: 120,
    startVelocity: 25,
    decay: 0.92,
    scalar: 1.2
  })
  fire(0.1, {
    spread: 120,
    startVelocity: 45
  })
}
