import { useQuery, useQueryClient } from 'react-query'
import { API } from '../API'
import { getUser } from '../apis/user'

export const useUser = () => {
  const queryClient = useQueryClient()
  const { data, isLoading, isError, error } = useQuery('user', getUser, {
    refetchOnWindowFocus: true
  })

  const logout = async () => {
    await API.logout()
    queryClient.invalidateQueries('user')
  }

  return {
    user: data,
    isGuest: data?.isGuest,
    isAuthenticated: !isLoading && !isError && data && !data?.isGuest,
    isLoading,
    error,
    logout
  }
}
