import { Disclosure } from '@headlessui/react'
import { RouteComponentProps } from '@reach/router'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import tw from 'twin.macro'
import { Container, H3 } from '../_common/components'
import { ReactComponent as Chevron } from '../_common/assets/angle-left.svg'
import { useTitle } from '../_common/hooks/useTitle'

export const FAQ: React.FC<RouteComponentProps> = () => {
  useTitle('FAQ')

  return (
    <Container tw="py-5 md:py-10 max-w-4xl">
      <div id="bg" />
      <H3>
        <FormattedMessage id="faq.title" />
      </H3>
      {categories.map(({ category, questions }) => (
        <div key={category} tw="mb-10">
          <H3>
            <FormattedMessage id={`faq.${category}`} />
          </H3>
          <div tw="flex flex-col gap-5">
            {questions.map((question) => (
              <Question
                key={question}
                question={<FormattedMessage id={`faq.${question}.q`} />}
                answer={<FormattedMessage id={`faq.${question}.a`} />}
              />
            ))}
          </div>
        </div>
      ))}
    </Container>
  )
}

const categories = [
  {
    category: 'general',
    questions: ['support', 'bug', 'sponsorship']
  },
  {
    category: 'crash',
    questions: [
      'crash-how',
      'crash-items',
      'crash-less',
      'cashout-lost',
      'cashout-auto'
    ]
  },
  {
    category: 'duels',
    questions: ['duels-how', 'duels-tax']
  },
  {
    category: 'lottery',
    questions: ['lottery-how', 'lottery-tickets', 'lottery-when']
  }
]

const Question: React.FC<{
  question: React.ReactNode
  answer: React.ReactNode
}> = ({ question, answer }) => {
  return (
    <div tw="rounded-md overflow-hidden">
      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button
              tw="p-4 bg-gainsBgSubNavbar hover:bg-gainsBgSubNavbarHover transition w-full text-sm text-left font-bold flex flex-row items-center"
              className="group">
              <div tw="flex-1 text-gainsBgSubNavbarText">{question}</div>
              <Chevron
                tw="w-5 h-5 fill-current text-gray group-hover:text-gray-el2 transition duration-200"
                css={open ? tw`rotate-90` : tw`-rotate-90`}
              />
            </Disclosure.Button>
            <Disclosure.Panel tw="py-3 px-4 bg-gainsBgSubNavbar text-sm text-white border-t-2 border-white/25">
              {answer}
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  )
}
