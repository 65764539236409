import { BetState, CrashBet } from '@crashgg/types/dist'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import tw, { TwStyle } from 'twin.macro'
import { Avatar } from '../_common/components'
import { Currency } from '../_common/components/Currency'

interface Props {
  bet: CrashBet
}

export const Bet: React.FC<Props> = ({ bet }) => {
  return (
    <div tw="rounded-md px-3 py-2" css={[bgColors[bet.state]]}>
      <div tw="flex flex-row items-center">
        <Avatar src={bet.user.avatar} size="medium" />
        <div tw="ml-2 flex-1">
          <div tw="text-white max-w-[130px] truncate">{bet.user.name}</div>
          <div tw="text-xs text-gray-el1">
            <Subtext bet={bet} />
          </div>
        </div>
        <Currency
          value={
            bet.state === BetState.Cashout
              ? Math.round(bet.amount * bet.cashedOutAt!)
              : bet.amount
          }
        />
      </div>
    </div>
  )
}

const bgColors: Record<BetState, TwStyle> = {
  [BetState.Active]: tw`bg-dark-el2`,
  [BetState.Pending]: tw`bg-dark-el2`,
  [BetState.Cashout]: tw`bg-gradient-to-r from-[rgb(25,96,62)] to-dark-el2`,
  [BetState.Lost]: tw`bg-gradient-to-r from-[rgb(89,32,44)] to-dark-el2`
}

const Subtext: React.FC<Props> = ({ bet }) => {
  if ([BetState.Active, BetState.Lost].includes(bet.state)) {
    return bet.autoCashoutAt ? (
      <FormattedMessage
        id="crash.cashout-at"
        values={{ at: bet.autoCashoutAt.toFixed(2) }}
      />
    ) : (
      <FormattedMessage id="crash.no-auto" />
    )
  }

  if (BetState.Cashout === bet.state) {
    return (
      <FormattedMessage
        id="crash.cashout-at"
        values={{ at: bet.cashedOutAt?.toFixed(2) }}
      />
    )
  }

  return null
}
