import soundError from './assets/sounds/error.mp3'
import soundNotification from './assets/sounds/notification.mp3'
import soundPlaceBet from './assets/sounds/place-bet.mp3'
import soundWin from './assets/sounds/win.mp3'
import soundCrash from './assets/sounds/crash.mp3'

export const VOLUME_KEY = 'settings:sound-volume'

const play = (source: string) => {
  const audio = new Audio(source)
  audio.volume = getVolume()
  audio.play().catch((err) => {
    console.log(`Failed to play audio`, err)
  })
}

export const getVolume = () => {
  const value = localStorage.getItem(VOLUME_KEY)
  if (value) return parseFloat(value)

  return 0.3
}

export const persistVolume = (volume: string) => {
  localStorage.setItem(VOLUME_KEY, volume)
}

export const sounds = {
  error: () => play(soundError),
  notification: () => play(soundNotification),
  placeBet: () => play(soundPlaceBet),
  win: () => play(soundWin),
  crash: () => play(soundCrash)
}
