import React from 'react'
import 'twin.macro'
import { Duel } from './lib/duels-api'
import { Player } from './Player'
import { ReactComponent as UserGroup } from './assets/user-group.svg'
import { ReactComponent as WarningIcon } from './assets/warning.svg'
import { FormattedMessage } from 'react-intl'

interface Props {
  game: Duel
}

export const RightSection: React.FC<Props> = ({ game }) => {
  const showBotNotice =
    game.players.length < game.playerCount || game.players.find((p) => p.botId)

  return (
    <div tw="bg-dark px-3 space-y-3">
      <div tw="text-gray-el2 pt-3 flex flex-row items-center font-bold">
        <UserGroup tw="h-3 fill-current text-gray-de1 mr-2" />
        Players in Game
      </div>
      {[...new Array(game.playerCount)].map((_, idx) => (
        <Player teamId={idx + 1} game={game} key={idx} />
      ))}

      {showBotNotice && (
        <div tw="text-sm text-gray text-center px-5 gap-5 md:(absolute bottom-5) flex flex-row items-center">
          <WarningIcon tw="h-6 fill-current text-gray-de1" />
          <div>
            <FormattedMessage id="duels.bot-tax" />
          </div>
        </div>
      )}
    </div>
  )
}
