import { Int } from '@crashgg/types/dist'
import { API } from '../../_common/API'

export const getMessages = (room: string) => {
  return API.get(`/chat/messages?room=${room}`)
}

interface SendMessage {
  room: string
  message: string
  isMod: boolean
}

export const sendMessage = ({ room, message, isMod }: SendMessage) => {
  if (isMod && message.startsWith('/')) {
    const [cmd, ...args] = message.split(' ')

    const command = {
      '/ban': () => limitUser('ban', args[0], ...args.slice(1)),
      '/mute': () => limitUser('mute', args[0], ...args.slice(1)),
      '/unban': () => limitUser('ban', args[0], 1),
      '/unmute': () => limitUser('mute', args[0], 1)
    }[cmd]

    if (command) return command()
  }

  return API.post(`/chat/messages`, { room, message })
}

const limitUser = async (
  type: string,
  steamId: string,
  duration?: string | number,
  ...reason: string[]
) => {
  return API.patch(`/user/by-steamid/${steamId}/limit`, {
    type,
    duration: (duration ? ~~duration : 365 * 24 * 60) * 60,
    reason: reason.join(' ')
  })
}

export const removeMessage = (id: Int) => {
  return API.delete(`/chat/messages/${id}`)
}
