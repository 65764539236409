import React from 'react'
import { FormattedNumber } from 'react-intl'
import { useQuery } from 'react-query'
import 'twin.macro'
import { Avatar, Container, LoadingScreen, Table } from '../_common/components'
import { Currency } from '../_common/components/Currency'
import { getAffiliates } from './apis/stats'

export const Affiliates: React.FC = () => {
  const { data } = useQuery('admin-affiliates', getAffiliates)

  if (!data) return <LoadingScreen />

  return (
    <Container>
      <Table isFull>
        <thead>
          <Table.Tr>
            <Table.Td>User</Table.Td>
            <Table.Td>Code</Table.Td>
            <Table.Td>Referrals</Table.Td>
            <Table.Td>Wager</Table.Td>
            <Table.Td>Clicks</Table.Td>
          </Table.Tr>
        </thead>
        <tbody>
          {data.map((affiliate) => (
            <Table.Tr key={affiliate.steamId}>
              <Table.Td>
                <a
                  href={`https://steamcommunity.com/profiles/${affiliate.steamId}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  tw="flex flex-row gap-2 transition duration-150 hover:text-white">
                  <Avatar src={affiliate.avatar} size="small" />
                  {affiliate.name}
                </a>
              </Table.Td>
              <Table.Td>{affiliate.code}</Table.Td>
              <Table.Td>
                <FormattedNumber value={affiliate.referrals} />
              </Table.Td>
              <Table.Td>
                <Currency value={affiliate.wager * 2} size="small" />
              </Table.Td>
              <Table.Td>{affiliate.clicks}</Table.Td>
            </Table.Tr>
          ))}
        </tbody>
      </Table>
    </Container>
  )
}
