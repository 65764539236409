import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useMutation, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'
import tw from 'twin.macro'
import {
  ActionButton,
  ErrPretty,
  H2,
  Input,
  Modal,
  ModalProps,
  H1,
  P
} from '../_common/components'
import { Currency } from '../_common/components/Currency'
import { useAffiliateCode } from './lib/affiliates'
import { REFLINK_CODE } from './ReflinkPage'
import { UseCode } from '../cashier/UseCode'
import { usePromoCode } from '../cashier/apis/deposit'

export const PromoCodeModal: React.FC<ModalProps> = (props) => {
  // const { formatMessage } = useIntl()

  // const [code, setCode] = useState(localStorage.getItem(REFLINK_CODE) || '')

  // const queryClient = useQueryClient()
  // const mutation = useMutation(useAffiliateCode, {
  //   onSuccess: () => {
  //     queryClient.invalidateQueries('user')
  //     toast.success(
  //       formatMessage(
  //         { id: 'free-coins.claimed' },
  //         { code: code.toUpperCase() }
  //       )
  //     )
  //     props.close()
  //   }
  // })

  return (
    <Modal {...props} styles={tw`px-5 py-5 max-w-md`}>
      <H1>
        <FormattedMessage id="deposit.promo-code.title" />
      </H1>
      <P>
        <FormattedMessage id="deposit.promo-code.desc" />
      </P>

      <div tw="mt-10">
        <UseCode method={usePromoCode} withCaptcha />
      </div>
    </Modal>
  )
}
