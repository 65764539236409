import { keyframes } from '@emotion/react'
import tw from 'twin.macro'

export const shake = keyframes`
  0%, 40% { transform: translate3d(-5px, 0, 0); }
  20%, 60% { transform: translate3d(5px, 0, 0); }
`

export const shakeFull = keyframes`
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
`

export const fadeIn = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`

export const zoomIn = keyframes`
  0% { transform: scale(0.8, 0.8); }
  100% { transform: scale(1, 1); }
`

export const blink = keyframes`
  0% { ${tw`bg-red-dark/70`} }
  20% { ${tw`bg-red-dark/50`} }
  100% { ${tw`bg-transparent`} }
`

export const zoomedSpin = keyframes`
  0% { transform: scale(1.2, 1.2) rotate(0deg) translateZ(-1em); }
  100% { transform: scale(1.2, 1.2) rotate(360deg) translateZ(-1em); }
`
