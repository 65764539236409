import React, { useState } from 'react'
import tw from 'twin.macro'
import logoIcon from '../_common/assets/logo-icon.svg'
import Hamburger from 'hamburger-react'
import { Balance } from './Balance'
import { PrimaryLinks, SecondaryLink, SecondaryLinks } from './Navigation'
import { Link } from '@reach/router'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { useUser } from '../_common/hooks/useUser'
import { FormattedMessage } from 'react-intl'
import { SignInButton } from '../_common/components/SignInButton'

export const Mobile: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false)
  const { isAuthenticated, logout } = useUser()

  return (
    <>
      <div tw="h-16 bg-dark-el1 border-b-2 border-dark-el2 flex flex-row items-center px-3">
        <Link to="/" tw="block w-12">
          <img src={logoIcon} alt="REUP.GG" tw="h-10" />
        </Link>
        <div tw="flex-1 flex justify-center">
          {isAuthenticated ? <Balance withTopUp /> : <SignInButton compact />}
        </div>
        <div tw="text-gray">
          <Hamburger toggled={isOpen} toggle={() => setIsOpen(!isOpen)} />
        </div>
      </div>
      {isOpen && (
        <div tw="bg-dark-el1 z-40 pt-5">
          <PrimaryWrapper>
            <PrimaryLinks />
          </PrimaryWrapper>
          <div tw="flex flex-col m-5 gap-2">
            {isAuthenticated && (
              <>
                <SecondaryLink to="/profile">
                  <FormattedMessage id="nav.profile" />
                </SecondaryLink>
                <SecondaryLink to="/withdraw/rust">
                  <FormattedMessage id="nav.withdraw" />
                </SecondaryLink>
              </>
            )}
            <SecondaryLinks />
            {isAuthenticated && (
              <div tw="text-red" onClick={logout}>
                <FormattedMessage id="nav.logout" />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  )
}

const PrimaryWrapper = styled.nav([
  tw`flex flex-col`,
  css`
    .link {
      ${tw`h-14! border-l-2 px-5`}
    }
  `
])
