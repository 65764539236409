import React from 'react'
import 'twin.macro'
import { RouteComponentProps } from '@reach/router'
import { useIntl } from 'react-intl'
import { Tab } from './Tab'

import { ReactComponent as Rust } from './assets/rust.svg'
import { ReactComponent as CSGO } from './assets/csgo.svg'
import { ReactComponent as Crypto } from './assets/multi-crypto.svg'
import { ReactComponent as Gift } from './assets/gift.svg'
import kinguinImage from './assets/kinguin.png'
import dota2 from './assets/dota2.png'

export const DepositTabs: React.FC<RouteComponentProps> = () => {
  const { formatMessage } = useIntl()

  return (
    <div tw="hidden md:flex flex-row justify-center lg:justify-start flex-wrap gap-3 py-3 px-3 bg-gainsBgChat">
      <Tab
        to="/deposit/rust"
        name="Rust Skins"
        image={<Rust tw="w-10" />}
        lead={formatMessage({ id: 'deposit.instant' })}
      />
      <Tab
        to="/deposit/skinsback"
        name="CS:GO Skins"
        image={<CSGO tw="w-10" />}
        lead={formatMessage({ id: 'deposit.bonus' }, { bonus: 0.4 })}
      />
      <Tab
        to="/deposit/skinsback"
        name="Dota 2 Skins"
        image={<img src={dota2} alt="Dota 2" />}
        lead={formatMessage({ id: 'deposit.bonus' }, { bonus: 0.4 })}
      />
      <Tab
        to="/deposit/giftcards"
        name="Kinguin / Card"
        image={<img src={kinguinImage} alt="Kinguin" />}
        lead={formatMessage({ id: 'deposit.bonus' }, { bonus: 0.4 })}
      />
      <Tab
        to="/deposit/crypto/btc"
        name="Crypto"
        image={<Crypto />}
        lead={formatMessage({ id: 'deposit.bonus' }, { bonus: 0.4 })}
      />
      {/* <Tab
        to="/deposit/promocodes"
        name="Promo Code"
        image={<Gift tw="fill-current text-blue" />}
        lead={formatMessage({ id: 'deposit.free' })}
      /> */}
    </div>
  )
}
