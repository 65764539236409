import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import 'twin.macro'
import { Button, Err, Input } from '../_common/components'
import { CreateGiftcards, createGiftcards } from './apis/admin'

export const Giftcards: React.FC = () => {
  const [codes, setCodes] = useState('')
  const { register, handleSubmit, reset } = useForm()

  const create = useMutation(createGiftcards, {
    onSuccess: (giftcards) => {
      setCodes(giftcards.join('\n'))
      reset()
    }
  })

  const onSubmit = (payload: CreateGiftcards) =>
    create.mutate({ amount: ~~(payload.amount * 100), count: ~~payload.count })

  return (
    <div tw="grid lg:grid-cols-4 gap-5">
      <form onSubmit={handleSubmit(onSubmit)} tw="flex flex-col gap-3">
        <Input
          type="number"
          step={0.01}
          min={0.01}
          placeholder="Amount"
          {...register('amount')}
        />
        <Input
          type="number"
          step={1}
          min={1}
          placeholder="Count"
          {...register('count')}
        />
        <Button type="submit">Create giftcards</Button>
        {create.error && <Err>{(create.error as Error).message}</Err>}
      </form>

      <div tw="col-span-3">
        {codes && (
          <textarea
            tw="bg-dark-el1 w-full select-all font-mono"
            value={codes}
            readOnly
            rows={20}
          />
        )}
      </div>
    </div>
  )
}
