import { CrashPlaceBet, GameState, Int } from '@crashgg/types/dist'
import React, { useState, memo } from 'react'
import { FormattedMessage } from 'react-intl'
import tw from 'twin.macro'
import {
  Button,
  Container,
  Input,
  Fade,
  fade,
  AltLabel,
  InputWrapper,
  BetInput
} from '../_common/components'
import { ReactComponent as MultipIcon } from '../_common/assets/close.svg'
import { Currency, CurrencyUsd } from '../_common/components/Currency'
import { INF } from '../_common/constants'
import { useQuickBets } from './lib/useQuickBets'

interface Props {
  gameState: GameState
  betCount: Int
  hasActiveBets: boolean
  placeBet: (payload: CrashPlaceBet) => void
  cashoutAll: () => void
}

export const PlaceBet: React.FC<Props> = memo(
  ({ gameState, betCount, hasActiveBets, placeBet, cashoutAll }) => {
    const [amount, setAmount] = useState('0')
    const [multiplier, setMultiplier] = useState('')
    const { bets, add } = useQuickBets()

    const placeAndAdd = (bet: CrashPlaceBet) => {
      placeBet(bet)
      add(bet)
    }

    const doPlaceBet = () => {
      const bet = {
        amount: Math.round(Number(amount) * 100),
        ...(multiplier.length && { autoCashoutAt: Number(multiplier) })
      }
      placeAndAdd(bet)
    }

    const canBet = gameState === GameState.Betting && betCount < 3

    return (
      <div>
        <div tw="my-5 w-64 mx-auto">
          {gameState === GameState.InProgress && betCount ? (
            <Button
              size="big"
              color={hasActiveBets ? 'primary' : 'default'}
              tw="uppercase font-bold"
              disabled={!hasActiveBets}
              onClick={cashoutAll}
              full>
              <FormattedMessage id="crash.cashout" />
            </Button>
          ) : canBet ? (
            <Button
              size="big"
              color="primary"
              tw="uppercase font-bold"
              onClick={doPlaceBet}
              full>
              <FormattedMessage id="crash.upgrade" />
            </Button>
          ) : (
            <Button size="big" tw="uppercase font-bold" disabled full>
              <FormattedMessage
                id={
                  gameState === GameState.Ended
                    ? 'crash.cashout'
                    : 'crash.upgrade'
                }
              />
            </Button>
          )}
        </div>
        <div tw="flex flex-col max-w-[14rem] items-center mx-auto md:(flex-row max-w-none) justify-center mt-8 gap-5">
          <div>
            <AltLabel>
              <FormattedMessage id="common.bet-amount" />
            </AltLabel>
            <BetInput value={amount} setAmount={setAmount} />
          </div>

          <div>
            <AltLabel>
              <FormattedMessage id="crash.auto-cashout" />
            </AltLabel>
            <InputWrapper>
              <MultipIcon tw="w-5 h-5 my-auto ml-1 fill-current text-blue-el2" />
              <Input
                type="number"
                min={1.01}
                step={0.25}
                tw="w-24 mx-0 p-2 text-lg"
                value={multiplier}
                placeholder={INF}
                onChange={(e) => setMultiplier(e.target.value)}
              />
            </InputWrapper>
          </div>

          <div>
            <AltLabel>
              <FormattedMessage id="crash.potential-payout" />
            </AltLabel>
            <InputWrapper tw="w-36 pl-1 py-1">
              {multiplier ? (
                <Currency
                  value={~~(100 * Number(amount) * Number(multiplier))}
                  styles={tw`font-normal text-lg pl-1 py-2`}
                />
              ) : (
                <span tw="text-gray-de2 text-lg py-2 pl-2">{INF}</span>
              )}
            </InputWrapper>
          </div>
        </div>
        <QuickBets bets={bets} placeBet={placeAndAdd} canBet={canBet} />
      </div>
    )
  }
)

interface QuickBetsProps {
  bets: CrashPlaceBet[]
  placeBet: (payload: CrashPlaceBet) => void
  canBet: boolean
}

const QuickBets: React.FC<QuickBetsProps> = ({ bets, placeBet, canBet }) => {
  if (!bets.length) return null

  return (
    <Container tw="w-screen sm:max-w-lg mt-5 overflow-hidden">
      <AltLabel>
        <FormattedMessage id="crash.quick-bets" />
      </AltLabel>
      <Fade
        fades={[fade('big', 'right', `#060C33`)]}
        tw="flex flex-row gap-3 overflow-hidden">
        {bets.map((bet, i) => (
          <button
            tw="text-xs px-2 py-1 rounded-md bg-gainsBgChat hover:bg-gainsBgChatInput flex-shrink-0 transition duration-150"
            key={i}
            disabled={!canBet}
            css={!canBet && tw`cursor-not-allowed`}
            onClick={() => canBet && placeBet(bet)}>
            <CurrencyUsd value={bet.amount} />
            <div tw="inline-block ml-2 text-gray-el1">
              {bet.autoCashoutAt ? `x${bet.autoCashoutAt}` : INF}
            </div>
          </button>
        ))}
      </Fade>
    </Container>
  )
}
