import { notifyError, prettyError, sleep } from './utils'
import { WS, WSCrash } from './WS'

const API_URL = process.env.REACT_APP_API_URL

interface APIOptions {
  skipAuth?: boolean
  skipError?: boolean
}

class APIClient {
  private accessToken?: string
  private isFetchingToken = false
  isGuest = false

  async login() {
    // eslint-disable-next-line prettier/prettier
    // (window as any).gtag('event', 'conversion', {
    //   send_to: 'AW-11054659065/1IpACOWIwoYYEPnropcp'
    // })

    window.location.href = `${API_URL}/auth/steam`
  }

  linkDiscord() {
    window.location.href = `${API_URL}/auth/discord`
  }

  async logout() {
    this.isGuest = true
    this.accessToken = undefined

    return this.post('/auth/logout')
  }

  async get(endpoint: string, queryParams?: Record<string, string | string>) {
    const query = new URLSearchParams(queryParams).toString()
    const withQuery = endpoint + (query ? `?${query}` : '')

    return this.request('GET', withQuery)
  }

  async post(endpoint: string, data?: any, options?: APIOptions) {
    return this.requestWithBody('POST', endpoint, data, options)
  }

  async put(endpoint: string, data?: any, options?: APIOptions) {
    return this.requestWithBody('PUT', endpoint, data, options)
  }

  async patch(endpoint: string, data?: any, options?: APIOptions) {
    return this.requestWithBody('PATCH', endpoint, data, options)
  }

  async delete(endpoint: string, data?: any, options?: APIOptions) {
    return this.requestWithBody('DELETE', endpoint, data, options)
  }

  private async requestWithBody(
    method: string,
    endpoint: string,
    data?: any,
    options: APIOptions = {}
  ) {
    return this.request(
      method,
      endpoint,
      {
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data ?? {})
      },
      options
    )
  }

  private async request(
    method: string,
    endpoint: string,
    options?: RequestInit,
    apiOptions: APIOptions = {}
  ) {
    const res = await fetch(API_URL + endpoint, {
      ...options,
      method,
      mode: 'cors',
      credentials: 'include',
      headers: {
        ...options?.headers,
        ...(!apiOptions.skipAuth && (await this.getAuthHeaders()))
      }
    })
    const json = await res.json()

    if (!res.ok) {
      if (json.statusCode === 401) this.accessToken = undefined
      else if (!apiOptions.skipError) notifyError(prettyError(json.message))

      return Promise.reject(json)
    }

    return json
  }

  private async getAuthHeaders(): Promise<Record<string, string>> {
    if (this.isGuest) return {}

    if (!this.accessToken) {
      if (this.isFetchingToken) {
        await sleep(50)
        return this.getAuthHeaders()
      }

      await this._fetchAccessToken()
    }

    return { Authorization: `Bearer ${this.accessToken}` }
  }

  async _fetchAccessToken(force?: boolean) {
    if (!force && this.isFetchingToken)
      throw new Error('already_fetching_token')

    this.isFetchingToken = true
    try {
      const { accessToken } = await this.request(
        'GET',
        '/auth/access_token',
        undefined,
        { skipAuth: true }
      )

      this.accessToken = accessToken
      WS.login(accessToken)
      WSCrash.login(accessToken)
    } catch (err) {
      this.isGuest = true
    }
  }
}

export const API = new APIClient()
