import React, { useState } from 'react'
import { useQuery } from 'react-query'
import 'twin.macro'
import { API } from '../_common/API'
import { Button, Container, H1, Input } from '../_common/components'

export const Lookup: React.FC = () => {
  const [query, setQuery] = useState('')
  const [endpoint, setEndpoint] = useState('')

  const userBySteamId = () => setEndpoint(`/user/by-steamid/${query}`)
  const userById = () => setEndpoint(`/user/by-id/${query}`)
  const giftcardByCode = () => setEndpoint(`/payments/giftcards/code/${query}`)
  const giftcardsBySteamId = () =>
    setEndpoint(`/payments/giftcards/claimed-by/${query}`)

  const { data, isLoading } = useQuery(
    ['lookup', endpoint],
    async () => endpoint.length && API.get(endpoint),
    { retry: false }
  )

  return (
    <Container tw="my-10">
      <H1 tw="text-center">Lookup</H1>

      <Input
        full
        type="text"
        placeholder="Search anything"
        tw="bg-dark-el2 placeholder-gray-el2"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
      />

      <div tw="flex flex-row flex-wrap gap-3 mt-5 justify-center">
        <Button onClick={userBySteamId}>User by Steam ID</Button>
        <Button onClick={userById}>User by ID</Button>
        <Button onClick={giftcardByCode} color="primary">
          Giftcard by code
        </Button>
        <Button onClick={giftcardsBySteamId} color="primary">
          Giftcards by Steam ID
        </Button>
      </div>

      <pre tw="bg-dark-el1 mt-10">
        {isLoading ? 'Loading...' : data ? JSON.stringify(data, null, 2) : null}
      </pre>
    </Container>
  )
}
