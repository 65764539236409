import { useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { randomSeed } from '../../_common/components/SeedInput'
import { getServerHash } from './blackjack-api'

export const SERVER_HASH = 'serverHash'

export interface Fairness {
  serverHash: string
  randomizeSeed: () => void
  seed: string
  setSeed: React.Dispatch<React.SetStateAction<string>>
  updateServerHash: (serverHash: string) => void
  prevGame: PrevFairness | null
  setPrevGame: React.Dispatch<React.SetStateAction<PrevFairness | null>>
  refreshServerHash: () => void
}

export interface PrevFairness {
  serverHash: string
  serverSeed: string
  seed: string
}

export const useFairness = (): Fairness => {
  const queryClient = useQueryClient()
  const hashQuery = useQuery(SERVER_HASH, getServerHash)
  const [seed, setSeed] = useState(randomSeed())
  const [prevGame, setPrevGame] = useState<PrevFairness | null>(null)

  const randomizeSeed = () => setSeed(randomSeed())

  const updateServerHash = (serverHash: string) => {
    queryClient.setQueryData(SERVER_HASH, { serverHash })
  }

  const refreshServerHash = () => {
    queryClient.invalidateQueries(SERVER_HASH)
  }

  return {
    serverHash: hashQuery.data?.serverHash || 'N/A',
    randomizeSeed,
    seed,
    setSeed,
    updateServerHash,
    prevGame,
    setPrevGame,
    refreshServerHash
  }
}
