import { RouteComponentProps } from '@reach/router'
import React, { useState } from 'react'
import 'twin.macro'
import { API } from '../_common/API'
import { LoadingScreen } from '../_common/components'
import { useTitle } from '../_common/hooks/useTitle'
import { useUser } from '../_common/hooks/useUser'
import { BuyTicketsModal } from './BuyTicketsModal'
import { Hero } from './Hero'
import { HowItWorks } from './HowItWorks'
import { useLottery } from './lib/useLottery'
import { LiveTickets } from './LiveTickets'
import { Previous } from './Previous'

export const Lottery: React.FC<RouteComponentProps> = () => {
  useTitle('Lottery')
  const { isAuthenticated } = useUser()

  const { current } = useLottery()

  const [isOpen, setIsOpen] = useState(false)
  const open = () => {
    if (!isAuthenticated) return API.login()

    setIsOpen(true)
  }

  if (!current.data) return <LoadingScreen />
  const { lottery, pot, ticketsPerCoin } = current.data

  return (
    <>
      <div tw="font-rubik">
        <Hero
          id={lottery.id}
          pot={pot}
          endsAt={lottery.endsAt}
          entryPrice={lottery.entryPrice}
          open={open}
        />
        <LiveTickets current={current.data} />
        <Previous currentId={lottery.id} />
        <HowItWorks />
      </div>

      <BuyTicketsModal
        isOpen={isOpen}
        close={() => setIsOpen(false)}
        entryPrice={lottery.entryPrice}
        ticketsPerCoin={ticketsPerCoin}
      />
    </>
  )
}
