import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useMutation, useQueryClient } from 'react-query'
import 'twin.macro'
import { H3, Input, P, ActionButton, ErrPretty, Success } from '.'
import { useUser } from '../hooks/useUser'
import { updateTradelink } from '../../profile/apis/profile'

interface Props {
  isRequired?: boolean
}

export const Tradelink: React.FC<Props> = ({ isRequired }) => {
  const [tradelink, setTradelink] = useState('')

  const { user } = useUser()
  useEffect(() => {
    if (tradelink === '' && user.tradeToken) {
      setTradelink(buildTradelink(user.steamId, user.tradeToken))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  const queryClient = useQueryClient()
  const updateMutation = useMutation(updateTradelink, {
    onSuccess: () => queryClient.invalidateQueries('user')
  })

  return (
    <section>
      <H3>
        <FormattedMessage
          id={isRequired ? 'settings.trade-url-required' : 'settings.trade-url'}
        />
      </H3>
      <P tw="mb-3">
        <FormattedMessage id="settings.trade-url-desc" values={{ clickHere }} />
      </P>

      <div tw="flex flex-row gap-5">
        <Input
          type="text"
          tw="flex-1"
          placeholder="https://steamcommunity.com/tradeoffer/new/?partner=XXXXXXXXX&token=XXXXXXXX"
          value={tradelink}
          onChange={(e) => setTradelink(e.target.value)}
        />
        <ActionButton mutation={updateMutation} payload={tradelink}>
          <FormattedMessage id="common.save" />
        </ActionButton>
      </div>

      <ErrPretty error={updateMutation.error} />
      {updateMutation.isSuccess && (
        <Success>
          <FormattedMessage id="settings.trade-url-set" />
        </Success>
      )}
    </section>
  )
}

const openPopup = () => {
  window.open(
    'https://steamcommunity.com/my/tradeoffers/privacy#trade_offer_access_url',
    'findTradelink',
    'width=750 height=200'
  )
}

const clickHere = (
  <span tw="text-white cursor-pointer" onClick={openPopup}>
    <FormattedMessage id="common.click-here" />
  </span>
)

const buildTradelink = (steamid: string, token: string) => {
  const partner = BigInt(steamid) & BigInt(0xffffffff)

  return `https://steamcommunity.com/tradeoffer/new/?partner=${partner}&token=${token}`
}
