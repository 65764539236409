import React from 'react'
import { FormattedDate } from 'react-intl'
import 'twin.macro'
import { Table } from '../_common/components'
import { Currency } from '../_common/components/Currency'
import { Bet } from './apis/profile'

interface Props {
  bet: Bet
}

export const BetHistoryRow: React.FC<Props> = ({ bet }) => {
  const profit = bet.winningAmount - bet.betAmount

  return (
    <Table.Tr>
      <Table.Td>
        <FormattedDate
          value={bet.createdAt}
          dateStyle="medium"
          timeStyle="short"
        />
      </Table.Td>
      <Table.Td>
        <Currency size="small" value={bet.betAmount} />
      </Table.Td>
      <Table.Td>{bet.game.toUpperCase()}</Table.Td>
      <Table.Td>
        <Currency size="small" value={profit} comparison />
      </Table.Td>
      {/* <Table.Td>Verify</Table.Td> */}
    </Table.Tr>
  )
}
