import { Link, LinkProps, useLocation } from '@reach/router'
import React from 'react'
import tw from 'twin.macro'

type Props = LinkProps<undefined> & { icon: React.ReactFragment } & any

export const NavLink: React.FC<Props> = ({ children, icon, to, ...props }) => {
  const location = useLocation()

  return (
    <Link
      to={to}
      {...props}
      className="link"
      getProps={({ isCurrent }) => ({
        style: {
          ...tw`h-full block font-bold tracking-widest text-sm transition duration-150 border-0`,
          ...(isCurrent ? tw`text-gainsGold` : tw`text-gainsBlueText`)
        }
      })}>
      <div tw="flex h-full items-center flex-row gap-3 hover:text-gainsGold">
        <div
          css={[
            tw`transition duration-100`,
            location.pathname === to && tw`text-gainsGold`
          ]}>
          {icon}
        </div>
        <div tw="uppercase">{children}</div>
      </div>
    </Link>
  )
}
