import { Float } from '@crashgg/types/dist'
import { useEffect } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { refreshUser } from '../../_common/user'
import { WS } from '../../_common/WS'
import { Current, getCurrent, LastBuy } from './lottery-api'

export const CURRENT = 'lottery-current'

export const useLottery = () => {
  const queryClient = useQueryClient()

  useEffect(() => {
    const onNewPot = ({ detail }: CustomEvent<{ pot: Float }>) => {
      queryClient.setQueryData(CURRENT, (old: any) => {
        if (!old) return undefined

        return {
          ...old,
          pot: Math.round(detail.pot)
        } as Current
      })
    }

    const onEnter = ({ detail }: CustomEvent<LastBuy>) => {
      queryClient.setQueryData(CURRENT, (old: any) => {
        if (!old) return undefined

        return {
          ...old,
          totalTickets: (old.totalTickets || 0) + detail.count,
          lastBuys: [detail, ...old.lastBuys].slice(0, 5)
        } as Current
      })
    }

    const onNewLottery = async () => {
      await queryClient.invalidateQueries(CURRENT)
      refreshUser()
    }

    WS.on('lottery:pot', onNewPot)
    WS.on('lottery:enter', onEnter)
    WS.on('lottery:new', onNewLottery)

    return () => {
      WS.off('lottery:pot', onNewPot)
      WS.off('lottery:enter', onEnter)
      WS.off('lottery:new', onNewLottery)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const current = useQuery(CURRENT, getCurrent)

  return { current }
}
