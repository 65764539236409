import React from 'react'
import tw from 'twin.macro'
import { Button, ButtonProps } from './Button'
import { ReactComponent as Refresh } from '../assets/refresh.svg'
import { UseQueryResult } from 'react-query'

interface Props extends ButtonProps {
  query: UseQueryResult<any, any>
}

export const RefreshQuery: React.FC<Props> = ({ query, ...buttonProps }) => {
  const invalidate = () => {
    if (query.isFetching) return

    query.refetch()
  }

  return (
    <Button
      tw="w-min"
      color="transparent"
      disabled={query.isFetching}
      onClick={invalidate}
      {...buttonProps}>
      <Refresh
        css={[tw`h-6 fill-current`, query.isFetching && tw`animate-spin`]}
      />
    </Button>
  )
}
