import { BjPublic, Int } from '@crashgg/types/dist'
import { useEffect, useRef, useState } from 'react'
import { sleep } from '../../_common/utils'
import { useDeepCompareEffectNoCheck } from 'use-deep-compare-effect'

export interface CardsDrawn {
  gameId: Int | undefined
  dealer: Int
  player: Int[]
  isDrawing?: boolean
  isDealerRevealed?: boolean
}

export const useCardsDrawn = (
  game: BjPublic | undefined,
  gameId: Int | undefined,
  isLoading: boolean
) => {
  const [cardsDrawn, setCardsDrawn] = useState<CardsDrawn>({
    gameId,
    dealer: 0,
    player: [0, 0]
  })
  const prevRef = useRef<any>(cardsDrawn)

  const gamePrimitive = game && JSON.stringify(game)
  // console.log(gamePrimitive, gameId, isLoading, cardsDrawn)
  console.log(gamePrimitive, isLoading)

  useDeepCompareEffectNoCheck(() => {
    console.log('effect call')
    if (!game) return console.log('no game')
    if (isLoading && typeof isLoading !== 'number')
      return console.log('laoding')
    console.log('game ph', JSON.stringify(game), (game as any).action)

    let prev: CardsDrawn = prevRef.current
    if (!prev) return

    if (prev.gameId !== gameId) {
      const NEW_GAME: CardsDrawn = {
        gameId,
        dealer: 0,
        player: [0, 0],
        isDrawing: true,
        isDealerRevealed: game.allHandsDone
      }
      prevRef.current = { ...NEW_GAME }
      prev = prevRef.current
      setCardsDrawn({ ...NEW_GAME })
    }

    // Handle splits
    if (game.playerHands.length === 2 && prev.player.length === 1) {
      console.log('handleSplit')
      prev.player = [0, 0]
      setCardsDrawn((prev) => ({ ...prev, player: [0, 0] }))
    }

    const dealerDraws = game.dealerHand.length - prev.dealer
    const playerDraws = game.playerHands.map((hand, i) => {
      console.log('hand', i, hand.cards.length, prev.player[i])
      return hand.cards.length - (prev.player[i] || 0)
    })
    if (playerDraws.length === 1) playerDraws.push(0)

    const maxPlayerDraws = Math.max(...playerDraws)

    if (dealerDraws === 0 && maxPlayerDraws === 0) {
      console.log('skipping - no draws')
      if (game.allHandsDone)
        setCardsDrawn((prev) => ({ ...prev, isDealerRevealed: true }))
      return
    }

    // eslint-disable-next-line
    ;(async () => {
      for (let i = 1; i <= maxPlayerDraws; i++) {
        setCardsDrawn((prevCd) => ({
          ...prevCd,
          isDrawing: true,
          player: prevCd.player.map((draws, j) => {
            return i <= playerDraws[j] ? draws + 1 : draws
          })
        }))
        console.log('pl', i)
        await sleep(1000)
      }

      if (game.dealerHand[1][0] !== -1) {
        setCardsDrawn((prev) => ({
          ...prev,
          isDrawing: true,
          isDealerRevealed: true
        }))
        // await sleep(game.dealerHand.length > 2 ? 1000 : 200)
        await sleep(1000)
      }

      if (dealerDraws) {
        for (let i = 1; i <= dealerDraws; i++) {
          setCardsDrawn((prevCd) => ({
            ...prevCd,
            isDrawing: true,
            dealer: prevCd.dealer + 1
          }))
          console.log('dea', i)
          await sleep(1000)
        }
      }

      setCardsDrawn((prev) => ({
        ...prev,
        isDrawing: false,
        isDealerRevealed: game.allHandsDone
      }))

      prevRef.current = {
        gameId,
        dealer: game.dealerHand.length,
        player: game.playerHands.map((h) => h.cards.length)
      }
    })()
  }, [game, gameId, isLoading])

  useEffect(() => {
    console.log('cardsDrawn', cardsDrawn)
  }, [cardsDrawn])

  return cardsDrawn
}
