import { Router } from '@reach/router'
import React from 'react'
import { ErrorBoundary } from './ErrorBoundary'
import { GlobalStyles } from './GlobalStyles'
import { IntlProvider } from './IntlProvider'
import { QueryProvider } from './QueryProvider'
import './lib/analytics'
import './lib/sentry'
import 'react-loading-skeleton/dist/skeleton.css'
import { ProfilePage } from '../profile/ProfilePage'
import { Toasts } from './Toasts'
import { CrashPage } from '../crash/CrashPage'
import { WithdrawPage } from '../cashier/WithdrawPage'
import { DepositItemsPage } from '../cashier/DepositItemsPage'
import { Error404 } from './Error404'
import { DepositPage } from '../cashier/DepositPage'
import { AdminPage } from '../admin/AdminPage'
import { Layout } from '../_common/components'
import { Lottery } from '../lottery/Lottery'
import { FAQ } from '../static/FAQ'
import { SkeletonTheme } from 'react-loading-skeleton'
import { theme } from 'twin.macro'
import { FairnessPage } from '../static/fairness/FairnessPage'
import { SkinsbackPage } from '../cashier/SkinsbackPage'
import { CryptoPage } from '../cashier/CryptoPage'
import { PromoCodePage } from '../cashier/PromoCodePage'
import { Events } from './Events'
import { GiftcardPage } from '../cashier/GiftcardPage'
import { PrivacyPage } from '../static/PrivacyPage'
import { TOSPage } from '../static/TOSPage'
import { AffiliatesPage } from '../affiliates/AffiliatesPage'
import { DuelsPage } from '../duels/DuelsPage'
import { ReflinkPage } from '../affiliates/ReflinkPage'
import { BlackjackPage } from '../blackjack/BlackjackPage'

const App: React.FC = () => {
  return (
    <IntlProvider>
      <ErrorBoundary>
        <QueryProvider>
          <GlobalStyles />
          <Toasts />
          <Events />
          <SkeletonTheme
            baseColor={theme`colors.dark.el1`}
            highlightColor={theme`colors.dark.el2`}>
            <Router>
              <Layout path="/">
                <CrashPage path="/" />
                <DuelsPage path="/duels" />
                <ProfilePage path="/profile" />
                <BlackjackPage path="/blackjack" />
                <Lottery path="/lottery" />
                <DepositPage path="/deposit" />
                <DepositItemsPage path="/deposit/rust" />
                <SkinsbackPage path="/deposit/skinsback" />
                <CryptoPage
                  path="/deposit/crypto/btc"
                  code="BTC"
                  name="Bitcoin"
                />
                <CryptoPage
                  path="/deposit/crypto/ltc"
                  code="LTC"
                  name="Litecoin"
                />
                <CryptoPage
                  path="/deposit/crypto/eth"
                  code="ETH"
                  name="Ethereum"
                />
                <PromoCodePage path="/deposit/promocodes" />
                <GiftcardPage path="/deposit/giftcards" />
                <WithdrawPage path="/withdraw/rust" />
                <AffiliatesPage path="/affiliates" />
                <ReflinkPage path="/r/:code" />
                <ReflinkPage path="/ref/:code" />
                <AdminPage path="/admin" />
                <FAQ path="/faq" />
                <TOSPage path="/terms-of-service" />
                <PrivacyPage path="/privacy-policy" />
                <FairnessPage path="/provably-fair" />
                <Error404 default />
              </Layout>
            </Router>
          </SkeletonTheme>
        </QueryProvider>
      </ErrorBoundary>
    </IntlProvider>
  )
}

export default App
