import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useMutation, useQueryClient } from 'react-query'
import 'twin.macro'
import { ActionButton, ErrPretty, Input, Success } from '../_common/components'
import { CodeClaimed } from './apis/deposit'

interface Props {
  method: ({ code }: { code: string }) => Promise<CodeClaimed>
  withCaptcha?: boolean
}

export const UseCode: React.FC<Props> = ({ method, withCaptcha }) => {
  const { formatMessage } = useIntl()
  const [code, setCode] = useState('')

  const queryClient = useQueryClient()
  const mutation = useMutation(method, {
    onSuccess: () => {
      setCode('')
      queryClient.invalidateQueries('user')
    }
  })

  return (
    <section>
      <div tw="flex flex-row gap-5 mb-2">
        <Input
          type="text"
          tw="flex-1"
          placeholder={formatMessage({ id: 'deposit.enter-code' })}
          value={code}
          onChange={(e) => setCode(e.target.value)}
        />
        <ActionButton
          mutation={mutation}
          payload={{ code }}
          withCaptcha={withCaptcha}>
          <FormattedMessage id="common.redeem" />
        </ActionButton>
      </div>

      {mutation.isError && <ErrPretty error={mutation.error} />}
      {mutation.isSuccess && (
        <Success>
          <FormattedMessage
            id="deposit.redeemed"
            values={{ amount: mutation.data.amount }}
          />
        </Success>
      )}
    </section>
  )
}
