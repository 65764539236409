import { Int } from '@crashgg/types/dist'
import { useEffect, useState } from 'react'
import { WS } from '../_common/WS'

export const useOnline = () => {
  const [online, setOnline] = useState(1)

  useEffect(() => {
    const update = ({ detail }: CustomEvent<{ online: Int }>) => {
      setOnline(detail.online)
    }

    WS.on('online', update)
    WS.send(['online', null])

    return () => WS.off('online', update)
  }, [])

  return { online }
}
