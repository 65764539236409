import { Int } from '@crashgg/types/dist'
import styled from '@emotion/styled'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import tw from 'twin.macro'
import { ReactComponent as AngleLeft } from '../assets/angle-left.svg'

interface Props {
  page: Int
  setPage: (page: Int) => void
  pages: Int
}

export const Pagination: React.FC<Props> = ({
  page,
  setPage: _setPage,
  pages
}) => {
  const setPage = (page: Int) => {
    if (page < 1 || page > pages) return
    _setPage(page)
  }

  return (
    <div tw="flex flex-row gap-2">
      <Tile onClick={() => setPage(page - 1)} disabled={page === 1}>
        <AngleLeft tw="fill-current" width={8} />
      </Tile>

      <Tile active>
        <FormattedMessage
          id="common.out-of"
          values={{ i: page, total: pages }}
        />
      </Tile>

      <Tile onClick={() => setPage(page + 1)} disabled={page === pages}>
        <AngleLeft tw="fill-current rotate-180" width={8} />
      </Tile>
    </div>
  )
}

interface TileProps {
  disabled?: boolean
  active?: boolean
}

const Tile = styled.div(({ active, disabled }: TileProps) => [
  tw`px-3 h-9 bg-dark-el2 rounded flex items-center justify-center font-medium text-gray-el4 text-sm`,
  disabled
    ? tw`cursor-not-allowed opacity-50`
    : tw`hover:(bg-dark-el3 text-gray-el5) cursor-pointer transition duration-150`,
  active && tw`bg-dark-el3 text-gray-el5 cursor-default`
])
