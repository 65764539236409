import { Listbox } from '@headlessui/react'
import React, { forwardRef } from 'react'
import tw from 'twin.macro'
import { ReactComponent as SelectorIcon } from '../assets/arrows-up-down.svg'

const MyListbox: any = Listbox

interface Props {
  options: Option[]
  selected: Option
  select: (o: Option) => void
}

export interface Option {
  id: any
  render: React.ReactElement
  disabled?: boolean
}

export const Select = forwardRef<HTMLInputElement, Props>(
  ({ options, selected, select, ...props }, ref) => {
    return (
      <MyListbox value={selected} onChange={select} ref={ref}>
        <div tw="relative">
          <MyListbox.Button
            tw="relative w-full rounded-md flex flex-row bg-gainsBgChat py-3 pl-3 pr-10 h-12"
            {...props}>
            <div tw="block truncate">{selected.render}</div>
            <SelectorIcon tw="pointer-events-none absolute right-2 h-5 mt-[2px] fill-current text-gray-de2" />
          </MyListbox.Button>
          <MyListbox.Options tw="absolute mt-1 w-full">
            {options.map((option) => (
              <MyListbox.Option
                css={[
                  tw`w-full rounded-md bg-gainsBgChat py-3 px-3 h-12`,
                  option.disabled
                    ? tw`cursor-not-allowed`
                    : tw`cursor-pointer hover:bg-gainsBgChatInput`,
                  selected.id === option.id && tw`bg-gainsBgChatInput`
                ]}
                key={option.id}
                value={option}
                disabled={option.disabled}
                {...props}>
                {option.render}
              </MyListbox.Option>
            ))}
          </MyListbox.Options>
        </div>
      </MyListbox>
    )
  }
)
