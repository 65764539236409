import React from 'react'
import 'twin.macro'
import { MOD_ROLES } from '../_common/constants'
import { useUser } from '../_common/hooks/useUser'
import { useMessages } from './lib/useMessages'
import { IMessage, Message } from './Message'
import { MessageInput } from './MessageInput'
import { Rain } from './Rain'

export const Chat: React.FC = () => {
  const room = 'en'
  const { messages } = useMessages(room)
  const { user, isAuthenticated } = useUser()
  const isMod = isAuthenticated && MOD_ROLES.includes(user.role)

  return (
    <>
      <Rain />
      <div
        tw="flex-1 overflow-y-auto flex flex-col-reverse relative px-4 hover:pr-3"
        className="hideScroll">
        {messages.map((message: IMessage) => (
          <Message key={message.id} {...message} isMod={isMod} />
        ))}
      </div>
      <MessageInput room={room} />
    </>
  )
}
