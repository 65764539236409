import React from 'react'
import { FormattedMessage } from 'react-intl'
import 'twin.macro'
import { H2 } from '../_common/components'
import { Game } from './Game'
import { DuelMin } from './lib/duels-api'

interface Props {
  title: React.ReactFragment
  games: DuelMin[]
}

export const GamesList: React.FC<Props> = ({ title, games }) => {
  return (
    <section>
      <H2 tw="text-2xl">{title}</H2>
      {games.length ? (
        <div tw="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5">
          {games.map((game) => (
            <Game key={game.id} {...game} />
          ))}
        </div>
      ) : (
        <div tw="py-4 px-3 text-center bg-gainsBgSubNavbar text-gainsBgSubNavbarText">
          <FormattedMessage id="duels.no-active-games" />
        </div>
      )}
    </section>
  )
}
