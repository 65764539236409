import { BetState, CrashBet } from '@crashgg/types/dist'
import styled from '@emotion/styled'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import tw from 'twin.macro'
import { Container } from '../_common/components'
import { Currency } from '../_common/components/Currency'
import { sumKey } from '../_common/utils'
import { ReactComponent as RankingStar } from './assets/ranking-star.svg'
import { Bet } from './Bet'

interface Props {
  bets: CrashBet[]
}

export const Bets: React.FC<Props> = ({ bets }) => {
  const value = sumKey(bets, 'amount')
  const { inProgress, cashedOut } = sortBets(bets)

  return (
    <Container tw="lg:(px-0 mr-2)">
      <div tw="bg-gainsBgChat p-3 rounded-md flex flex-row">
        <div tw="flex-1 text-gainsBgSubNavbarText">
          <FormattedMessage
            id="crash.count-bets"
            values={{ bets: bets.length }}
          />
        </div>
        <Currency value={value} />
      </div>
      {bets.length ? (
        <>
          {!!inProgress.length && (
            <Section>
              <Category>
                <span tw="text-gainsBgSubNavbarText">
                  <FormattedMessage id="crash.bets-in-progress" />
                </span>
              </Category>
              {inProgress.map((bet) => (
                <Bet bet={bet} key={bet.betId} />
              ))}
            </Section>
          )}

          {!!cashedOut.length && (
            <Section>
              <Category>
                <FormattedMessage id="crash.bets-cashed-out" />
              </Category>
              {cashedOut.map((bet) => (
                <Bet bet={bet} key={bet.betId} />
              ))}
            </Section>
          )}
        </>
      ) : (
        <NoBets />
      )}
    </Container>
  )
}

const Category = styled.h3(tw`flex-1 text-white mb-0`)
const Section = styled.section(tw`flex flex-col gap-3 mt-2`)

const NoBets: React.FC = () => (
  <div tw="text-gray-de1 py-16 flex flex-col items-center text-center">
    <RankingStar tw="fill-current text-gainsBgSubNavbarText opacity-50 w-40" />
    <div tw="text-2xl mt-3 mb-1 font-bold">
      <FormattedMessage id="crash.no-bets" />
    </div>
    <div>
      <FormattedMessage id="crash.be-first" />
    </div>
  </div>
)

const sortBets = (bets: CrashBet[]) => {
  const grouped = bets.reduce(
    (grouped, bet) => {
      if (bet.state === BetState.Cashout) grouped.cashedOut.push(bet)
      else grouped.inProgress.push(bet)

      return grouped
    },
    { inProgress: [] as CrashBet[], cashedOut: [] as CrashBet[] }
  )

  return {
    inProgress: grouped.inProgress.sort(amountDesc),
    cashedOut: grouped.cashedOut.sort(amountDesc)
  }
}

const amountDesc = (a: CrashBet, b: CrashBet) => b.amount - a.amount
