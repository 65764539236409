import { Redirect, RouteComponentProps } from '@reach/router'
import React, { useEffect } from 'react'
import 'twin.macro'
import { API } from '../_common/API'
import { noop } from '../_common/utils'

interface Props {
  code: string
}

export const REFLINK_CODE = 'reflink-code'

export const ReflinkPage: React.FC<RouteComponentProps<Props>> = ({ code }) => {
  useEffect(() => {
    if (!code) return

    localStorage.setItem(REFLINK_CODE, code.toUpperCase())

    API.put(
      `/affiliates/${code.toUpperCase()}/click`,
      {},
      { skipAuth: true, skipError: true }
    ).catch(noop)
  }, [code])

  return <Redirect to="/" noThrow />
}
