import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Link } from '@reach/router'
import React from 'react'
import tw from 'twin.macro'
import { SignInButton } from '../_common/components/SignInButton'
import { Avatar, Button } from '../_common/components'
import { useUser } from '../_common/hooks/useUser'
import {
  PrimaryLinks,
  SecondaryLink,
  SecondaryLinks,
  SecondaryLinkExt
} from './Navigation'
import { FormattedMessage } from 'react-intl'
import { ReactComponent as LogoutIcon } from './assets/logout.svg'
import { Balance } from './Balance'
import { depositConversion, lvlFromWager } from '../_common/utils'
import { ReactComponent as DepositArrow } from './assets/deposit-arrow.svg'
import { ReactComponent as WithdrawArrow } from './assets/withdraw-arrow.svg'
import { useOnline } from './useOnline'
import { ReactComponent as Discord } from '../_common/assets/discord.svg'
import { ReactComponent as Twitter } from '../_common/assets/twitter.svg'

export const Desktop: React.FC = () => {
  const { isAuthenticated, user, logout } = useUser()
  const { online } = useOnline()

  const isAdmin = isAuthenticated && user.role === 'admin'

  return (
    <div tw="h-[120px] bg-gainsBgNavbar">
      <PrimaryWrapper aria-label="primary">
        <PrimaryLinks />
        <div tw="flex-1" />
        <div tw="my-auto mr-3">
          {isAuthenticated ? (
            <div tw="flex flex-row items-center">
              <Balance />
              {/* <div tw="ml-3 hidden rounded-tr-lg rounded-bl-lg xxl:block">
                <Balance tickets />
              </div> */}
              <CashierLinks />
              <Profile />
            </div>
          ) : (
            <SignInButton />
          )}
        </div>
      </PrimaryWrapper>

      <div
        tw="h-10 bg-gainsBgSubNavbar flex flex-row items-center gap-5 pl-5 pr-3 text-xs"
        aria-label="secondary">
        <SecondaryLinks />
        {isAdmin && <SecondaryLink to="/admin">Admin panel</SecondaryLink>}
        <div tw="flex-1"></div>

        <div tw="flex flex-row gap-4 uppercase">
          <SecondaryLinkExt href="https://discord.gg/reupgg">
            <Discord />
          </SecondaryLinkExt>
          <SecondaryLinkExt href="https://twitter.com/reupgg">
            <Twitter />
          </SecondaryLinkExt>
        </div>

        {!!online && (
          <div tw="flex flex-row items-center gap-2">
            <div tw="w-2 h-2 flex">
              <div tw="bg-gainsGreen w-2 h-2 rounded-full animate-ping opacity-50 absolute inline-flex" />
              <div tw="bg-gainsGreen w-[6px] h-[6px] m-auto rounded-full relative inline-flex" />
            </div>
            <div tw="leading-3 text-gainsGreen text-[10px] font-semibold uppercase tracking-wider">
              {online} online
            </div>
          </div>
        )}

        {isAuthenticated && (
          <LogoutIcon
            onClick={logout}
            tw="fill-current text-gray-de2 hover:text-gray w-3 cursor-pointer"
          />
        )}
      </div>
    </div>
  )
}

const PrimaryWrapper = styled.nav([
  tw`pl-5 h-20 flex flex-row gap-8`,
  css`
    .link {
      ${tw`border-b-2 px-0 xl:px-0`}
    }
  `
])

const CashierLinks: React.FC = () => (
  <div tw="ml-5 mr-3 xl:mx-8 flex gap-4">
    <Link to="/deposit/rust" onClick={depositConversion}>
      <Button
        color="gains-green"
        tw="px-2 md:px-6 h-[46px] py-0 text-sm flex items-center gap-2">
        <DepositArrow tw="h-4" />
        <span tw="hidden font-bold xl:inline">
          <FormattedMessage id="nav.deposit" />
        </span>
      </Button>
    </Link>

    <Link to="/withdraw/rust">
      <Button
        color="gains-gold"
        tw="px-2 md:px-6 h-[46px] py-0 text-sm flex items-center gap-2">
        <WithdrawArrow tw="h-4" />
        <span tw="hidden font-bold xl:inline">
          <FormattedMessage id="nav.withdraw" />
        </span>
      </Button>
    </Link>
  </div>
)

const Profile: React.FC = () => {
  const { user } = useUser()

  return (
    <Link to="/profile" tw="flex flex-row items-center">
      <div tw="mr-2 text-right hidden xxl:block">
        <div>{user?.name ?? '-'}</div>
        <div tw="text-xs text-gray">
          <FormattedMessage
            id="common.level"
            values={{ level: lvlFromWager(user?.totalWagered ?? 0) }}
          />
        </div>
      </div>
      <Avatar src={user?.avatar ?? ''} />
    </Link>
  )
}
