import React, { useState, useRef } from 'react'
import { useIntl } from 'react-intl' // FormattedMessage,
import { useMutation } from 'react-query'
import 'twin.macro'
import { sendMessage } from './lib/chat-api'
// import { ReactComponent as PlaneIcon } from './assets/plane.svg'
import { ReactComponent as SmileyIcon } from './assets/smiley.svg'
import { MOD_ROLES } from '../_common/constants'
import { useUser } from '../_common/hooks/useUser'
import { notifyError } from '../_common/utils'
import { EMOTES } from './lib/emotes'

interface Props {
  room: string
}

export const MessageInput: React.FC<Props> = ({ room }) => {
  const { user, isAuthenticated } = useUser() // isAuthenticated
  const { formatMessage } = useIntl()
  const [message, setMessage] = useState('')
  const [emotesOpen, setEmotesOpen] = useState(false)

  const inputRef = useRef<HTMLInputElement>(null)

  const sendMutation = useMutation('sendMessage', sendMessage)

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if ((e.nativeEvent as InputEvent).inputType === 'insertLineBreak') {
      return onSend()
    }

    setMessage(e.target.value)
  }

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      return onSend()
    }
  }

  const onSend = () => {
    if (!message.length) return
    if (message.length > 300)
      return notifyError(formatMessage({ id: 'chat.message-too-long' }))

    sendMutation.mutate({ room, message, isMod: MOD_ROLES.includes(user.role) })
    setMessage('')
  }

  const appendEmoji = (code: string) => {
    setMessage(`${message}:${code}:`)
    setEmotesOpen(false)

    inputRef.current?.focus()
  }

  if (!isAuthenticated) return null
  // return (
  //   <div tw="bg-gainsBgSubNavbarHover m-3 p-3 rounded-lg text-center text-gray-el4 text-xs font-semibold">
  //     <FormattedMessage id="chat.login-to-chat" />
  //   </div>
  // )

  return (
    <>
      {emotesOpen && (
        <div tw="bg-gainsBgChat m-3 p-3 flex flex-row flex-wrap gap-3">
          {Object.entries(EMOTES).map(([code, imageUrl]) => (
            <img
              src={imageUrl}
              alt={`:${code}:`}
              title={`:${code}:`}
              tw="cursor-pointer w-[22px] h-[22px] hover:scale-110"
              onClick={() => appendEmoji(code)}
            />
          ))}
        </div>
      )}

      <div tw="bg-gainsBgChat">
        <div tw="flex flex-row items-center">
          <input
            type="text"
            tw="bg-transparent text-white placeholder:text-gainsBgSubNavbarText flex-1 resize-none p-4 py-6 focus:outline-none text-xs font-semibold"
            placeholder={formatMessage({ id: 'chat.your-message-here' })}
            value={message}
            onChange={onChange}
            onKeyDown={onKeyDown}
            ref={inputRef}
          />

          <div
            onClick={() => setEmotesOpen(!emotesOpen)}
            tw="mx-2 w-8 h-8 cursor-pointer bg-gainsBgSubNavbarHover transition duration-150 rounded-xl flex">
            <SmileyIcon tw="m-auto" />
          </div>
          {/* <SmileyIcon
            tw="h-5 mx-2 fill-current text-gray hover:text-gray-el2 cursor-pointer transition duration-150"
            onClick={() => setEmotesOpen(!emotesOpen)}
          /> */}
          {/* <div
            onClick={onSend}
            tw="mx-2 w-8 h-8 cursor-pointer bg-blue bg-opacity-20 hover:bg-opacity-30 transition duration-150 rounded-xl flex">
            <PlaneIcon tw="m-auto" />
          </div> */}
        </div>
      </div>
    </>
  )
}
