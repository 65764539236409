import { GameState, Millis } from '@crashgg/types/dist'
import { State } from './useCrash'

export const growthFn = (ms: Millis) => {
  const r = 0.00006
  return ~~(100 * Math.pow(Math.E, r * ms)) / 100
}

const TITLES: Partial<Record<GameState, string>> = {
  [GameState.Betting]: 'Betting',
  [GameState.Blocked]: 'Starting',
  [GameState.Ended]: 'Crashed',
  [GameState.Paused]: 'Paused'
}

export const getTitle = ({ state, at }: State) => {
  return TITLES[state] || `x${at.toFixed(2)}`
}
