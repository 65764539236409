import { useEffect } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { WS } from '../../_common/WS'
import { IMessage } from '../Message'
import { getMessages } from './chat-api'

export const useMessages = (room: string) => {
  const messages = useQuery(['chat', room], async () => getMessages(room), {
    staleTime: Infinity
  })

  const queryClient = useQueryClient()

  useEffect(() => {
    const onMessage = (ev: CustomEvent) => {
      queryClient.setQueryData(['chat', ev.detail.room], (old: any) => {
        return [ev.detail].concat((old || []).slice(0, 19))
      })
    }

    const onRemove = (ev: CustomEvent) => {
      queryClient.setQueryData(['chat', ev.detail.room], (old: any) => {
        const idx = old.findIndex((msg: IMessage) => msg.id === ev.detail.id)
        if (idx !== -1) old[idx] = ev.detail

        return old
      })
    }

    WS.on('chat:message', onMessage)
    WS.on('chat:remove', onRemove)

    return () => {
      WS.off('chat:message', onMessage)
      WS.off('chat:remove', onRemove)
    }
  }, [queryClient])

  return {
    messages: messages.data || [],
    isLoading: messages.isLoading
  }
}
