import styled from '@emotion/styled'
import { Link } from '@reach/router'
import React from 'react'
import tw from 'twin.macro'

interface Props {
  to: string
  image: React.ReactChild
  name: string
  lead: string
}

export const MethodCard: React.FC<Props> = ({ to, image, name, lead }) => {
  return (
    <Card to={to} className="group">
      <Image>
        <div tw="m-auto group-hover:scale-110 transition duration-200">
          {image}
        </div>
      </Image>
      <Desc>
        <Name>{name}</Name>
        <Lead>{lead}</Lead>
      </Desc>
    </Card>
  )
}

const Card = styled(Link)(
  tw`bg-dark-el2 w-40 h-48 rounded-md overflow-hidden flex flex-col cursor-pointer`,
  tw`transition duration-150 hover:bg-dark-el3 hover:ring-1 ring-dark-el1`
)
const Image = styled.div(tw`h-28 bg-dark-el1 flex transition duration-150`)
const Desc = styled.div(tw`m-auto text-center`)
const Name = styled.div(tw`text-lg font-bold`)
const Lead = styled.div(tw`text-green text-sm tracking-wider`)
