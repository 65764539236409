import React from 'react'
import tw from 'twin.macro'
import { Currency } from '../_common/components/Currency'
import { getItemImage } from '../_common/utils'
import { Props } from './Item'

export const ItemCompact: React.FC<Props> = ({
  name,
  price,
  imageUrl,
  isAccepted = true,
  isSelected,
  onSelect
}) => {
  const onClick = () => isAccepted && onSelect && onSelect()

  return (
    <div
      css={[
        tw`w-auto max-w-[15rem] lg:max-w-none px-2 py-2 bg-gainsBgChat flex flex-row flex-shrink-0 items-center rounded-md gap-3 transition duration-150`,
        isSelected && tw`bg-gainsBgSubNavbarHover`,
        isAccepted ? tw`cursor-pointer` : tw`cursor-not-allowed opacity-50`
      ]}
      onClick={onClick}>
      <img src={getItemImage(imageUrl)} alt="" tw="w-10" />

      <div tw="mr-2 overflow-hidden">
        <div tw="text-gainsBgSubNavbarText text-sm truncate mb-1">{name}</div>
        <Currency value={price || 0} size="small" />
      </div>
    </div>
  )
}
