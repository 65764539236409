import ReconnectingWebSocket from 'reconnecting-websocket'

type Listener = (name: string, handler: (ev: CustomEvent) => void) => void

/**
 * @example
 * WS.on('online', ev => console.log(ev.detail))
 */
export class WSClient extends EventTarget {
  private _currentAccessToken?: string
  private _ws: ReconnectingWebSocket
  on: Listener
  off: Listener

  constructor(private url: string) {
    super()

    this._ws = new ReconnectingWebSocket(this.url)
    this._ws.addEventListener('message', (message) => {
      const [op, payload] = JSON.parse(message.data)
      const event = new CustomEvent(op, { detail: payload })

      this.dispatchEvent(event)
    })
    this._ws.addEventListener('open', () => {
      if (this._currentAccessToken) {
        this.send(['auth', this._currentAccessToken])
      }
    })
    this._ws.addEventListener('close', () => {
      this.dispatchEvent(new CustomEvent('disconnect'))
    })

    this.on = this.addEventListener.bind(this) as Listener
    this.off = this.removeEventListener.bind(this) as Listener
  }

  login(accessToken: string) {
    if (this._currentAccessToken === accessToken) return

    this._currentAccessToken = accessToken
    this.send(['auth', accessToken])
  }

  send(payload: [string, unknown]) {
    this._ws.send(JSON.stringify(payload))
  }
}

export const WS = new WSClient(process.env.REACT_APP_WS_URL as string)
export const WSCrash = new WSClient(
  process.env.REACT_APP_WS_CRASH_URL as string
)
