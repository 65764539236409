import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useMutation, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'
import tw from 'twin.macro'
import {
  ActionButton,
  ErrPretty,
  H2,
  Input,
  Modal,
  ModalProps,
  P
} from '../_common/components'
import { Currency } from '../_common/components/Currency'
import { useAffiliateCode } from './lib/affiliates'
import { REFLINK_CODE } from './ReflinkPage'

export const ClaimAffiliateModal: React.FC<ModalProps> = (props) => {
  const { formatMessage } = useIntl()

  const [code, setCode] = useState(localStorage.getItem(REFLINK_CODE) || '')

  const queryClient = useQueryClient()
  const mutation = useMutation(useAffiliateCode, {
    onSuccess: () => {
      queryClient.invalidateQueries('user')
      toast.success(
        formatMessage(
          { id: 'free-coins.claimed' },
          { code: code.toUpperCase() }
        )
      )
      props.close()
    }
  })

  return (
    <Modal {...props} styles={tw`px-5 py-5 max-w-md`}>
      <H2>
        <FormattedMessage id="free-coins.title" />
      </H2>
      <P tw="text-sm">
        <FormattedMessage
          id="free-coins.desc"
          values={{
            coins: (
              <span tw="text-white ml-[2px]">
                <Currency size="xs" value={50} /> coins
              </span>
            ),
            code: <span tw="text-white">REUP</span>
          }}
        />
      </P>

      <div tw="flex justify-center gap-2 mt-5 mb-2">
        <Input
          type="text"
          tw="bg-dark"
          placeholder={formatMessage({ id: 'deposit.enter-code' })}
          value={code}
          onChange={(e) => setCode(e.target.value)}
        />
        <ActionButton mutation={mutation} payload={code} color="primary">
          <FormattedMessage id="common.redeem" />
        </ActionButton>
      </div>
      <ErrPretty error={mutation.error} />

      <P tw="text-sm mt-10 text-gray-el1">
        <FormattedMessage id="free-coins.requirements" />
      </P>
    </Modal>
  )
}
