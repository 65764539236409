import React from 'react'
import 'twin.macro'
import { AOut, H2, P } from '../../_common/components'

export const Crash: React.FC = () => {
  return (
    <section>
      <H2>Crash</H2>
      <P>To generate a crashing point for a game, we use two inputs:</P>
      <P tw="mt-3">
        1 &mdash; The "Server seed", one of 10,000,000 pregenerated SHA256
        hashes creating a chain - each element is a hash of the lowercase,
        hexadecimal string representation of the previous hash.
      </P>
      <P tw="mt-3">
        2 &mdash; The "Client seed", a random string we were not aware of when
        generating the Server seed chain. We chose the hash of Bitcoin block
        707,777 which is
        <code tw="text-sm ml-1">
          00000000000000000009e2a08a78ba29b737a4d5209aa48f7993d17229b16ef7
        </code>
        .
      </P>
      <P tw="mt-3">
        You can learn more about this system
        <AOut
          href="https://twitter.com/CrashGG_/status/1455547709788721153"
          target="_blank"
          rel="noopener noreferrer">
          in our tweet
        </AOut>
        .
      </P>
      <P tw="mt-3">
        Both inputs are combined to generate a crashing point. You can verify
        outcome of any finished game by following the instructions below:
      </P>
      <ol tw="list-decimal list-inside mt-2 text-gray-el4">
        <li>Click on a multiplier in the recent crash history.</li>
        <li>Obtain the Server and Client seed.</li>
        <li>
          Open our
          <AOut href="https://onecompiler.com/nodejs/3ycbnp63z" tw="mx-2">
            fairness verification algorithm
          </AOut>
          and insert the values, then click Run.
        </li>
        <li>
          Ensure that the crash point is correct, and the output hash matches
          Server seed of the previous game.
        </li>
      </ol>
    </section>
  )
}
