import { RouteComponentProps } from '@reach/router'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import 'twin.macro'
import { Duels } from './Duels'
import { Container, H1, P } from '../../_common/components'
import { useTitle } from '../../_common/hooks/useTitle'
import { Crash } from './Crash'
import { Serials } from './Serials'
import { Blackjack } from './Blackjack'

export const FairnessPage: React.FC<RouteComponentProps> = () => {
  useTitle('Provably Fair')

  return (
    <Container tw="py-5 md:(pt-10 pb-32) max-w-4xl">
      <div id="bg" />
      <H1>
        <FormattedMessage id="fairness.title" />
      </H1>
      <P tw="text-base">
        <FormattedMessage id="fairness.description" />
      </P>

      <div tw="mt-10">
        <Crash />
      </div>

      <div tw="mt-10">
        <Blackjack />
      </div>

      <div tw="mt-10">
        <Duels />
      </div>

      <div tw="mt-10">
        <Serials />
      </div>
    </Container>
  )
}
