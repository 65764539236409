import React from 'react'
import tw from 'twin.macro'
import { UserBet } from './lib/useCrash'
import bg from './assets/upgrade-bg.svg'
import logo from './assets/logo.svg'
import underlay from './assets/underlay.png'
import overlayLose from './assets/overlay-lose.svg'
import overlayCashout from './assets/overlay-cashout.svg'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { FormattedMessage } from 'react-intl'
import { BetState, Float, GameState, Int } from '@crashgg/types/dist'
import { CurrencyUsd } from '../_common/components/Currency'
import { fadeIn, shake, zoomedSpin, zoomIn } from './lib/animations'

interface Props {
  bet: UserBet | undefined
  cashout: (betId?: Int) => void
  gameState: GameState
  isCenter?: boolean
  dynamicAt: Float
  at: Float
}

export const Upgrade: React.FC<Props> = ({
  bet,
  cashout,
  gameState,
  at,
  dynamicAt,
  isCenter
}) => {
  return (
    <div tw="flex flex-1 flex-col items-center w-full max-w-xs lg:max-w-sm xl:max-w-none">
      <Square
        state={bet?.state || gameState}
        onClick={() => cashout(bet?.betId)}>
        <img className="underlay" src={underlay} alt="" />
        {isCenter && !bet?.items?.length && (
          <img className="logo" src={logo} alt="" />
        )}
        <img className="lost" src={overlayLose} alt="" />
        <img className="cashout" src={overlayCashout} alt="" />

        <div>
          {bet ? (
            <>
              <div tw="flex items-center">
                {!!bet.items.length && (
                  <img
                    src={bet.items[0][2]}
                    alt={bet.items[0][1]}
                    key={bet.items[0][1]}
                    tw="w-16 h-16 md:(w-32 h-32) lg:(w-20 h-20) xl:(w-40 h-40) mb-10 transition duration-150"
                    css={css`
                      animation: 500ms ${zoomIn} ease;
                    `}
                  />
                )}
              </div>

              {!!bet.autoCashoutAt && (
                <div tw="absolute top-[10%] z-0 text-center text-gray-el2 text-xs">
                  Auto: x{bet.autoCashoutAt.toFixed(2)}
                </div>
              )}

              <div tw="absolute bottom-[10%] z-0 text-center">
                <div tw="text-2xl font-bold tracking-wide">
                  <CurrencyUsd
                    value={
                      ~~(
                        bet.amount *
                        (bet.cashedOutAt || Math.max(dynamicAt, at))
                      )
                    }
                  />
                </div>
                <div tw="text-gray-el4">
                  <CurrencyUsd value={bet.amount} />
                </div>
              </div>
            </>
          ) : gameState === GameState.Betting ? (
            <div tw="text-gray-el4 text-center">
              <FormattedMessage id="crash.upgrade-place-bet" />
            </div>
          ) : gameState === GameState.InProgress ? (
            <div tw="text-gray-el4 text-center">
              <FormattedMessage id="crash.upgrade-upgrading" />
            </div>
          ) : (
            gameState === GameState.Ended && (
              <div tw="text-red-crash text-center">
                <FormattedMessage id="crash.upgrade-ended" values={{ at }} />
              </div>
            )
          )}
        </div>
      </Square>

      <div tw="mt-5 h-16">
        {bet?.items?.map(([price, name], idx) => (
          <div tw="flex my-1 items-center justify-center gap-2" key={idx}>
            {/* <div tw="bg-blue w-2 h-2 rounded-full"></div> */}
            <div tw="text-gray-el4">{name}</div>
            <div tw="font-bold">
              <CurrencyUsd value={price} />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

interface SquareProps {
  state: BetState | GameState
}

const Square = styled.div<SquareProps>(({ state }) => [
  tw`w-full max-w-[18rem] relative transition duration-150`,
  css`
    background: url(${bg}) no-repeat;
    background-size: cover;
    border-radius: 30%;
    transform-style: preserve-3d;

    &:after {
      content: '';
      ${tw`block pb-[108%]`}
    }

    & > div {
      ${tw`absolute w-full h-full flex items-center justify-center`}
    }

    & > img {
      ${tw`hidden z-10 absolute inset-0 w-full h-full select-none`}
    }

    .underlay {
      z-index: -10 !important;
      ${tw`block`}
      animation: 7.5s ${zoomedSpin} infinite;
      animation-timing-function: linear;
      transition: opacity 1s;
    }

    .logo {
      z-index: -5;
      ${tw`block scale-75 opacity-10`}
    }
  `,
  state === BetState.Active &&
    css`
      &:hover .cashout {
        ${tw`block`}
      }
      &:hover .underlay {
        filter: hue-rotate(-0.19turn);
        z-index: -1;
      }
    `,
  state === BetState.Lost &&
    css`
      .lost {
        ${tw`block`}
        animation: 200ms ${fadeIn} ease;
      }
    `,
  state === GameState.Betting &&
    css`
      .underlay {
        opacity: 0;
      }
    `,
  state === BetState.Cashout &&
    css`
      .cashout {
        ${tw`block`}
        animation: 300ms ${fadeIn} ease;
      }

      .underlay {
        filter: hue-rotate(-0.19turn);
      }
    `,
  state === BetState.Active && tw`cursor-pointer hover:scale-105 hover:z-50`,
  [BetState.Lost, GameState.Ended].includes(state) &&
    css`
      animation: 300ms ${shake} ease;
      .underlay {
        filter: hue-rotate(-0.63turn);
      }
    `
])
