import { Card as TCard, HandStatus, Int } from '@crashgg/types/dist'
import React from 'react'
import tw from 'twin.macro'
import { Card } from './Card'
import { humanHandValue } from './lib/cards'

interface Props {
  cards: (TCard | [-1])[]
  status?: HandStatus
  gameId: Int
  drawn: Int
  reveal2Card?: boolean
  isCurrent?: boolean
}

export const Hand: React.FC<Props> = ({
  cards,
  status,
  gameId,
  drawn,
  reveal2Card,
  isCurrent
}) => {
  return (
    <div tw="py-2 lg:py-5 flex flex-col items-center lg:gap-3 px-[15%]">
      <div tw="bg-dark-el1 text-sm rounded-xl px-3 py-1 font-bold inline-block z-10 shadow-lg">
        {humanHandValue(cards, drawn, reveal2Card)}
      </div>

      <div
        tw="relative h-16 ml-[-5.5rem] lg:(h-32 -ml-44)"
        css={!isCurrent && tw`opacity-70`}>
        {cards.map(
          (card, i) =>
            i < drawn && (
              <Card
                card={card}
                isRevealed={card[0] !== -1 && !!(i !== 1 || reveal2Card)}
                idx={i}
                key={[gameId, i].join()}
                status={status}
              />
            )
        )}
      </div>
    </div>
  )
}
