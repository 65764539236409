export const enUS: Record<string, string> = {
  'common.at-time': 'at {date, date, ::hhmm}',
  'common.out-of': '{i} out of {total}',
  'common.save': 'Save',
  'common.redeem': 'Redeem',
  'common.go-back': 'Back',
  'common.date': 'Date',
  'common.amount': 'Amount',
  'common.game': 'Game',
  'common.result': 'Result',
  'common.profitloss': 'Profit/Loss',
  'common.action': 'Action',
  'common.level': 'Level {level}',
  'common.click-here': 'Click here',
  'common.refresh': 'Refresh',
  'common.sign-in-compact': 'Login',
  'common.sign-in-steam': 'Login via Steam',
  'common.bet-amount': 'Bet Amount',
  'common.players': 'Players',
  'common.redirecting': 'Redirecting...',
  'common.empty-here': 'A bit empty here...',
  'common.provably-fair': 'Provably Fair',
  'common.by-random.org': 'powered by random.org',
  'common.how-to-use': 'How do I use it?',
  'common.place-bet': 'Place bet',
  'common.verify': 'Verify now',
  'common.mode': 'Mode',
  'common.user': 'User',
  'common.time': 'Time',
  'nav.profile': 'Your Profile',
  'nav.faq': 'FAQ',
  'nav.provably-fair': 'Provably Fair',
  'nav.tos': 'Terms of Service',
  'nav.privacy': 'Privacy Policy',
  'nav.crash': 'Crash',
  'nav.duels': 'Duels',
  'nav.blackjack': 'Blackjack',
  'nav.lottery': 'Lottery',
  'nav.logout': 'Logout',
  'nav.affiliates': 'Affiliates',
  'nav.free-coins': 'REDEEM CODE',
  'nav.promo-code': 'PROMO CODE',
  'nav.deposit': 'Deposit',
  'nav.withdraw': 'Withdraw',
  'faq.title': 'Frequently Asked Questions',
  'faq.general': 'General',
  'faq.crash': 'Crash',
  'faq.duels': 'Dice Duels',
  'faq.lottery': 'Lottery',
  'faq.support.q': 'I need to contact support, where can I do it?',
  'faq.support.a':
    'Please join our Discord server and create a support ticket. We are always happy to help you.',
  'faq.bug.q': 'I have found a bug/exploit, what should I do?',
  'faq.bug.a':
    'Please create a ticket in our Discord server. If the bug/exploit is major, you will be rewarded.',
  'faq.sponsorship.q': 'How do I enquire about a sponsorship?',
  'faq.sponsorship.a':
    'If you create gambling or Rust content, we may be interested in partnering with you. Please open a ticket in our Discord server and show us your social media profiles.',
  'faq.crash-how.q': 'How does the crash game work?',
  'faq.crash-how.a':
    'You can place up to 3 bets per game. The game crashes on a random multiplier. If you cashout before the game ends, your bet will be multiplied by the value you cashed out at.',
  'faq.cashout-lost.q': 'I clicked cashout and lost. Why?',
  'faq.cashout-lost.a':
    "There is a network latency between you and the game server, meaning your request may arrive after the game finishes. We suggest setting an auto-cashout so it doesn't happen again.",
  'faq.cashout-auto.q': 'My bet has been automatically cashed out. Why?',
  'faq.cashout-auto.a':
    'If you ever get disconnected mid-game, your bets will be automatically cashed out. It is also possible that we cashout all bets in a given round if the winnings reach "max win" value, displayed in the top right corner.',
  'faq.crash-items.q': 'I won, where are my items?',
  'faq.crash-items.a':
    'We use a balance system - items are displayed purely for visualization purposes. You can buy items using coins on the withdraw page.',
  'faq.crash-less.q': 'I received less tickets or wager than I should?',
  'faq.crash-less.a':
    'To prevent abuse, all games cashed out at x1.49 or lower yield less tickets and wager. For example, a 10 coin bet cashed out at x1.20 will count as 2 coin wager and therefore yield 20 tickets.',
  'faq.duels-how.q': 'How do Dice Duels work?',
  'faq.duels-how.a':
    'Dice Duels are an unique Player vs Player gamemode. You bet against up to 3 players or bots. Every player rolls a random number between 0 and 99.99. Player with the highest number wins the entire pot.',
  'faq.duels-tax.q': 'I won. Why did I not receive the full amount?',
  'faq.duels-tax.a':
    'Every Dice Duel is taxed 5% of the total win, or 7.5% if bots are called. The amount defined as "Winnings" is the amount winner receives. The fee allows us to operate the site.',
  'faq.lottery-how.q': 'How does lottery work?',
  'faq.lottery-how.a':
    'The lottery is our unique system of rewarding active players. Users can spend their tickets to draw numbers, much like regular lottery. Winners are drawn everyday. Everyone has a chance to win a jackpot!',
  'faq.lottery-tickets.q': 'How do I get tickets?',
  'faq.lottery-tickets.a':
    'You can earn tickets by playing on the site. You get approximately 10 tickets for every coin wagered.',
  'faq.lottery-when.q': 'When will the lottery start?',
  'faq.lottery-when.a':
    'We are a relatively new site, and we would like to start the lottery with big prizes and lots of winners. The lottery will launch once we establish a stable playerbase and users accumulate enough tickets.',
  'faq..q': '',
  'faq..a': '',
  'chat.your-message-here': 'Type your message here...',
  'chat.message-too-long': 'Message cannot exceed 300 characters',
  'chat.login-to-chat': 'Please login in order to chat',
  'rain.incoming': 'Rain is coming',
  'rain.active': "It's raining!",
  'rain.ends-in': 'Showers in {mm}:{ss}',
  'rain.join': 'Join Rain',
  'rain.reward':
    'You have received {reward, number, ::currency/USD scale/0.01} from rain',
  'rain.about':
    'Rain is a feature that rewards active players. Every 30 minutes, we distribute the pot among everyone who completes a captcha. The higher your level, the more you receive!',
  'rain.requirements':
    'In order to join, you must be {level} or higher and have {tag} in your steam name.',
  'rain.joined': 'Successfully joined rain!',
  'rain.not-active': 'Rain can be joined 2 minutes before finish.',
  'crash.history-title': 'Crash game #{id}',
  'crash.history-desc':
    'This game crashed at {at}. You can verify game fairness on our Provably Fair page by using the details below.',
  'crash.game-seed': 'Game seed',
  'crash.client-seed': 'Client seed',
  'crash.upgrade': 'Place bet',
  'crash.in-progress': 'In progress...',
  'crash.upgrade-place-bet': 'Place a bet to Upgrade',
  'crash.upgrade-upgrading': 'Upgrading...',
  'crash.upgrade-ended': 'Crashed at x{at}',
  'crash.details':
    'Max win: {maxWin, number, ::currency/USD scale/0.01} {br} Ping: {ping, number}ms',
  'crash.cashout': 'Cashout all',
  'crash.auto-cashout': 'Auto Cashout',
  'crash.potential-payout': 'Potential Payout',
  'crash.count-bets':
    '{bets, plural, =0 {No bets} =1 {1 bet} other {# bets}} in progress...',
  'crash.no-auto': 'No auto cashout',
  'crash.cashout-at': 'Cashout at x{at}',
  'crash.bets-in-progress': 'In progress',
  'crash.bets-cashed-out': 'Cashed out',
  'crash.no-bets': 'No bets in progress',
  'crash.be-first': 'Be the first to play',
  'crash.quick-bets': 'Quick Bets',
  'item-page.not-selected': 'No items selected',
  'item-page.selected-count': 'items selected',
  'item-page.search': 'Serch for an item...',
  'profile.joined': 'Joined {when, date, medium}',
  'profile.account': 'Account',
  'profile.settings': 'Settings',
  'profile.history': 'History',
  'profile.betting-history': 'Betting History',
  'profile.total-deposits': 'Total Deposits',
  'profile.total-withdrawals': 'Total Withdrawals',
  'profile.total-wagered': 'Total Wagered',
  'profile.next-level-wager': `Wager {wager, number, ::currency/USD scale/0.01} more to level up`,
  'profile.level-wager': `Level {level, number} - {wager, number, ::currency/USD scale/0.01}`,
  'profile.no-bets': 'No bets found',
  'settings.trade-url': 'Trade URL',
  'settings.trade-url-required': 'Please enter your Trade URL',
  'settings.trade-url-desc':
    'We need your Trade URL to send you tradeoffers on Steam. {clickHere} to find your trade link.',
  'settings.trade-url-set': 'Trade URL set!',
  'settings.linked-accounts': 'Linked Accounts',
  'settings.account-id': 'Account ID: {id}',
  'settings.not-linked': 'Account not linked',
  'settings.server-not-joined': 'Server not joined',
  'settings.server-joined': 'Joined our server',
  'settings.link': 'Link',
  'settings.interface': 'Interface Settings',
  'settings.volume': 'Sound Volume',
  'deposit.title': 'Deposit funds',
  'deposit.items': 'Steam items',
  'deposit.fiat': 'Real money',
  'deposit.crypto': 'Cryptocurrencies',
  'deposit.bonuses': 'Redeem bonuses',
  'deposit.instant': 'Instant',
  'deposit.bonus': '+{bonus, number, ::percent} bonus',
  'deposit.free': 'Free coins',
  'deposit.items.title': 'Deposit items',
  'deposit.items.action': 'Deposit',
  'deposit.items.action-desc': 'You will receive',
  'deposit.items.empty':
    "Looks like you don't own any Rust items matching the criteria",
  'deposit.skinsback.header': 'Deposit CS:GO or Dota 2 Skins',
  'deposit.skinsback.info':
    'CS:GO and Dota 2 skin deposits are handled by a third-party provider SkinsBack. You will be redirected to complete the transaction.',
  'deposit.skinsback.cta': 'Pay via SkinsBack',
  'deposit.crypto.title': 'Deposit {name}',
  'deposit.crypto.desc':
    'This is your permament {name} address. Send any amount to receive on-site balance. You will be credited when the transaction receives {confirms} confirmations. Incoming transactions can be tracked {explorer}.',
  'deposit.crypto.explorer': 'on the blockchain explorer',
  'deposit.promo-code.title': 'Redeem Promo Codes',
  'deposit.promo-code.desc':
    'Each code can be used once per account. Some codes have an on-site level requirement. Promo Codes are regurarly shared on our Discord and Twitter, so be sure to follow us there!',
  'deposit.giftcard.title': 'Deposit via Gift Cards',
  'deposit.giftcard.desc':
    'You can buy official REUP.GG giftcards in online shops such as Kinguin. A 40% bonus applies, so if you buy a $100 giftcard, you will receive 140 coins. Enter the code below to redeem on-site balance.',
  'deposit.giftcard.buy': 'Buy Gift Cards',
  'deposit.giftcard.buy-desc':
    'Click on a giftcard below to view the official shop listing',
  'deposit.success':
    'Deposit of {amount, number, ::currency/USD scale/0.01} completed',
  'deposit.redeemed':
    'Successfully redeemed {amount, number, ::currency/USD scale/0.01}',
  'deposit.enter-code': 'Enter code here',
  'withdraw.title': 'Withdraw',
  'withdraw.action': 'Withdraw',
  'withdraw.action-desc': 'You will spend',
  'withdraw.items.empty': 'Shop will be restocked shortly',
  '404.header': 'Error 404',
  '404.description': 'The page you were looking for does not exist',
  '404.go-home': 'Go home ↗',
  'protected.header': 'Not authorized',
  'protected.description': 'This page can only be viewed by authorized users',
  'lottery.soon': 'Coming Soon',
  'lottery.soon-desc':
    'The lottery is our innovative reward system. It was designed to give back to our active players.',
  'lottery.soon-desc-2':
    'A fraction of all bets goes into the lottery pool. Users can spend their tickets for a chance to win.',
  'lottery.soon-tickets': 'You have {tickets} tickets',
  'lottery.soon-pool': 'Pool is currently {pool} coins',
  'lottery.tickets-entered': '{tickets} tickets bought this round',
  'fairness.title': 'Provably Fair',
  'fairness.description':
    'When using our site, you can be sure that all games are fair and truly random. Our systems prove that it is impossible to alter the results of any game. Below you can verify all outcomes yourself.',
  'free-coins.title': 'Free Coins',
  'free-coins.desc':
    "Use an affiliate code and get free {coins}. If you don't have a code, use {code}. The person who referred you will receive a fraction of your bets back.",
  'free-coins.requirements':
    'In order to claim a referral code you must own Rust and play at least 5 hours in total. Make sure your game history is public.',
  'free-coins.claimed': 'Claimed code {code}!',
  'affiliates.title': 'Affiliates',
  'affiliates.desc':
    'Refer new users and earn a fraction of their bets. Create an affiliate code and share it. New users get 0.50 coins for free and you get 0.5% of their bets. Blackjack bets give 0.1%.',
  'affiliates.users': 'Users Referred',
  'affiliates.all-time': 'All Time Earnings',
  'affiliates.claimable': 'Claimable Earnings',
  'affiliates.set-code': 'Set code',
  'affiliates.claim-earnings': 'Claim earnings',
  'affiliates.claimed-earnings': `Successfully claimed {amount, number, ::currency/USD scale/0.01}!`,
  'error.unexpected': 'Unexpected error encountered',
  'error.reload': 'Please reload the page',
  'error.wager_requirement_unmet':
    'You have to wager 10 coins in order to wihdraw',
  'error.trade_failed':
    'Failed to send offer. Make sure your inventory is public and you are allowed to trade.',
  'error.steam_down': 'Could not load inventory, make sure it is public.',
  'duels.header': 'Dice Duels',
  'duels.active-games': 'Active games',
  'duels.history-games': 'Recent games',
  'duels.no-active-games': 'There are no active games. Be the first to play!',
  'duels.game-title.hi': 'Dice Duel',
  'duels.game-title.lo': 'Cursed Duel',
  'duels.state.open': 'Open',
  'duels.state.in-progress': 'In progress...',
  'duels.state.finished': 'Finished',
  'duels.rules.hi': 'Highest roll wins',
  'duels.rules.lo': 'Lowest roll wins',
  'duels.stats.cost': 'Cost',
  'duels.stats.winnings': 'Winnings',
  'duels.stats.players': 'Players',
  'duels.waiting-for-players': 'Waiting for players...',
  'duels.winner': 'Winnings of {winnings} credited',
  'duels.chance': 'Chance: {chance}%',
  'duels.call-bots': 'Call bots',
  'duels.hero-won': 'Has won by rolling {roll}',
  'duels.bot-tax': 'Games involving bots are taxed at 7.5% instead of 5%',
  'lottery.buy-tickets': 'Buy tickets',
  'lottery.ends-in': 'Ends in',
  'lottery.live-tickets': 'Live Tickets Feed',
  'lottery.total-tickets': 'Total Tickets',
  'lottery.your-tickets': 'Your Tickets',
  'lottery.win-chance': 'Match 6 Chance',
  'lottery.jackpot-notice':
    'True only if chosen numbers do not repeat, which is not guaranteed when buying tickets multiple times',
  'lottery.tickets-bought': 'Tickets Bought',
  'lottery.how-it-works': 'How it Works',
  'lottery.get-tickets.title': 'Get Tickets',
  'lottery.get-tickets.desc':
    'Get approximately 10 tickets for each coin you wager, or buy tickets with your coins.',
  'lottery.enter-draw.title': 'Enter the Draw',
  'lottery.enter-draw.desc':
    'Spend your regular tickets on lottery tickets for a chance to win. Wait for the lottery to end.',
  'lottery.win.title': 'Check your Winnings',
  'lottery.win.desc':
    'When the round is over, check and claim your winnings. Everyone has a chance to hit the jackpot.',
  'lottery.criteria.title': 'The winning criteria',
  'lottery.criteria.desc':
    'Every lottery ticket has 6 digits from 0-9. Digits are chosen at random by your browser when joining the lottery. Tickets are checked in order from left to right.',
  'lottery.buy-tickets.title': 'Buy lottery tickets',
  'lottery.previous': 'Previous Lottery Rounds',
  'lottery.unclaimed':
    'You have won {amount, number, ::currency/USD scale/0.01} from the lottery!',
  'lottery.prize-pool': 'Prize Pool',
  'lottery.winning-numbers': 'Winning Numbers',
  'lottery.winners': 'Top Round Winners'
}
