import React, { useState } from 'react'
import 'twin.macro'
import { API } from '../../_common/API'
import { Button, H2, Input, P } from '../../_common/components'
import { getRandomOrgUrl } from '../../_common/utils'

export const Serials: React.FC = () => {
  const [id, setId] = useState('')

  const verify = async () => {
    try {
      const serial = await API.get(`/fairness/serial/${id}`).catch(() => null)

      if (!serial) return
      const url = getRandomOrgUrl(serial.random)
      if (url) window.open(url, '_blank')
    } catch (err: any) {}
  }

  return (
    <section>
      <H2>Random.org Serials</H2>
      <P>
        While random.org results are truly random, it is technically possible to
        request outcomes multiple times and choose the result in one's favour.
        To prove that we do not attempt to choose a favourable outcome, we
        provide a database of all random.org responses along with their serial
        numbers.
      </P>
      <P>
        Feel free to check a few serial IDs before and after your game. By
        comparing creation dates, serial IDs and "User Data", you can verify
        that only one outcome per game is generated.
      </P>

      <div tw="mt-5">
        <Input
          type="number"
          placeholder="Serial ID"
          tw="bg-dark-el2"
          value={id}
          onChange={(e) => setId(e.target.value)}
        />
        <Button color="primary" tw="ml-2" onClick={verify}>
          Verify serial
        </Button>
      </div>
    </section>
  )
}
