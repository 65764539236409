import { Int } from '@crashgg/types/dist'
import { useReducer } from 'react'

export interface Props<T> {
  items: T[]
  idKey: keyof T
  maxItems?: Int
}

export type Key = string | number | symbol

export interface State<T> {
  isSelected: Record<Key, boolean>
  selectedItems: T[]
}

export const useSelect = <T>({
  items,
  idKey,
  maxItems = Infinity
}: Props<T>) => {
  const [state, dispatch] = useReducer(
    (state: State<T>, action: any): State<T> => {
      switch (action.type) {
        case 'toggle': {
          const isNowSelected = !state.isSelected[action.id]

          if (isNowSelected && state.selectedItems.length >= maxItems) {
            return { ...state }
          }

          const selectedItems = isNowSelected
            ? [
                ...state.selectedItems,
                items.find((item) => item[idKey] === action.id) as T
              ]
            : state.selectedItems.filter((item) => item[idKey] !== action.id)

          return {
            selectedItems,
            isSelected: {
              ...state.isSelected,
              [action.id]: isNowSelected
            }
          }
        }
        case 'reset':
          return {
            selectedItems: [],
            isSelected: {}
          }
        default:
          throw new Error()
      }
    },
    { isSelected: {}, selectedItems: [] }
  )

  const toggleItem = (id: Key) => dispatch({ type: 'toggle', id })
  const reset = () => dispatch({ type: 'reset' })

  return { ...state, toggleItem, reset }
}
