import React from 'react'
import { FormattedMessage } from 'react-intl'
import tw from 'twin.macro'
import { DuelMin } from './lib/duels-api'
import { ReactComponent as Ticket } from '../_common/assets/ticket.svg'
import { ReactComponent as Crown } from './assets/crown.svg'
import { ReactComponent as UserGroup } from './assets/user-group.svg'
import styled from '@emotion/styled'
import { Currency } from '../_common/components/Currency'
import { css } from '@emotion/react'

interface Props {
  game: DuelMin
}

export const Stats: React.FC<Props> = ({ game }) => {
  return (
    <div tw="flex flex-row justify-between md:(grid grid-cols-3) px-[3%] pb-3">
      <StatWrapper>
        <StatLabel>
          <FormattedMessage id="duels.stats.cost" />
        </StatLabel>
        <StatLabelIcon>
          <Ticket tw="h-4 -rotate-45" />
        </StatLabelIcon>
        <StatValue>
          <Currency value={game.betAmount} styles={tw`font-normal`} />
        </StatValue>
      </StatWrapper>

      <StatWrapper tw="justify-center">
        <StatLabel>
          <FormattedMessage id="duels.stats.winnings" />
        </StatLabel>
        <StatLabelIcon>
          <Crown tw="h-4" />
        </StatLabelIcon>
        <StatValue>
          <Currency value={game.winnings} styles={tw`font-normal`} />
        </StatValue>
      </StatWrapper>

      <StatWrapper tw="justify-end">
        <StatLabel>
          <FormattedMessage id="duels.stats.players" />
        </StatLabel>
        <StatLabelIcon>
          <UserGroup tw="h-3" />
        </StatLabelIcon>
        <StatValue>
          <span tw="text-lg">{game.players.length}</span>
          <span tw="text-gray-el4 text-sm">/{game.playerCount}</span>
        </StatValue>
      </StatWrapper>
    </div>
  )
}

const StatWrapper = styled.div(tw`h-10 flex flex-row items-center gap-3`)
const StatLabel = styled.div(tw`text-gray-el4 hidden sm:inline`)
const StatLabelIcon = styled.div([
  tw`text-gray-el4 inline-block sm:hidden`,
  css`
    svg {
      ${tw`fill-current`}
    }
  `
])
const StatValue = styled.div(tw`text-white`)
