import { BetState, GameState } from '@crashgg/types/dist'
import { RouteComponentProps } from '@reach/router'
import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import 'twin.macro'
import { Container, LoadingScreen } from '../_common/components'
import { useAnimationFrame } from '../_common/hooks/useAnimationFrame'
import { useTitle } from '../_common/hooks/useTitle'
import { Bets } from './Bets'
import { Blink } from './Blink'
import { History } from './History'
import { useCrash } from './lib/useCrash'
import { getTitle, growthFn } from './lib/utils'
import { PlaceBet } from './PlaceBet'
import { Status } from './Status'
import { Upgrade } from './Upgrade'

export const CrashPage: React.FC<RouteComponentProps> = () => {
  const { cashout, state, placeBet, cashoutAll } = useCrash()
  useTitle(getTitle(state))

  const [dynamicAt, setDynamicAt] = useState(0)
  const [timer, setTimer] = useState(0)

  useAnimationFrame(() => {
    const now = Date.now() + state.clockDiff

    if (state.state === GameState.InProgress) {
      const elapsed = Math.max(now - (state.startedAt as number) - 30, 0)

      setDynamicAt(growthFn(elapsed))
    } else if (state.state === GameState.Betting) {
      const timeLeft = (state.startedAt as number) + 15000 - now
      setTimer(Math.max(timeLeft, 0))
      setDynamicAt(1)
    }
  }, [state.state, state.startedAt, state.clockDiff])

  if (state.gameId === -1 || !state.isConnected) return <LoadingScreen />

  return (
    <Blink
      state={state.state}
      tw="grid gap-10 pt-3 lg:(gap-0 grid-cols-4 pr-2) xl:grid-cols-5 w-full h-full">
      <div id="bg" />
      <div tw="lg:col-span-3 xl:col-span-4 relative h-full">
        <History history={state.gameHistory} clientSeed={state.clientSeed} />

        <Container tw="flex flex-col md:flex-row items-center gap-5 mt-5 max-w-[68rem]">
          <Upgrade
            bet={state.userBets[1]}
            cashout={cashout}
            gameState={state.state}
            at={state.at}
            dynamicAt={dynamicAt}
          />
          <Upgrade
            bet={state.userBets[0]}
            cashout={cashout}
            gameState={state.state}
            at={state.at}
            dynamicAt={dynamicAt}
            isCenter
          />
          <Upgrade
            bet={state.userBets[2]}
            cashout={cashout}
            gameState={state.state}
            at={state.at}
            dynamicAt={dynamicAt}
          />
        </Container>

        <div tw="absolute top-16 md:(top-auto bottom-3) left-3 text-xs text-gray">
          <FormattedMessage
            id="crash.details"
            values={{
              ping: state.ping,
              maxWin: state.maxTotalWin || 0,
              br: <br />
            }}
          />
        </div>

        <Status
          state={state.state}
          at={state.at}
          dynamicAt={dynamicAt}
          timer={timer}
        />

        <PlaceBet
          placeBet={placeBet}
          cashoutAll={cashoutAll}
          betCount={state.userBets.length}
          hasActiveBets={state.userBets.some(
            (bet) => bet.state === BetState.Active
          )}
          gameState={state.state}
        />
      </div>
      <Bets bets={state.bets} />
    </Blink>
  )
}
