import React, { useRef } from 'react'
import 'twin.macro'
import { Button, ButtonProps } from './Button'
import { ReactComponent as Spinner } from '../assets/spinner.svg'
import HCaptcha from '@hcaptcha/react-hcaptcha'
import { HCAPTCHA_KEY } from '../constants'

interface Props extends ButtonProps {
  mutation: any
  payload: any
  withCaptcha?: boolean
}

export const ActionButton: React.FC<Props> = ({
  mutation,
  payload,
  children,
  disabled,
  withCaptcha,
  ...buttonProps
}) => {
  const captchaRef = useRef<HCaptcha>(null)

  const onClick = () => {
    if (disabled || mutation.isLoading) return
    if (withCaptcha) {
      captchaRef.current?.resetCaptcha()
      captchaRef.current?.execute()
      return
    }

    mutation.mutate(payload)
  }

  const onVerify = (token: string) => {
    if (typeof payload !== 'object') payload = {}
    payload.captchaToken = token

    mutation.mutate(payload)
  }

  return (
    <>
      <Button {...buttonProps} disabled={disabled} onClick={onClick}>
        {mutation.isLoading ? <Spinner height={20} width={20} /> : children}
      </Button>

      {withCaptcha && (
        <HCaptcha
          sitekey={HCAPTCHA_KEY}
          theme="dark"
          size="invisible"
          onVerify={onVerify}
          ref={captchaRef}
        />
      )}
    </>
  )
}
