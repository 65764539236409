import { Int } from '@crashgg/types/dist'
import { DuelMin, DuelPlayer } from './duels-api'
import { PublicUser } from '../../_common/types'
import botAvatar from '../../_common/assets/avatar-bot.png'
import tw from 'twin.macro'

const BOT_NAMES = [
  'Michael',
  'Parsa',
  'Patrick',
  'Alice',
  'James',
  'Kevin',
  'Emma',
  'Boris',
  'Steve',
  'David',
  'George',
  'Larry',
  'Albert'
]

export const getPlayerData = (player: DuelPlayer, gameId: Int): PublicUser => {
  if (player.botId) {
    const idx = (gameId % 10) + player.team - 1

    return {
      id: idx,
      name: BOT_NAMES[idx],
      avatar: botAvatar,
      totalWagered: -1,
      isBot: true
    }
  }

  return player.user!
}

export const getRoll = (roll: Int) => (roll / 100).toFixed(2)

export const getColor = (
  game: DuelMin,
  type: 'text' | 'bg' | 'border' = 'text'
) => {
  if (type === 'text') {
    if (game.state === 'finished') return tw`text-gray`
    if (game.mode === 'lo') return tw`text-purple`
    return tw`text-blue`
  }

  if (type === 'border') {
    if (game.mode === 'lo') return tw`border-purple`
    return tw`border-blue`
  }

  if (game.state === 'finished') return tw`bg-gray`
  if (game.mode === 'lo') return tw`bg-purple`
  return tw`bg-blue`
}
