import { Int } from '@crashgg/types/dist'
import { LoadingScreen, Modal } from '../_common/components'
import React from 'react'
import tw from 'twin.macro'
import { useQuery } from 'react-query'
import { getDuel } from './lib/duels-api'
import { AnimatedDice } from './AnimatedDice'
import { Hero } from './Hero'
import { Stats } from './Stats'
import { RightSection } from './RightSection'
import { Fairness } from './Fairness'

interface Props {
  isOpen: boolean
  gameId?: Int
  close: () => void
}

export const DuelModal: React.FC<Props> = ({ isOpen, gameId, close }) => {
  const query = useQuery(
    ['duel', gameId],
    async () => (gameId ? getDuel(gameId) : undefined),
    {
      enabled: isOpen,
      staleTime: Infinity,
      cacheTime: Infinity,
      refetchOnMount: true,
      refetchOnWindowFocus: true
    }
  )

  if (!query.data)
    return (
      <Modal
        isOpen={isOpen}
        close={close}
        styles={tw`max-w-[60rem] pt-36 pb-16`}>
        <LoadingScreen />
      </Modal>
    )

  const game = query.data

  return (
    <Modal isOpen={isOpen} close={close} styles={tw`max-w-[60rem] p-0`}>
      <div tw="flex flex-col md:(grid grid-cols-3)">
        <div tw="px-4 py-3 col-span-2">
          <Hero game={game} />
          <Stats game={game} />
          <AnimatedDice game={game} />
          <Fairness game={game} />
        </div>

        <RightSection game={game} />
      </div>
    </Modal>
  )
}
