import { HandAction } from '@crashgg/types/dist'
import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import 'twin.macro'
import { ActionButton, AltLabel, BetInput } from '../_common/components'
import { ControlButton } from './ControlButton'
import { Game } from './lib/blackjack-api'
import { ReactComponent as Hit } from '../_common/assets/cards.svg'
import { ReactComponent as Stand } from './assets/actions/stand.svg'
import { ReactComponent as Double } from './assets/actions/double.svg'
import { ReactComponent as Split } from './assets/actions/split.svg'
import { ReactComponent as Insurance } from './assets/actions/insurance.svg'
import { ReactComponent as NoInsurance } from './assets/actions/no-insurance.svg'
import { SeedInput } from '../_common/components/SeedInput'
import { Fairness } from './lib/useFairness'

interface Props {
  game: Game | undefined
  play: (action: HandAction) => void
  createMutation: any
  isDrawing: boolean
  fairness: Fairness
  isPlayProcessing: boolean
}

export const Controls: React.FC<Props> = ({
  game,
  play,
  createMutation,
  isDrawing,
  fairness,
  isPlayProcessing
}) => {
  const [amount, setAmount] = useState('1.00')

  const createPayload = {
    amount: ~~(parseFloat(amount) * 100),
    clientSeed: fairness.seed
  }

  const canPlaceBet = !game || game.allHandsDone
  const isBusy = isPlayProcessing || isDrawing

  return (
    <div tw="flex flex-col gap-5 bg-[#121a53] p-3 rounded-lg w-full">
      <div>
        <AltLabel tw="text-xs">
          <FormattedMessage id="common.bet-amount" />
        </AltLabel>
        <BetInput
          value={amount}
          setAmount={setAmount}
          tw="w-full"
          variant="darker"
          disabled={!canPlaceBet}
        />
        <AltLabel tw="text-xs mt-2">Client Seed</AltLabel>
        <SeedInput
          value={fairness.seed}
          setSeed={fairness.setSeed}
          tw="w-full"
          variant="darker"
          disabled={!canPlaceBet}
        />
      </div>
      {game?.canInsurance ? (
        <div tw="flex flex-col gap-2">
          <ControlButton
            icon={<Insurance />}
            text="Insurance"
            onClick={() => play(HandAction.Insurance)}
          />
          <ControlButton
            icon={<NoInsurance />}
            text="No Insurance"
            onClick={() => play(HandAction.NoInsurance)}
          />
        </div>
      ) : (
        <div tw="gap-2 grid grid-cols-2 lg:grid-cols-1 xxl:grid-cols-2">
          <ControlButton
            icon={<Hit tw="fill-current text-[#00EA5D]" />}
            text="Hit"
            onClick={() => play(HandAction.Hit)}
            disabled={!(!isBusy && game?.canHitStand)}
          />
          <ControlButton
            icon={<Stand />}
            text="Stand"
            onClick={() => play(HandAction.Stand)}
            disabled={!(!isBusy && game?.canHitStand)}
          />
          <ControlButton
            icon={<Double />}
            text="Double"
            onClick={() => play(HandAction.Double)}
            disabled={!(!isBusy && game?.canDouble)}
          />
          <ControlButton
            icon={<Split />}
            text="Split"
            onClick={() => play(HandAction.Split)}
            disabled={!(!isBusy && game?.canSplit)}
          />
        </div>
      )}

      <ActionButton
        color="primary"
        disabled={!canPlaceBet}
        mutation={createMutation}
        payload={createPayload}>
        Place bet
      </ActionButton>
    </div>
  )
}
