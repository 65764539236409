import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Link } from '@reach/router'
import React from 'react'
import tw from 'twin.macro'

interface Props {
  to: string
  image: React.ReactChild
  name: string
  lead: string
}

export const Tab: React.FC<Props> = ({ to, image, name, lead }) => {
  return (
    <Card to={to} className="group">
      <Image>{image}</Image>
      <div>
        <Name>{name}</Name>
        <Lead>{lead}</Lead>
      </div>
    </Card>
  )
}

const Card = styled(Link)(
  tw`bg-gainsBgChatInput rounded-md overflow-hidden flex flex-row gap-3 px-3 py-1 cursor-pointer`,
  tw`transition duration-150 hover:bg-gainsBgSubNavbarHover`
)
const Image = styled.div(
  tw`h-12 flex`,
  css`
    img,
    svg {
      ${tw`h-8 my-auto`}
    }
  `
)
const Name = styled.div(tw`font-bold`)
const Lead = styled.div(tw`text-green text-sm tracking-wider`)
