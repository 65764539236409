import React from 'react'
import tw from 'twin.macro'
import { Avatar } from '../_common/components'
import { Currency } from '../_common/components/Currency'
import { Winner as IWinner } from './lib/lottery-api'

export interface Props {
  winner: IWinner
  winningNumbers: string
}

export const Winner: React.FC<Props> = ({
  winner: { user, coinsWon, maxNumbers, ticketsWon },
  winningNumbers
}) => {
  return (
    <div tw="bg-dark-el1 rounded-lg flex flex-col items-center border-t-2 border-blue">
      <Avatar
        src={user.avatar}
        size="big"
        tw="rounded-full my-5 border-4 p-1 border-separate border-blue"
      />
      <div tw="text-lg font-medium pb-5">{user.name}</div>

      <div tw="bg-dark w-full text-center py-4 border-t border-b border-dark-el3 text-2xl font-bold">
        {winningNumbers.split('').map((num, idx) => (
          <span
            key={idx}
            tw="px-1"
            css={
              idx + 1 <= maxNumbers ? tw`text-[#F8E555]` : tw`text-dark-el4`
            }>
            {num}
          </span>
        ))}
      </div>

      <div tw="font-medium py-5 text-lg">
        <span tw="text-gray-el1">Winning tickets:</span> {ticketsWon}
      </div>
      <div tw="mb-7">
        <div tw="text-gray-el4 tracking-wide font-medium text-center">
          Total Winnings
        </div>
        <Currency value={coinsWon} size="big" styles={tw`text-2xl`} />
      </div>
    </div>
  )
}
