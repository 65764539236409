import React from 'react'
import { useIntl } from 'react-intl'
import 'twin.macro'
import { Button, Input } from '../_common/components'
import { ReactComponent as SearchIcon } from './assets/search.svg'
import { ReactComponent as OrderDesc } from './assets/order-desc.svg'
import { ReactComponent as OrderAsc } from './assets/order-asc.svg'
import { Order } from './useItemFilter'

interface Props {
  search: string
  setSearch: (v: string) => void
  order: Order
  setOrder: (v: Order) => void
}

export const Filter: React.FC<Props> = ({
  search,
  setSearch,
  order,
  setOrder
}) => {
  const { formatMessage } = useIntl()

  const Order = order === 'price-asc' ? OrderAsc : OrderDesc
  const toggleOrder = () =>
    setOrder(order === 'price-asc' ? 'price-desc' : 'price-asc')

  return (
    <>
      <div tw="flex flex-row items-center">
        <SearchIcon tw="h-4 fill-current text-gray" />
        <Input
          type="text"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder={formatMessage({ id: 'item-page.search' })}
          tw="bg-transparent px-2"
        />
      </div>

      <Button tw="w-min px-0" color="transparent" onClick={toggleOrder}>
        <Order tw="fill-current h-6" />
      </Button>
    </>
  )
}
