import styled from '@emotion/styled'
import React from 'react'
import tw from 'twin.macro'
import { Button, ButtonProps } from '../_common/components'

type ButtonPropsFull = ButtonProps & React.HTMLAttributes<HTMLButtonElement>

interface Props extends ButtonPropsFull {
  icon: React.ReactElement
  text: string
}

export const ControlButton: React.FC<Props> = ({ icon, text, ...props }) => {
  return (
    <StyledButton {...props}>
      {icon}
      <div>{text}</div>
    </StyledButton>
  )
}

const StyledButton = styled(Button)((props) => [
  tw`flex flex-col items-center justify-center uppercase text-white font-medium`
  // props.disabled && tw`bg-gainsBgChat hover:bg-gainsBgChatInput`
])
