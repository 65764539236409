import { CrashPlaceBet } from '@crashgg/types/dist'
import { useEffect, useState } from 'react'

export const useQuickBets = () => {
  const [history, setHistory] = useState(get())
  useEffect(() => set(history), [history])

  const add = ({ amount, autoCashoutAt }: CrashPlaceBet) => {
    if (!amount || Number(amount) < 1) return

    const bet = `${amount}:${autoCashoutAt || ''}`
    const newHistory = [...new Set([bet, ...history])]
    setHistory(newHistory)
  }

  const bets = history.map((bet) => {
    const [amount, autoCashoutAt] = bet.split(':')
    return {
      amount: Number(amount),
      ...(autoCashoutAt && { autoCashoutAt: Number(autoCashoutAt) })
    }
  })

  return { bets, add }
}

const QUICK_BETS = 'quick-bets'
const get = () => {
  const history = localStorage.getItem(QUICK_BETS)
  if (!history || !history.length) return []

  return history.split('|')
}
const set = (bets: string[]) => localStorage.setItem(QUICK_BETS, bets.join('|'))
