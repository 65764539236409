import { Int } from '@crashgg/types/dist'
import { isTabFocused } from './utils'

interface UserDetails {
  balance: Int
  tickets: Int
  totalWagered: Int
  totalDeposits: Int
  totalWithdrawals: Int
}

export const credit = (changes: Partial<UserDetails>) => {
  // eslint-disable-next-line prettier/prettier
  (global as any).queryClient.setQueryData('user', (old: any) => {
    if (!isTabFocused()) return

    return Object.entries(changes).reduce(
      (old, [key, change]) => {
        old[key] = Math.max(0, (old[key] || 0) + (change || 0))

        return old
      },
      { ...old }
    )
  })
}

export const refreshUser = () => {
  if (!isTabFocused()) return
  ;(global as any).queryClient.invalidateQueries('user')
}

export const updateUser = (changes: Partial<UserDetails>) => {
  // eslint-disable-next-line prettier/prettier
  (global as any).queryClient.setQueryData('user', (old: any) => ({
    ...old,
    ...changes
  }))
}
