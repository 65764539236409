import React from 'react'
import { FormattedMessage } from 'react-intl'
import 'twin.macro'
import { H2, P, Card } from '../_common/components'
import { wagerForLvl, lvlFromWager } from '../_common/utils'
import { useUser } from '../_common/hooks/useUser'

export const LevelProgress: React.FC = () => {
  const { user } = useUser()

  const userLevel = lvlFromWager(user.totalWagered)
  const wagerRemaining = wagerForLvl(userLevel + 1) - user.totalWagered
  const wagerForCurrent = wagerForLvl(userLevel)
  const wagerForNext = wagerForLvl(userLevel + 1)
  const progress =
    (user.totalWagered - wagerForCurrent) / (wagerForNext - wagerForCurrent)

  return (
    <Card tw="py-7 flex flex-col gap-4 md:(flex-row items-center gap-16)">
      <div>
        <H2 tw="mb-0">
          <FormattedMessage id="common.level" values={{ level: userLevel }} />
        </H2>
        <P tw="text-sm">
          <FormattedMessage
            id="profile.next-level-wager"
            values={{ wager: wagerRemaining }}
          />
        </P>
      </div>

      <div tw="flex-1 w-full">
        <div tw="flex flex-row place-content-between mb-2">
          <P tw="text-blue-el1">
            <FormattedMessage
              id="profile.level-wager"
              values={{ level: userLevel, wager: wagerForCurrent }}
            />
          </P>

          <P tw="text-sm">
            <FormattedMessage
              id="profile.level-wager"
              values={{
                level: userLevel + 1,
                wager: wagerForNext
              }}
            />
          </P>
        </div>

        <div tw="w-full h-5 rounded-xl bg-dark-el3 overflow-hidden">
          <div
            tw="w-full h-full rounded-xl bg-gradient-to-r from-blue-de1 to-blue-el1"
            style={{ width: `${progress * 100}%` }}
          />
        </div>
      </div>
    </Card>
  )
}
