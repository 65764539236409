import React, { useEffect, useRef, useState } from 'react'
import 'twin.macro'
import { Item } from './Item'
import Grid from 'react-virtualized/dist/commonjs/Grid'
import ColumnSizer from 'react-virtualized/dist/commonjs/ColumnSizer'
import 'react-virtualized/styles.css'
import { Center, Container, H2, P } from '../_common/components'
import { SteamItem } from '../_common/types'
import Skeleton from 'react-loading-skeleton'
import { FormattedMessage } from 'react-intl'

interface Props {
  items: (SteamItem & any)[]
  idKey: string
  emptyMessage?: React.ReactNode
  isLoading?: boolean
  isSelected: Record<string, boolean>
  toggleItem: (id: string) => void
}

export const ItemList: React.FC<Props> = ({
  items,
  isSelected,
  idKey,
  emptyMessage,
  toggleItem,
  isLoading
}) => {
  if (isLoading && !items.length)
    items = new Array(60).fill(0).map((_, i) => ({ ph: true, [idKey]: i }))

  const wrapperRef = useRef<HTMLDivElement | null>(null)

  const [dimensions, setDimensions] = useState({
    width: 100,
    height: window.innerHeight
  })

  const updateDimensions = () => {
    setDimensions({
      width: (wrapperRef?.current?.clientWidth || 0) - 50,
      height: wrapperRef?.current?.clientHeight || 0
    })
  }

  useEffect(() => {
    updateDimensions()
    window.addEventListener('resize', updateDimensions)

    return () => window.removeEventListener('resize', updateDimensions)
  }, [])

  const { width, height } = dimensions
  const colCount = Math.round(width / 185)
  const rowCount = items.length ? Math.ceil(items.length / colCount) : 0

  const cellRenderer = ({ columnIndex, key, rowIndex, style }: any) => {
    const data = items[rowIndex * colCount + columnIndex]
    if (!data) return null

    const id = data[idKey]

    if (data.ph)
      return (
        <div key={key} style={style} tw="flex items-center justify-center px-1">
          <Skeleton
            height="10rem"
            width="100%"
            containerClassName="skeleton-container"
          />
        </div>
      )

    return (
      <div key={key} style={style} tw="flex items-center justify-center px-1">
        <Item
          {...data}
          isSelected={isSelected[id]}
          onSelect={() => toggleItem(id)}
        />
      </div>
    )
  }

  if (!items.length)
    return (
      <Center>
        <H2>
          <FormattedMessage id="common.empty-here" />
        </H2>
        <P>{emptyMessage}</P>
      </Center>
    )

  return (
    <div tw="max-w-full h-full overflow-hidden outline-none">
      <ColumnSizer
        columnMaxWidth={190}
        columnMinWidth={120}
        columnCount={colCount}
        width={width}>
        {({ adjustedWidth, getColumnWidth, registerChild }: any) => (
          <Container
            tw="overflow-hidden flex flex-col items-center h-full outline-none"
            ref={wrapperRef}>
            <Grid
              ref={registerChild}
              columnWidth={getColumnWidth}
              columnCount={colCount}
              height={height}
              cellRenderer={cellRenderer}
              rowHeight={170}
              rowCount={rowCount}
              width={adjustedWidth}
              tw="pb-32"
            />
          </Container>
        )}
      </ColumnSizer>
    </div>
  )
}
