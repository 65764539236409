import React from 'react'
import tw from 'twin.macro'
// import { ReactComponent as Star } from './assets/star.svg'
import { ReactComponent as VerifiedMark } from './assets/badge-check.svg'
// import iconMod from './assets/icon-mod.png'
// import iconOwner from './assets/icon-owner.png'

interface Props {
  role: string
  name: string
  steamId: string | null
  isVerified?: boolean
}

export const RoleName: React.FC<Props> = ({
  role,
  name,
  steamId,
  isVerified
}) => {
  const link = steamId && `https://steamcommunity.com/profiles/${steamId}`

  const props: Record<string, any> = {
    user: { css: tw`font-semibold text-xs` },
    mod: { css: tw`text-green font-semibold text-xs`, title: 'Moderator' },
    admin: { css: tw`text-red font-semibold text-xs`, title: 'Admin' }
  }

  return (
    <a
      href={link}
      target="_blank"
      rel="noreferrer noopener"
      tw="truncate"
      {...props[role]}>
      {name}
      {isVerified && (
        <VerifiedMark tw="fill-current text-blue-el2 h-[14px] mt-1 inline float-right mx-1" />
      )}
    </a>
  )

  // return <Star tw="w-3 fill-current inline text-[#f39c12]" title="Admin" />
}
