import { BjPublic, HandAction, Int, UserSummary } from '@crashgg/types/dist'
import { API } from '../../_common/API'
import { notifyError } from '../../_common/utils'

export interface Current {
  game?: BjPublic & {
    handId: Int
    canHitStand: boolean
  }
  gameId: Int
  balanceDiff: {
    bet: Int
    win: Int
  }
  cardsDrawn: Int
  newBalance?: Int | null
  serverHash?: string
  serverSeed?: string
  clientSeed?: string
}

export interface Game extends BjPublic {
  canHitStand: boolean
}

export interface HistoryEntry {
  id: Int
  betAmount: Int
  winningAmount: Int
  user: UserSummary
}

export const getCurrent = async (): Promise<Current> => {
  const res = await API.get('/blackjack/current')

  return { ...res, game: fillGame(res.game) }
}

export const playHand = async (
  gameId: Int,
  handId: Int,
  action: HandAction,
  serverHash: string
) => {
  if (!gameId) {
    notifyError('no_game_pending')
    throw new Error()
  }

  const res = await API.post('/blackjack/play', {
    gameId,
    handId,
    action,
    serverHash
  })

  return {
    ...res,
    game: {
      ...fillGame(res.game),
      action
    }
  }
}

const fillGame = (game?: Partial<Current['game']>) => {
  if (!game) return null

  const firstHandIn = game.playerHands!.findIndex((h) => h.in)
  const handId = firstHandIn === -1 ? 0 : firstHandIn

  game.handId = handId
  game.canHitStand = game.playerHands![handId]?.in
  // game.updatedAt = Date.now()

  return game
}

export interface Create {
  amount: Int
  clientSeed: string
  serverHash: string
}

export const createBlackjack = async (create: Create): Promise<Current> => {
  const res = await API.post('/blackjack/create', create)

  return { ...res, game: fillGame(res.game) }
}

export const getHistory = async (): Promise<HistoryEntry[]> => {
  return API.get('/blackjack/history')
}

export const getServerHash = async () => {
  return API.get('/fairness/server-hash')
}
