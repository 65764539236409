import { API } from '../../_common/API'

export const getAffiliateStats = async () => {
  return API.get('/affiliates')
}

export const setAffiliateCode = async (code: string) => {
  if (!code.length) throw new Error('Invalid code')

  return API.put(`/affiliates/set-code?code=${code.toUpperCase()}`, {})
}

export const claimAffiliateEarnings = async () => {
  return API.put(`/affiliates/claim-earnings`)
}

export const useAffiliateCode = async (code: string) => {
  if (!code.length) throw new Error('Invalid code')

  return API.put(
    `/affiliates/${code.toUpperCase()}/use-code`,
    {},
    { skipError: true }
  )
}
