import React from 'react'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'
import 'twin.macro'
import { ActionButton } from '../_common/components'
import { useUser } from '../_common/hooks/useUser'
import { sounds } from '../_common/sounds'
import { refreshUser } from '../_common/user'
import iconWin from './assets/icon-win.svg'
import { claimLotteryWinnings } from './lib/lottery-api'

export const Claim: React.FC = () => {
  const { isAuthenticated, user } = useUser()

  const mutation = useMutation(claimLotteryWinnings, {
    onSuccess: () => {
      toast.success(`Successfully claimed winnings!`)
      sounds.notification()
      refreshUser()
    }
  })

  if (!isAuthenticated || user.unclaimedLottery === 0) return null

  return (
    <div tw="bg-dark-el1 p-5 rounded-md flex flex-row flex-wrap mb-5">
      <img src={iconWin} alt="" tw="h-12 mt-1 mr-5" />
      <div tw="flex-1 font-sans mr-3">
        <p tw="text-lg">
          Congratulations, you have won{' '}
          <b tw="font-bold">{(user.unclaimedLottery / 100).toFixed(2)} coins</b>{' '}
          from the Lottery!
        </p>
        <p tw="text-gray-el4">Claim your winnings to play or withdraw skins</p>
      </div>

      <ActionButton
        color="primary"
        tw="mx-auto mt-5 xs:mt-0"
        mutation={mutation}
        payload={null}>
        Claim now
      </ActionButton>
    </div>
  )
}
