import { Link, RouteComponentProps } from '@reach/router'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import 'twin.macro'
import { Center, H1 } from '../_common/components'
import { useTitle } from '../_common/hooks/useTitle'

export const Error404: React.FC<RouteComponentProps> = () => {
  useTitle('Error 404')

  return (
    <Center>
      <H1>
        <FormattedMessage id="404.header" />
      </H1>
      <FormattedMessage id="404.description" />
      <Link
        to="/"
        tw="block mt-5 text-blue-el1 hover:text-blue-el2 transition duration-150">
        <FormattedMessage id="404.go-home" />
      </Link>
    </Center>
  )
}
