import { RouteComponentProps } from '@reach/router'
import React from 'react'
import 'twin.macro'
import { Container, H3, P } from '../_common/components'
import { useTitle } from '../_common/hooks/useTitle'

export const PrivacyPage: React.FC<RouteComponentProps> = () => {
  useTitle('Privacy Policy')

  return (
    <Container page>
      <div id="bg" />
      <H3>Privacy Policy</H3>
      <div tw="flex flex-col gap-5">
        <P>
          Your privacy is important to us. It is REUP.GG's policy to respect
          your privacy regarding any information we may collect from you across
          our websites and other services.
        </P>

        <P>
          We automatically collect certain information when you visit, use or
          navigate the Sites, Services or Apps. This information does not reveal
          your specific identity (like your name or contact information) but may
          include device and usage information, such as your IP address, browser
          and device characteristics, operating system, language preferences,
          referring URLs, device name, country, location, information about how
          and when you use our Sites, Services or Apps and other technical
          information. This information is primarily needed to maintain the
          security and operation of our Sites, Services or Apps, and for our
          internal analytics and reporting purposes.
        </P>

        <P>
          We use personal information collected via our services for a variety
          of business purposes described below. We process your personal
          information for these purposes in pursuit of legitimate business
          interests, in order to enter into or perform a contract with you, with
          your consent, and/or for compliance with our legal obligations. We
          indicate the specific processing grounds we rely on next to each
          purpose listed below.
        </P>

        <P>
          We may use your information for other Business Purposes, such as data
          analysis, identifying usage trends, determining the effectiveness of
          our promotional campaigns and to evaluate and improve our apps,
          products, marketing and your experience. We store this information in
          aggregated and anonymized form so that it is not associated with
          individual end users and does not include personal information. We
          will not use identifiable personal information without your consent.
        </P>

        <P>
          We may process or share data based on the following legal basis:
          <br />
          Consent: We may process your data if you have given us specific
          consent to use your personal information in a specific purpose.
          <br />
          Performance of a Contract: Where we have entered into a contract with
          you, we may process your personal information to fulfill the terms of
          our contract.
        </P>

        <P>
          You are free to refuse our request for your personal information, with
          the understanding that we may be unable to provide you with some of
          your desired services. All your personal information stored by us can
          be deleted upon request. Please contact us at contact@reup.gg. Your
          continued use of our services will be regarded as an acceptance of our
          practices around privacy and personal information. If you have any
          questions about how we handle user data and personal information, feel
          free to contact us.
        </P>

        <P>This policy is effective as of 15 September 2024.</P>
      </div>
    </Container>
  )
}
