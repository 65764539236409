import React from 'react'
import tw from 'twin.macro'
import { Int } from '@crashgg/types/dist'
import { css, Global } from '@emotion/react'
import DigitRoll from 'digit-roll-react'
import { ReactComponent as CoinIcon } from './assets/currency-emblem.svg'

interface Props {
  pot: Int
}

export const PoolSize: React.FC<Props> = ({ pot }) => {
  const props = {
    delay: 1,
    width: 5,
    height: 8.475
  }

  return (
    <div tw="justify-center overflow-hidden flex scale-40 sm:scale-50 md:scale-75 lg:scale-100">
      <Global styles={styles} />
      <div className="DigitRoll__wrapper">
        <CoinIcon tw="mr-8" />

        <div tw="text-6xl bg-dark flex flex-row rounded-lg overflow-hidden">
          <DigitRoll
            num={~~(pot / 100)}
            {...props}
            divider="&nbsp;"
            className="coins"
          />
          <div className="DigitRoll__Divider">,</div>
          <DigitRoll num={pot % 100} {...props} length={2} className="cents" />
        </div>
      </div>
    </div>
  )
}

const styles = css`
  .DigitRoll__wrapper {
    ${tw`p-10 rounded-lg flex flex-row`}
    background: linear-gradient(
        112.16deg,
        #f0c938cc -26.02%,
        #f0c9381c 34.63%,
        rgba(26, 33, 44, 0) 100%
      ),
      #1a212c;
  }

  .DigitRoll {
    color: white;
    border: none;
  }

  .DigitRoll__Cell {
    ${tw`border border-white/5`}
  }
  .DigitRoll__Cell:before {
    display: block;
    content: '';
  }

  .DigitRoll__Divider {
    ${tw`z-10 mx-[-9px] mt-auto mb-8`}
  }

  .DigitRoll.coins .DigitRoll__Cell,
  .DigitRoll.coins .DigitRoll__Divider {
    ${tw`font-bold`}
    background: linear-gradient(52.8deg, #f0c838 16.73%, #faed5d 103.58%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`
